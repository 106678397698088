import * as R from 'ramda'
import CmsImage from 'components/shared/cms-image'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import { TABLET_WIDTH } from 'constants/breakpoints'
import s from './styles.module.css'
import { isServer } from 'utilities/environment'
import useIsFirstRender from 'hooks/use-is-first-render'

const AffiliatesExperiment = ({ fields: { primaryItems } }) => {
  const isDesktopSize = useWindowSizeCondition(
    ({ width }) => width > TABLET_WIDTH
  )
  const isFirstRender = useIsFirstRender()

  // Do a two-pass render to avoid hydration issues
  const repeat =
    isDesktopSize || isServer() || isFirstRender ? [1] : R.range(1, 4)
  return (
    <section className={s.container}>
      <div className={s.content}>
        {R.flatten(
          repeat.map(i =>
            R.map(({ sys: { id }, fields: { primaryImage } }) => {
              return (
                <div key={`${id} - ${i}`}>
                  <CmsImage
                    fields={primaryImage.fields}
                    mobileFields={primaryImage.fields}
                    className={s.logo}
                    imageClassName={s.image}
                    isProgressive={false}
                    layout="responsive"
                    useNextImage
                  />
                </div>
              )
            }, primaryItems)
          )
        )}
      </div>
    </section>
  )
}

export default AffiliatesExperiment
