export const FITNESS_GOALS_QUESTION_ID = 1
export const FITNESS_GOALS_QUESTION_ID_V3 = 219
export const EMAIL_QUESTION_ID = 132
export const COACH_FIRST_NAME_QUESTION_ID = 100
export const COACH_CERTIFICATIONS_QUESTION_ID = 114
export const COACH_MISSION_QUESTION_ID = 116
export const COACH_QUOTE_QUESTION_ID = 117
export const COACH_WHY_TRAIN_WITH_ME_QUESTION_ID = 137
export const COACH_APPROACH_QUESTION_ID = 138
export const COACH_CLIENT_GOALS_QUESTION_ID = 107
export const COACH_SPECIALTIES_QUESTION_ID = 113
export const COACH_PROFILE_IMAGE_QUESTION_ID = 99
export const COACH_BODY_IMAGE_QUESTION_ID = 127
export const COACH_ACTION_IMAGE_QUESTION_ID = 128
export const COACH_HUMANIZING_IMAGE_QUESTION_ID = 129
export const COACH_INTRO_VIDEO_QUESTION_ID = 140
export const COACH_AGREEMENT_QUESTION_ID = 244
export const COACH_APPROACH_VIDEO_QUESTION_ID = 141
const PAGE_TITLES = {
  GOALS: 'Kickoff - Goals',
  AGE: 'Kickoff - Age',
  NUTRITION: 'Kickoff - Nutrition Interests',
  COACH_GENDER: 'Kickoff - Preferred Coach Gender',
  SPECIAL_CASES: 'Kickoff - Special Cases',
  COACH: 'Kickoff - Your coach',
  START: 'Kickoff - Start Training',
}
export const QUESTION_ID_TO_PAGE_TITLE_MAP = {
  1: PAGE_TITLES.GOALS,
  2: PAGE_TITLES.GOALS,
  133: PAGE_TITLES.AGE,
  3: PAGE_TITLES.NUTRITION,
  4: PAGE_TITLES.COACH_GENDER,
  5: PAGE_TITLES.SPECIAL_CASES,
  6: PAGE_TITLES.SPECIAL_CASES,
  matches: PAGE_TITLES.COACH,
  payment: PAGE_TITLES.START,
}

export const CPS_NUTRITION_QUESTION_IDS = [
  181, 182, 183, 158, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174,
  176, 177, 179,
]

export const FAVORITE_EXERCISE_TYPES_QUESTION_ID = 208
export const FAVORITE_EXERCISE_TYPES = {
  158: 'Weight training',
  160: 'High intensity interval training (HIIT)',
  153: 'Running and/or sprint drills',
  157: 'Swimming',
  154: 'Road biking',
  215: 'Yoga',
  216: 'Pilates',
  217: 'Barre',
  218: 'Boxing/kickboxing',
  230: 'Not sure yet',
}

export const SPECIALTY_WEIGHTLOSS_MALE = 'specialty-weightloss-male'
export const SPECIALTY_WEIGHTLOSS_FEMALE = 'specialty-weightloss-female'
export const SPECIALTY_WEIGHTLOSS = 'specialty-weightloss'

export const CLIENT_PREFERENCES_QUESTION_IDS = {
  WEIGHT: 9,
  HEIGHT: 10,
  DATE_OF_BIRTH: 11,
  SPECIAL_CIRCUMSTANCES: 224,
  EXERCISE_TYPES: 154,
}

export const CLIENT_SIGNUP_SURVEY_QUESTION_IDS = {
  EVENT: 207,
}

export const CLIENT_FITNESS_GOALS_RESPONSE_IDS = {
  TRAINING_FOR_EVENT: '5',
}

export const CLIENT_NOVELTY_VALUE_QUESTION_MAP = {
  '1': 'Client likes constantly changing workouts - Client gets bored easily and needs constant novelty!',
  '-1': 'Client likes to master exercises and get the most out of them before progressing',
  '0': 'Client likes a balance of novelty and mastery',
}
