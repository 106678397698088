import { AnalyticsManager } from 'utilities'

/*
 ** Analytics class
 */
export class LandingPageAnalytics {
  static onSignUp({ label, location, ...rest }) {
    AnalyticsManager.logEvent(
      label ? label : `Landing Page - Sign Up - ${location}`,
      { ...rest }
    )
  }
  static onCoachApply({ label, location, ref }) {
    AnalyticsManager.logEvent(
      label ? label : `Landing Page - Coach Sign Up - ${location}`,
      {
        'Referral Code': ref,
      }
    )
  }
  static onView({ title, slug, ref, ...rest }) {
    AnalyticsManager.logEvent(`Landing Page - View`, {
      Title: title,
      Slug: slug,
      'Referral Code': ref,
      ...rest,
    })
    try {
      window.dataLayer.push({
        event: `viewed_lp_${rest.split ? rest.split.toLowerCase() : 'y'}`,
      })
    } catch (error) {}
  }
  static onPromoBannerView({ ...rest }) {
    AnalyticsManager.logEvent('Promo Banner - View', { ...rest })
  }
}
