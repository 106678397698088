import cx from 'classnames'

import { mapWithMetadata } from 'utilities/list'
import s from './styles.module.css'

export const BILLING_PERIODS = [
  { value: 1, label: 'Month', discount: '' },
  { value: 6, label: '6 Months', discount: 'Save 10%' },
  { value: 12, label: 'Year', discount: 'Save 20%' },
]

const BillingPeriodSelect = ({ value, set, discountStyle }) => {
  return (
    <ul className={s.billingPeriods}>
      {mapWithMetadata((billingPeriod, { isFirst, isLast }) => {
        return (
          <li
            key={billingPeriod.value}
            className={cx(s.billingPeriod, {
              [s.billingPeriodActive]: billingPeriod.value === value,
              [s.billingPeriodFirst]: isFirst,
              [s.billingPeriodLast]: isLast,
            })}
            onClick={() => set(billingPeriod.value)}>
            {billingPeriod.label}
            <div className={cx(s.discount, discountStyle)}>
              {billingPeriod.discount}
            </div>
          </li>
        )
      }, BILLING_PERIODS)}
    </ul>
  )
}

export default BillingPeriodSelect
