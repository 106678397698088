import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'
import SectionTitle from '../../experiment/common/section-title'
import EditButton from '../../edit-button'
import { COACH_MISSION_QUESTION_ID } from 'constants/cms-survey'

const CoachMission = ({ coach }: CoachProps) => {
  return (
    <div className={s.container}>
      <SectionTitle
        title={
          <>
            My Mission
            <EditButton questionId={COACH_MISSION_QUESTION_ID} />
          </>
        }
      />
      <div className={s.mission}>{coach.missionStatement}</div>
    </div>
  )
}

export default CoachMission
