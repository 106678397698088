export default ({ className }) => (
  <svg
    className={className}
    width="104"
    height="16"
    viewBox="0 0 104 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="KICKOFF Final Vector">
      <path
        d="M37.496 13.4824C37.552 13.2419 37.4214 13.027 37.1601 12.5973L37.0515 12.4186C36.7093 11.8559 36.5382 11.5745 36.3149 11.5081C36.0915 11.4416 35.7067 11.6258 34.937 11.9941C34.8835 12.0197 34.8293 12.0443 34.7743 12.0679C34.2184 12.3007 33.6365 12.417 33.0289 12.417C32.4147 12.417 31.8394 12.3007 31.3028 12.0679C30.7662 11.8352 30.2976 11.5184 29.8968 11.1176C29.4959 10.7168 29.1792 10.2514 28.9464 9.72126C28.7137 9.1847 28.5974 8.61257 28.5974 8.0049C28.5974 7.39722 28.7137 6.8251 28.9464 6.28853C29.1792 5.7455 29.4959 5.27682 29.8968 4.88247C30.2976 4.48167 30.7662 4.1649 31.3028 3.93217C31.8394 3.69944 32.4147 3.58308 33.0289 3.58308C33.6365 3.58308 34.2184 3.70268 34.7743 3.94187C34.8279 3.96432 34.8809 3.98773 34.9331 4.0121C35.7097 4.37439 36.098 4.55553 36.3193 4.4885C36.5407 4.42147 36.711 4.14147 37.0515 3.58146L37.1632 3.39772C37.4232 2.97001 37.5532 2.75616 37.4976 2.51603C37.442 2.27591 37.2539 2.15554 36.8777 1.91479C36.4533 1.64321 36.001 1.42032 35.521 1.24611C34.7194 0.948736 33.8887 0.800049 33.0289 0.800049C32.3695 0.800049 31.7327 0.887322 31.1186 1.06187C30.5109 1.22995 29.9388 1.47237 29.4022 1.78914C28.8721 2.09944 28.3873 2.47439 27.9477 2.91399C27.5081 3.35358 27.1299 3.84167 26.8131 4.37823C26.5028 4.90833 26.2604 5.48045 26.0858 6.09459C25.9178 6.70874 25.8337 7.3455 25.8337 8.0049C25.8337 8.66429 25.9178 9.30106 26.0858 9.9152C26.2604 10.5229 26.5028 11.095 26.8131 11.6316C27.1299 12.1617 27.5081 12.6465 27.9477 13.0861C28.3873 13.5257 28.8721 13.9039 29.4022 14.2207C29.9388 14.531 30.5109 14.7702 31.1186 14.9382C31.7327 15.1128 32.3695 15.2 33.0289 15.2C33.8887 15.2 34.7194 15.0514 35.521 14.754C35.9982 14.5769 36.448 14.3541 36.8703 14.0854C37.25 13.8437 37.4399 13.7229 37.496 13.4824Z"
        fill="#030017"
      />
      <path
        d="M3.42835 13.9902C3.42835 14.5605 3.42835 14.8456 3.25119 15.0228C3.07403 15.1999 2.78889 15.1999 2.21862 15.1999H1.75611C1.18584 15.1999 0.900708 15.1999 0.723547 15.0228C0.546387 14.8456 0.546387 14.5605 0.546387 13.9902V2.00989C0.546387 1.43962 0.546387 1.15448 0.723547 0.97732C0.900708 0.80016 1.18584 0.80016 1.75611 0.80016H2.21862C2.78889 0.80016 3.07403 0.80016 3.25119 0.97732C3.42835 1.15448 3.42835 1.43962 3.42835 2.00989V5.44534C3.42835 6.50072 3.42835 7.02841 3.71854 7.1246C4.00872 7.22079 4.32392 6.79757 4.95431 5.95115L8.28268 1.48216C8.53189 1.14754 8.6565 0.980233 8.83582 0.890196C9.01514 0.80016 9.22375 0.80016 9.64098 0.80016H10.3723C11.1106 0.80016 11.4798 0.80016 11.5969 1.03406C11.7139 1.26796 11.4927 1.56348 11.0501 2.15451L8.91388 5.00755C8.23471 5.9146 7.89513 6.36813 7.85715 6.8998C7.81918 7.43146 8.09087 7.92865 8.63423 8.92302L11.3796 13.9471C11.6849 14.5058 11.8375 14.7851 11.7145 14.9925C11.5915 15.1999 11.2732 15.1999 10.6365 15.1999H9.79808C9.30704 15.1999 9.06152 15.1999 8.86142 15.0809C8.66132 14.9619 8.54414 14.7461 8.30979 14.3146L6.25843 10.5375C5.97442 10.0146 5.83242 9.7531 5.60141 9.73641C5.3704 9.71971 5.19227 9.95803 4.836 10.4347L4.19877 11.2872C3.81693 11.7981 3.626 12.0535 3.52718 12.3508C3.42835 12.6481 3.42835 12.967 3.42835 13.6048V13.9902Z"
        fill="#030017"
      />
      <path
        d="M20.3899 13.9902C20.3899 14.5605 20.3899 14.8456 20.2128 15.0228C20.0356 15.1999 19.7505 15.1999 19.1802 15.1999H18.7177C18.1474 15.1999 17.8623 15.1999 17.6851 15.0228C17.508 14.8456 17.508 14.5605 17.508 13.9902V2.00989C17.508 1.43962 17.508 1.15448 17.6851 0.97732C17.8623 0.80016 18.1474 0.80016 18.7177 0.80016H19.1802C19.7505 0.80016 20.0356 0.80016 20.2128 0.97732C20.3899 1.15448 20.3899 1.43962 20.3899 2.00989V13.9902Z"
        fill="#030017"
      />
      <path
        d="M46.034 13.9902C46.034 14.5605 46.034 14.8456 45.8568 15.0228C45.6796 15.1999 45.3945 15.1999 44.8242 15.1999H44.3466C43.7763 15.1999 43.4912 15.1999 43.314 15.0228C43.1368 14.8456 43.1368 14.5605 43.1368 13.9902V2.00989C43.1368 1.43962 43.1368 1.15448 43.314 0.97732C43.4912 0.80016 43.7763 0.80016 44.3466 0.80016H44.8242C45.3945 0.80016 45.6796 0.80016 45.8568 0.97732C46.034 1.15448 46.034 1.43962 46.034 2.00989V5.45604C46.034 6.50758 46.034 7.03335 46.3236 7.12978C46.6133 7.2262 46.9284 6.80532 47.5586 5.96355L50.9163 1.47875C51.1656 1.14579 51.2903 0.979277 51.4692 0.88972C51.6481 0.80016 51.8561 0.80016 52.2721 0.80016H53.0179C53.7583 0.80016 54.1284 0.80016 54.2454 1.03454C54.3623 1.26892 54.1397 1.56468 53.6945 2.15622L51.5529 5.00138C50.8681 5.9112 50.5257 6.3661 50.4875 6.90008C50.4493 7.43406 50.7234 7.9331 51.2716 8.93117L54.026 13.9454C54.3332 14.5047 54.4868 14.7843 54.3639 14.9921C54.2409 15.1999 53.9219 15.1999 53.2838 15.1999H52.4293C51.9395 15.1999 51.6945 15.1999 51.4947 15.0814C51.2949 14.9628 51.1775 14.7479 50.9428 14.3179L48.8749 10.5301C48.591 10.0102 48.4491 9.75022 48.2187 9.73353C47.9883 9.71684 47.8104 9.95364 47.4546 10.4272L46.8102 11.2849C46.4255 11.7969 46.2331 12.0529 46.1335 12.3512C46.034 12.6496 46.034 12.9698 46.034 13.6102V13.9902Z"
        fill="#030017"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.8451 9.90807C73.0199 9.2931 73.1073 8.65548 73.1073 7.99519C73.1073 7.33491 73.0199 6.69728 72.8451 6.08231C72.6768 5.46734 72.4373 4.89445 72.1265 4.36364C71.8158 3.83282 71.4404 3.34732 71.0002 2.90713C70.56 2.46694 70.0745 2.09149 69.5437 1.78076C69.0128 1.47004 68.44 1.23053 67.825 1.06222C67.21 0.887439 66.5724 0.800049 65.9121 0.800049C65.2518 0.800049 64.6142 0.887439 63.9992 1.06222C63.3907 1.23053 62.8178 1.47004 62.2806 1.78076C61.7497 2.09149 61.2642 2.46694 60.824 2.90713C60.3839 3.34732 60.0052 3.83282 59.688 4.36364C59.3773 4.89445 59.1345 5.46734 58.9597 6.08231C58.7914 6.69728 58.7073 7.33491 58.7073 7.99519C58.7073 8.65548 58.7914 9.2931 58.9597 9.90807C59.1345 10.5166 59.3773 11.0895 59.688 11.6268C60.0052 12.1576 60.3839 12.6431 60.824 13.0833C61.2642 13.5234 61.7497 13.9021 62.2806 14.2193C62.8178 14.5301 63.3907 14.7696 63.9992 14.9379C64.6142 15.1127 65.2518 15.2 65.9121 15.2C66.5724 15.2 67.21 15.1127 67.825 14.9379C68.44 14.7696 69.0128 14.5301 69.5437 14.2193C70.0745 13.9021 70.56 13.5234 71.0002 13.0833C71.4404 12.6431 71.8158 12.1576 72.1265 11.6268C72.4373 11.0895 72.6768 10.5166 72.8451 9.90807ZM69.9903 6.27652C70.2234 6.8138 70.3399 7.3867 70.3399 7.99519C70.3399 8.60369 70.2234 9.17658 69.9903 9.71387C69.7573 10.2447 69.4401 10.7108 69.0387 11.1121C68.6439 11.5135 68.1746 11.8307 67.6308 12.0637C67.0935 12.2967 66.5206 12.4133 65.9121 12.4133C65.2971 12.4133 64.721 12.2967 64.1837 12.0637C63.6464 11.8307 63.1771 11.5135 62.7758 11.1121C62.3744 10.7108 62.0572 10.2447 61.8242 9.71387C61.5911 9.17658 61.4746 8.60369 61.4746 7.99519C61.4746 7.3867 61.5911 6.8138 61.8242 6.27652C62.0572 5.73275 62.3744 5.26343 62.7758 4.86856C63.1771 4.46721 63.6464 4.15002 64.1837 3.91697C64.721 3.68393 65.2971 3.56741 65.9121 3.56741C66.5206 3.56741 67.0935 3.68393 67.6308 3.91697C68.1746 4.15002 68.6439 4.46721 69.0387 4.86856C69.4401 5.26343 69.7573 5.73275 69.9903 6.27652Z"
        fill="#030017"
      />
      <path
        d="M81.433 13.9902C81.433 14.5605 81.433 14.8456 81.2559 15.0228C81.0787 15.1999 80.7936 15.1999 80.2233 15.1999H79.7608C79.1905 15.1999 78.9054 15.1999 78.7282 15.0228C78.551 14.8456 78.551 14.5605 78.551 13.9902V2.49378C78.551 1.6954 78.551 1.29621 78.7991 1.04818C79.0471 0.80016 79.4463 0.80016 80.2447 0.80016H87.1812C87.7515 0.80016 88.0366 0.80016 88.2138 0.97732C88.391 1.15448 88.391 1.43962 88.391 2.00989V2.4724C88.391 3.04267 88.391 3.3278 88.2138 3.50496C88.0366 3.68212 87.7515 3.68212 87.1812 3.68212H82.6427C82.0725 3.68212 81.7873 3.68212 81.6102 3.85928C81.433 4.03645 81.433 4.32158 81.433 4.89185V5.35436C81.433 5.92463 81.433 6.20977 81.6102 6.38693C81.7873 6.56409 82.0725 6.56409 82.6427 6.56409H84.2993C84.8695 6.56409 85.1547 6.56409 85.3318 6.74125C85.509 6.91841 85.509 7.20355 85.509 7.77382V8.23633C85.509 8.8066 85.509 9.09173 85.3318 9.26889C85.1547 9.44605 84.8695 9.44605 84.2993 9.44605H82.6427C82.0725 9.44605 81.7873 9.44605 81.6102 9.62321C81.433 9.80037 81.433 10.0855 81.433 10.6558V13.9902Z"
        fill="#030017"
      />
      <path
        d="M96.5395 15.0228C96.7167 14.8456 96.7167 14.5605 96.7167 13.9902V10.6558C96.7167 10.0855 96.7167 9.80037 96.8939 9.62321C97.071 9.44605 97.3562 9.44605 97.9264 9.44605H99.583C100.153 9.44605 100.438 9.44605 100.616 9.26889C100.793 9.09173 100.793 8.8066 100.793 8.23633V7.77382C100.793 7.20355 100.793 6.91841 100.616 6.74125C100.438 6.56409 100.153 6.56409 99.583 6.56409H97.9264C97.3562 6.56409 97.071 6.56409 96.8939 6.38693C96.7167 6.20977 96.7167 5.92463 96.7167 5.35436V4.89185C96.7167 4.32158 96.7167 4.03645 96.8939 3.85928C97.071 3.68212 97.3562 3.68212 97.9264 3.68212H102.465C103.035 3.68212 103.32 3.68212 103.497 3.50496C103.675 3.3278 103.675 3.04267 103.675 2.4724V2.00989C103.675 1.43962 103.675 1.15448 103.497 0.97732C103.32 0.80016 103.035 0.80016 102.465 0.80016H95.5284C94.73 0.80016 94.3308 0.80016 94.0828 1.04818C93.8347 1.29621 93.8347 1.6954 93.8347 2.49378V13.9902C93.8347 14.5605 93.8347 14.8456 94.0119 15.0228C94.1891 15.1999 94.4742 15.1999 95.0445 15.1999H95.507C96.0772 15.1999 96.3624 15.1999 96.5395 15.0228Z"
        fill="#030017"
      />
    </g>
  </svg>
)
