import Select from 'react-select'
import s from './styles.module.css'

const SingleValueComponent = ({ children, selectProps }) => {
  return (
    <div className={s.customContainer}>
      {selectProps.value.value !== 'default' && (
        <div className={s.label}>Your fitness goal</div>
      )}
      <div>{children}</div>
    </div>
  )
}

const Dropdown = ({ options, onChange, menuPlacement = 'auto', styles }) => {
  return (
    <Select
      aria-label={options[0].label}
      isSearchable={false}
      components={{ SingleValue: SingleValueComponent }}
      className={s.select}
      classNamePrefix="s"
      defaultValue={options[0]}
      options={options}
      onChange={onChange}
      menuPlacement={menuPlacement}
      styles={styles}
    />
  )
}

export default Dropdown
