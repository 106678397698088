import * as R from 'ramda'
import { useState, useRef, useEffect } from 'react'

import { Swiper, SwiperSlide } from 'components/shared/swiper'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import { TABLET_WIDTH } from 'constants/breakpoints'
import CmsImage from 'components/shared/cms-image'
import rightArrow from 'images/long-right-arrow-grey.svg'
import leftArrow from 'images/long-left-arrow-grey.svg'
import DisableSSR from 'containers/disable-ssr'
import s from './styles.module.css'

const getSpace = ({ width }) => (width > TABLET_WIDTH ? 30 : 10)

const FeatureCarousel = ({ fields }) => {
  const swiperRef = useRef(null)
  const [slidesPerView, setSlidesPerView] = useState(null)
  const [spaceBetween, setSpaceBetween] = useState(null)
  const space = useWindowSizeCondition(getSpace)
  const numSlides = useWindowSizeCondition(({ width }) => {
    return Math.max(3, Math.floor(width / (200 + getSpace({ width }))))
  })

  useEffect(() => {
    setSlidesPerView(numSlides)
    setSpaceBetween(space)
  }, [space, numSlides])

  const { header, primaryItems: features } = fields

  const [activeIndex, setActiveIndex] = useState(
    Math.floor(features.length / 4)
  )

  useEffect(() => {
    if (swiperRef.current) swiperRef.current.swiper.slideTo(activeIndex, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) return

    const onChange = () => setActiveIndex(swiper.activeIndex)

    swiper.on('slideChange', onChange)

    return () => swiper && swiper.off('slideChange', onChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperRef.current])

  const swiperParams = {
    spaceBetween,
    slidesPerView,
    loop: true,
  }

  // reset if number of slides changes
  if (!slidesPerView || slidesPerView !== numSlides || space !== spaceBetween) {
    return null
  }

  return (
    <section className={s.featureSection}>
      {header && <h2 className={s.header}>{header}</h2>}
      <div className={s.swiperWrapper}>
        <button
          className={s.previousButton}
          onClick={() => swiperRef.current.swiper.slidePrev()}>
          <img src={leftArrow} alt="Left arrow" className={s.arrowIcon} />
        </button>
        {!R.isNil(slidesPerView) && (
          <Swiper {...swiperParams} ref={swiperRef}>
            {R.addIndex(R.map)(({ fields }, idx) => {
              const { header, primaryImage } = fields

              return (
                <SwiperSlide key={idx} className={s.feature}>
                  {primaryImage && (
                    <CmsImage
                      fields={primaryImage.fields}
                      className={s.imageWrapper}
                      imageClassName={s.image}
                      w={400}
                    />
                  )}
                  <div className={s.featureContent}>
                    <h4 className={s.featureLabel}>{header}</h4>
                  </div>
                </SwiperSlide>
              )
            }, features)}
          </Swiper>
        )}
        <button
          className={s.nextButton}
          onClick={() => swiperRef.current.swiper.slideNext()}>
          <img src={rightArrow} alt="Right arrow" className={s.arrowIcon} />
        </button>
      </div>
    </section>
  )
}

const FeatureCarouselContainer = props => {
  return (
    <DisableSSR>
      <FeatureCarousel {...props} />
    </DisableSSR>
  )
}

export default FeatureCarouselContainer
