import ProductSelectStandard from './product-select-standard'
import ProductSelectVolumeDiscount from './product-select-volume-discount'

const ProductSelect = ({ forceShowVolumeDiscount = false, ...props }) => {
  if (forceShowVolumeDiscount) {
    return <ProductSelectVolumeDiscount {...props} />
  }

  return <ProductSelectStandard {...props} />
}

export default ProductSelect
