import { useContext, useMemo, useState } from 'react'
import cx from 'classnames'
import { Button } from 'components/design-system/button'
import CmsPageContext from 'contexts/cms-page-context'

import s from './styles.module.css'
import {
  ArrowRightIcon,
  LoadingIcon,
} from 'components/design-system/kickoff-icons'
import { useRouter } from 'next/router'
import { LandingPageExperimentAnalytics } from '../analytics'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'

const CTAButton = ({
  children = undefined,
  className = '',
  signUpVariant = undefined,
  analyticProps = { location: '' },
  logAnalytics = undefined,
}) => {
  const { query } = useRouter()
  const targetAudience = useTargetAudience()
  const { isCoachPage, signUp } = useContext(CmsPageContext)

  const href = useMemo(() => {
    if (targetAudience === Audience.Coach) {
      const refString = query?.ref ? `?ref=${query?.ref}` : ''
      const route = `/apply${refString}`
      return route
    }
    return '#signUp'
  }, [query, targetAudience])

  const [submitting, setSubmitting] = useState(false)

  const handleSignUp = async () => {
    if (submitting) return

    setSubmitting(true)

    try {
      signUp()
      LandingPageExperimentAnalytics.onClick({
        signUpVariant,
        location: `${analyticProps.location} - CTA`,
      })
    } catch (error) {
      setSubmitting(false)
    }
  }

  const handleClick = e => {
    e.preventDefault()

    if (isCoachPage) {
      document.querySelector(href).scrollIntoView({ behavior: 'smooth' })
      window.history.pushState(null, null, href)
      if (logAnalytics) {
        logAnalytics()
      } else {
        LandingPageExperimentAnalytics.onClick({
          signUpVariant,
          location: `Coach Page ${analyticProps.location} - CTA`,
        })
      }
    } else {
      handleSignUp()
    }
  }

  return (
    <Button
      size="small"
      className={cx(s.cta, className)}
      onClick={handleClick}
      href={href}
      disabled={submitting}>
      {children || 'Get Started'}
      {submitting ? <LoadingIcon className={s.spin} /> : <ArrowRightIcon />}
    </Button>
  )
}

export default CTAButton
