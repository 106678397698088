import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as R from 'ramda'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'

import s from './styles.module.css'

library.add(faStar)

const STARS = (
  <ol className={s.stars}>
    {R.map(
      i => (
        <FontAwesomeIcon key={`star-${i}`} className={s.star} icon={'star'} />
      ),
      R.range(0, 5)
    )}
  </ol>
)

const DetailedStars = ({ rating, className }) => {
  return (
    <div className={cx(s.container, className)}>
      <div className={s.starsSection}>
        <div className={s.backgroundStars}>{STARS}</div>
        <div
          className={s.ratingStars}
          style={{ width: `${(rating / 5) * 100}%` }}>
          {STARS}
        </div>
      </div>
      <div className={s.ratingSection}>{rating}/5</div>
    </div>
  )
}

export default DetailedStars
