import * as R from 'ramda'
import { useState, useContext, useEffect } from 'react'
import cx from 'classnames'
import Link from 'next/link'

import useScrollThreshold from 'hooks/use-scroll-threshold'
import useTargetAudience from 'hooks/use-target-audience'
import urls from 'utilities/urls'
import CmsImage from 'components/shared/cms-image'
import CmsPageContext from 'contexts/cms-page-context'
import ModalContext from 'contexts/modal-context'
import kickoffLogoDark from 'images/kickoff-logo-dark.svg'
import ConfirmationModal from 'components/shared/confirmation-modal'
import Spinner from 'components/shared/spinner'
import CTALink from 'components/homepage/cta-link'
import { AnalyticsSection } from 'containers/analytics'
import { TrackedA } from 'components/shared/analytics'
import { NAV_LINKS, DEFAULT_LINKS } from './constants'
import s from './styles.module.css'

const SCROLL_THRESHOLD = 50

const Nav = ({
  fields,
  ctaText,
  logoLinkProps,
  coachLinkText,
  linksToExclude = [],
  linksToInclude = DEFAULT_LINKS,
}) => {
  const targetAudience = useTargetAudience()
  const { onAnchorLinkClick, signUp, hasBanner, isCoachPage } =
    useContext(CmsPageContext)
  const [isOpen, setIsOpen] = useState(false)
  const [appLink, setAppLink] = useState('#')
  const isScrolled = useScrollThreshold(
    R.pathOr(SCROLL_THRESHOLD, ['options', 'scrollThreshold'], fields)
  )

  const { secondaryItems: socialLinks = [] } = fields

  const toggleOpen = () => setIsOpen(R.not)

  const confirmationModal = useContext(ModalContext)

  const initAppLink = async () => {
    const branch = (await import('clients/branch')).default
    branch.link(
      {
        data: {
          $deeplink_path: 'dashboard',
        },
      },
      (_, link) => {
        setAppLink(link)
      }
    )
  }
  useEffect(() => {
    if (appLink == '#') {
      initAppLink()
    }
  }, [appLink])

  const handleAnchorLinkClick = (ev, link) => {
    if (R.hasPath(['options', 'links', link], fields)) return
    onAnchorLinkClick(ev)
    setIsOpen(false)
  }

  const openSignUp = _ => {
    confirmationModal.open(() => {
      return <Spinner />
    })
    signUp()
  }

  const showLoginModal = ev => {
    ev.preventDefault()

    if (appLink === '#') {
      return
    }

    confirmationModal.open(() => {
      return (
        <ConfirmationModal
          prompt={'Click to access the Kickoff app and login!'}
          cancelButtonText="Create Client Account"
          confirmButtonText="Login as Existing Client"
          onCancel={openSignUp}
          bottomLinkURL={'/sign-in'}
          bottomLinkText={'Login as Coach'}
          onConfirm={_ => {
            window.location.href = appLink
          }}
          confirmButtonClassname={s.loginButton}
        />
      )
    })
  }

  const linksToIncludeSet = new Set(linksToInclude)
  const linksToExcludeSet = new Set(linksToExclude)

  const shouldIncludeLink = link => {
    return linksToIncludeSet.has(link) && !linksToExcludeSet.has(link)
  }
  const renderLogos = () => {
    return (
      <>
        {isCoachPage ? (
          <img
            src={kickoffLogoDark}
            alt="Kickoff logo"
            className={cx(s.logoDark, {
              [s.logoHidden]: !isScrolled && !isCoachPage,
            })}
          />
        ) : (
          <Link {...urls.homePage()}>
            <img
              src={kickoffLogoDark}
              alt="Kickoff logo"
              className={cx(s.logoDark, {
                [s.logoHidden]: !isScrolled && !isCoachPage,
              })}
            />
          </Link>
        )}
      </>
    )
  }

  return (
    <nav
      className={cx(s.nav, {
        [s.navScrolled]: isScrolled,
        [s.navOpen]: isOpen,
        [s.navWithBanner]: hasBanner,
      })}>
      {!logoLinkProps && <div className={s.logoWrapper}>{renderLogos()}</div>}
      {logoLinkProps && (
        <Link {...logoLinkProps} className={s.logoWrapper}>
          {renderLogos()}
        </Link>
      )}
      <CTALink
        analyticProps={{ location: 'Mobile Nav' }}
        buttonText={ctaText}
        className={s.getStartedLinkMobile}
        buttonClassName={cx(s.getStartedButtonMobile, {
          [s.getStartedButtonNotScrolled]: !isScrolled && !isCoachPage,
          [s.getStartedButtonScrolled]: isScrolled || isCoachPage,
        })}
        spinnerClassName={s.spinner}
        targetAudience={targetAudience}
        onClick={() => setIsOpen(false)}
      />
      {/* Workaround for hiding menu bars if there are no links to show */}
      {!R.equals(linksToInclude, linksToExclude) && (
        <button className={s.bars} onClick={toggleOpen}>
          <div className={s.bar} />
          <div className={s.bar} />
          <div className={s.bar} />
        </button>
      )}
      <div
        className={cx(s.actions, {
          [s.navWithBanner]: hasBanner,
        })}>
        {shouldIncludeLink(NAV_LINKS.COACH) && (
          <TrackedA
            name="Coach"
            className={s.link}
            onClick={ev => handleAnchorLinkClick(ev, NAV_LINKS.COACH)}
            href={R.pathOr(
              `#${NAV_LINKS.COACH}`,
              ['options', 'links', NAV_LINKS.COACH],
              fields
            )}>
            {coachLinkText}
          </TrackedA>
        )}
        {shouldIncludeLink(NAV_LINKS.HOW_IT_WORKS) && (
          <TrackedA
            name="How It Works"
            className={s.link}
            onClick={ev => handleAnchorLinkClick(ev, NAV_LINKS.HOW_IT_WORKS)}
            href={R.pathOr(
              `#${NAV_LINKS.HOW_IT_WORKS}`,
              ['options', 'links', NAV_LINKS.HOW_IT_WORKS],
              fields
            )}>
            How it works
          </TrackedA>
        )}
        {shouldIncludeLink(NAV_LINKS.TESTIMONIALS) && (
          <TrackedA
            name="Testimonials"
            className={s.link}
            onClick={ev => handleAnchorLinkClick(ev, NAV_LINKS.TESTIMONIALS)}
            href={R.pathOr(
              `#${NAV_LINKS.TESTIMONIALS}`,
              ['options', 'links', NAV_LINKS.TESTIMONIALS],
              fields
            )}>
            Client stories
          </TrackedA>
        )}
        {shouldIncludeLink(NAV_LINKS.PRICING) && (
          <TrackedA
            name="Pricing"
            className={s.link}
            onClick={ev => handleAnchorLinkClick(ev, NAV_LINKS.PRICING)}
            href={R.pathOr(
              `#${NAV_LINKS.PRICING}`,
              ['options', 'links', NAV_LINKS.PRICING],
              fields
            )}>
            Pricing
          </TrackedA>
        )}
        {shouldIncludeLink(NAV_LINKS.TRAINERS) && (
          <a
            className={s.link}
            onClick={ev => handleAnchorLinkClick(ev, NAV_LINKS.TRAINERS)}
            href={R.pathOr(
              `#${NAV_LINKS.TRAINERS}`,
              ['options', 'links', NAV_LINKS.TRAINERS],
              fields
            )}>
            Trainers
          </a>
        )}
        {shouldIncludeLink(NAV_LINKS.JOBS) && (
          <Link
            href="/coachwithkickoff"
            className={s.link}
            onClick={() => setIsOpen(false)}>
            Jobs
          </Link>
        )}
        {shouldIncludeLink(NAV_LINKS.GIFTS) && (
          <a
            className={s.link}
            href="http://trainwithkickoff-1.hubspotpagebuilder.com/remote-personal-training-gift-subscriptions-0">
            Gifts
          </a>
        )}
        {shouldIncludeLink(NAV_LINKS.BLOG) && (
          <TrackedA
            name="Blog"
            className={s.link}
            onClick={ev => handleAnchorLinkClick(ev, 'blog')}
            href={R.pathOr(`/blog`, ['options', 'links', 'blog'], fields)}>
            Blog
          </TrackedA>
        )}
        {shouldIncludeLink(NAV_LINKS.LOGIN) && (
          <a className={s.link} onClick={showLoginModal} href="">
            Login
          </a>
        )}
        {shouldIncludeLink(NAV_LINKS.HOME) && (
          <Link {...urls.homePage()} className={s.link}>
            Home
          </Link>
        )}
        <CTALink
          analyticProps={{ location: 'Nav' }}
          buttonText={ctaText}
          className={s.getStartedLink}
          buttonClassName={cx(s.getStartedButton, {
            [s.getStartedButtonNotScrolled]: !isScrolled && !isCoachPage,
            [s.getStartedButtonScrolled]: isScrolled || isCoachPage,
          })}
          spinnerClassName={s.spinner}
          targetAudience={targetAudience}
        />
      </div>
      <div className={s.socialLinks}>
        {R.addIndex(R.map)(
          ({ fields: { subHeader: url, primaryImage } }, idx) => {
            return (
              <a key={idx} className={s.socialLink} href={url}>
                <CmsImage
                  fields={primaryImage.fields}
                  className={s.socialIcon}
                  isProgressive={false}
                />
              </a>
            )
          },
          socialLinks
        )}
      </div>
    </nav>
  )
}

const NavContainer = props => {
  const { coach } = useContext(CmsPageContext)
  const { firstName } = coach.user

  const linksToInclude = [
    NAV_LINKS.COACH,
    NAV_LINKS.HOW_IT_WORKS,
    NAV_LINKS.TESTIMONIALS,
    NAV_LINKS.PRICING,
  ]
  const coachLinkText = `Meet ${firstName}`

  // TODO: remove this hardcoding if this experiment wins
  return (
    <AnalyticsSection name="Nav">
      <Nav
        {...props}
        linksToInclude={linksToInclude}
        coachLinkText={coachLinkText}
      />
    </AnalyticsSection>
  )
}

export default NavContainer
