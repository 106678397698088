import { useContext, useState } from 'react'
import cx from 'classnames'

import Spinner from 'components/shared/spinner'
import CmsPageContext from 'contexts/cms-page-context'
import s from './styles.module.css'
import { TrackedA } from 'components/shared/analytics'
import { LandingPageAnalytics } from '../analytics'

const GetStartedLink = ({
  text = 'Get started',
  className,
  buttonClassName,
  spinnerClassName,
  analyticProps = { location: '', label: null },
  style,
  name = 'CTA',
  onClick,
}) => {
  const { isCoachPage, signUp } = useContext(CmsPageContext)

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = async () => {
    if (submitting) return

    setSubmitting(true)

    try {
      signUp()
      LandingPageAnalytics.onSignUp({
        location: analyticProps.location,
        label: analyticProps.label,
      })
    } catch (serverError) {
      setSubmitting(false)
      setError(`Something went wrong. We're fixing it now`)
    }
  }

  const handleClick = ev => {
    ev.preventDefault()

    if (onClick && typeof onClick === 'function') {
      onClick()
    }

    if (isCoachPage) {
      const element = document.querySelector(
        ev.currentTarget.getAttribute('href')
      )

      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - 100,
        behavior: 'instant', // Smooth scrolling here was causing issues in desktop Chrome and mobile safari
      })
    } else {
      onSubmit()
    }
  }

  return (
    <>
      <TrackedA
        className={cx(s.getStartedLink, className)}
        onClick={handleClick}
        name={name}
        href="#signUp">
        <button
          className={cx(s.buttonBase, buttonClassName || s.getStartedButton)}
          style={style}>
          <span className={cx({ [s.invisible]: submitting })}>{text}</span>
          {submitting && (
            <div className={s.spinnerWrapper}>
              <Spinner className={cx(s.spinner, spinnerClassName)} />
            </div>
          )}
        </button>
      </TrackedA>
      {error && <div className={s.error}>{error}</div>}
    </>
  )
}

export default GetStartedLink
