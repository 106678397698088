import * as R from 'ramda'
import cx from 'classnames'
import useProducts from 'hooks/use-products'
import CmsPageContext from 'contexts/cms-page-context'
import s from './styles.module.css'
import { useContext, useState } from 'react'
import { NAV_LINKS } from '../nav/constants'
import { LandingPageExperimentAnalytics } from '../analytics'
import { getDailyPrice, getTrialDaysDuration } from 'utilities/stripe'
import { AnalyticsSection } from 'containers/analytics'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import { capitalize } from 'utilities/strings'
import PlanSelection from 'components/payment/payment-variants/control/plan-selection'

const PricingExperiment = ({ fields, landingPageOptions }) => {
  const trialDays = useClientTrialDays()

  const { signUp, coupon, isCoachPage, selectPriceId, coach, query } =
    useContext(CmsPageContext)

  const productsPayload = useProducts()
  const products = productsPayload.data?.products || []

  const isOfferingLiveVideoWorkouts = coach
    ? coach.isOfferingLiveVideoWorkouts
    : true

  const [submittingProductId, setSubmittingProductId] = useState()

  const handleSelection = async (product, price) => {
    if (submittingProductId) return
    const priceId = price.id

    if (isCoachPage) {
      selectPriceId(priceId)

      return document
        .querySelector('#signUp')
        .scrollIntoView({ behavior: 'smooth' })
    }

    setSubmittingProductId(product.id)
    await signUp({ surveyParams: { priceId } })

    LandingPageExperimentAnalytics.onClick({
      location: `Pricing - ${product.descriptiveName} CTA`,
    })
  }

  const productToShow = R.find(R.propEq('group', 'workout'))(products)
  const priceToShow = productToShow?.prices.find(
    p => p.billingPeriodInMonths === 1
  )

  const [dailyPriceInDollars] = priceToShow
    ? getDailyPrice({
        price: priceToShow,
        coupon,
        isFree: !!trialDays,
      })
    : [3]

  const period = getTrialDaysDuration(trialDays)
  const periodFormatted = period && capitalize(period)

  const selectedProducts = R.pipe(
    R.filter(p => p.group === 'workout'),
    R.addIndex(R.filter)((p, i) =>
      isCoachPage && !isOfferingLiveVideoWorkouts ? i === 0 : true
    )
  )(products)

  return (
    <AnalyticsSection name="Pricing">
      <section
        id={NAV_LINKS.pricing}
        className={cx(s.container, { [s.coachBackground]: isCoachPage })}>
        <h3 className={s.title}>
          {fields.header
            .replace(/{price}/g, dailyPriceInDollars)
            .replace('{period}', periodFormatted)}
        </h3>
        <div className={cx(s.terms, s.mobile)}>
          <span>No cancellation fees</span>
          <span>No contracts</span>
          <span>Cancel anytime</span>
        </div>
        <div className={s.planSeletionContainer}>
          <PlanSelection
            products={selectedProducts}
            product={productToShow}
            price={priceToShow}
            options={fields.options}
            landingPageOptions={landingPageOptions}
            coupon={coupon}
            showPlanDuration={
              !isCoachPage &&
              !trialDays &&
              !fields.options?.overrideDailyPrice &&
              !coupon
            }
            isLandingPage
            isCoachPage={isCoachPage}
            onSelection={handleSelection}
            className={s.planSelection}
            loading={!!submittingProductId}
            trialDays={trialDays}
          />
        </div>
        <div className={cx(s.terms, s.desktop)}>
          <span>No cancellation fees</span>
          <span>No contracts</span>
          <span>Cancel anytime</span>
        </div>
      </section>
    </AnalyticsSection>
  )
}

export default PricingExperiment
