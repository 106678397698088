import * as R from 'ramda'

import CmsImage from 'components/shared/cms-image'
import { getFormattedYear } from 'utilities/dates'
import s from './styles.module.css'

const Footer = ({ fields }) => {
  const year = getFormattedYear()

  const {
    primaryItems: internalLinks,
    secondaryItems: socialLinks,
    primaryImage: bbbLogo,
  } = fields

  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <footer className={s.footer}>
      <div className={s.content}>
        <div className={s.main}>
          <div className={s.internalLinks}>
            {R.addIndex(R.map)(
              ({ fields: { header: text, subHeader: url } }, idx) => {
                return (
                  <a key={idx} className={s.internalLink} href={url}>
                    {text}
                  </a>
                )
              },
              internalLinks
            )}
          </div>
          <button className={s.scrollButton} onClick={scrollUp}>
            Back to Top
          </button>
        </div>
        <div className={s.below}>
          <div className={s.socialLinks}>
            {R.addIndex(R.map)(
              ({ fields: { subHeader: url, primaryImage } }, idx) => {
                return (
                  <a key={idx} className={s.socialLink} href={url}>
                    <CmsImage
                      fields={primaryImage.fields}
                      className={s.socialIcon}
                      imageClassName={s.socialIconImage}
                      isProgressive={false}
                    />
                  </a>
                )
              },
              socialLinks
            )}
          </div>
          <div className={s.dba}>
            {bbbLogo && (
              <CmsImage
                fields={bbbLogo.fields}
                className={s.bbbLogoWrapper}
                imageClassName={s.bbbLogo}
                isProgressive={false}
                w={200}
              />
            )}
            <div className={s.dbaContent}>
              <div className={s.dbaText}>© {year} EverFit Inc</div>
              <div className={s.dbaText}>d/b/a Kickoff</div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.dbaMobile}>
        <div className={s.dbaText}>© {year} EverFit Inc, d/b/a Kickoff</div>
        {bbbLogo && (
          <CmsImage
            fields={bbbLogo.fields}
            className={s.bbbLogoWrapper}
            imageClassName={s.bbbLogo}
            isProgressive={false}
            w={200}
          />
        )}
      </div>
    </footer>
  )
}

export default Footer
