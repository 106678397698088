import { useContext } from 'react'
import cx from 'classnames'

import CmsPageContext from 'contexts/cms-page-context'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const SignUpTestimonial = ({
  name,
  title,
  testimonial,
  primaryImageCmsFields,
  primaryImageUrl,
}) => {
  const { isCoachPage } = useContext(CmsPageContext)

  return (
    <div className={s.testimonialWrapper}>
      {primaryImageCmsFields && (
        <CmsImage
          fields={primaryImageCmsFields}
          className={s.imageWrapper}
          imageClassName={s.cmsImage}
          w="400"
          h="400"
        />
      )}
      {primaryImageUrl && (
        <div className={s.imageWrapper}>
          <img className={s.image} src={primaryImageUrl} alt="Coach Profile" />
        </div>
      )}
      <div className={s.testimonialTextWrapper}>
        <div>
          <div className={s.name}>{name}</div>
          <div className={s.title}>{title}</div>
        </div>
        <div
          className={cx(s.testimonial, { [s.coachTestimonial]: isCoachPage })}>
          "{testimonial}"
        </div>
      </div>
    </div>
  )
}

export default SignUpTestimonial
