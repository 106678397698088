import cx from 'classnames'
import { useContext } from 'react'
import CmsPageContext from 'contexts/cms-page-context'
import useScrollPos from 'hooks/use-scroll-pos'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import CTAButton from '../../cta-button'
import s from './styles.module.css'
import { LandingPageExperimentAnalytics } from '../../analytics'

export const isStickyCtaHidden = (y, isCoachPage) => {
  return y < 450 || (isCoachPage && y > 8500)
}

const StickyCTAExperiment = () => {
  const { coach, isCoachPage } = useContext(CmsPageContext)
  const targetAudience = useTargetAudience()
  const isGuestPass = targetAudience === Audience.GuestPass
  const isFreeTrial =
    targetAudience === Audience.FreeTrial ||
    targetAudience === Audience.CoachFreeTrial

  const { y } = useScrollPos()
  const hide = isStickyCtaHidden(y, isCoachPage)
  const show = !hide

  const onClick = () => {
    LandingPageExperimentAnalytics.onCoachStickyCTAClick({
      coachId: coach?.id,
    })
  }

  return (
    <div className={cx(s.container, { [s.hide]: hide, [s.show]: show })}>
      <CTAButton className={s.button} logAnalytics={onClick}>
        {isGuestPass
          ? 'Claim Guest Pass'
          : isFreeTrial
          ? `Start Free Trial`
          : isCoachPage
          ? `Train with Coach ${coach.user.firstName}`
          : 'Find Your Coach'}
      </CTAButton>
    </div>
  )
}

export default StickyCTAExperiment
