import * as R from 'ramda'

import plusIconWhite from 'images/plus-icon-white.svg'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const About = ({ fields }) => {
  const {
    header,
    primaryImage,
    primaryItems: iconItems,
    secondaryItems: textItems,
  } = fields

  return (
    <section className={s.aboutSection}>
      <CmsImage
        className={s.imageWrapper}
        imageClassName={s.image}
        fields={primaryImage.fields}
        fm="jpg"
        fl="progressive"
      />
      <div className={s.main}>
        <div className={s.top}>
          <h2 className={s.header}>{header}</h2>
          <div className={s.iconItems}>
            {R.map(({ fields: { header: label, primaryImage: icon } }) => {
              return (
                <div key={label} className={s.iconItem}>
                  <div className={s.iconItemContent}>
                    <CmsImage className={s.icon} fields={icon.fields} />
                    <label className={s.iconLabel}>{label}</label>
                  </div>
                  <img src={plusIconWhite} alt="Plus" className={s.plusIcon} />
                </div>
              )
            }, iconItems)}
          </div>
        </div>
        <div className={s.textItems}>
          {R.map(({ fields: { header: label, text } }) => {
            return (
              <div key={label} className={s.textItem}>
                <h4 className={s.textItemLabel}>{label}</h4>
                <div className={s.textItemText}>{text}</div>
              </div>
            )
          }, textItems)}
        </div>
      </div>
    </section>
  )
}

export default About
