import { useState, useEffect } from 'react'

import s from './styles.module.css'

const WeightPicker = ({
  value: initialValue,
  onBlur: propOnBlur,
  placeholder = 'Weight',
}) => {
  const [value, setValue] = useState(initialValue || '')
  const [error, setError] = useState(null)

  useEffect(() => {
    setValue(initialValue || '')
  }, [initialValue])

  const onBlur = ev => {
    const newValue = parseInt(ev.target.value)
    if (isNaN(newValue) || !newValue) {
      return setError('Provide a positive number')
    }

    propOnBlur && propOnBlur(newValue)
  }

  const onChange = ev => {
    setError(null)
    setValue(ev.target.value)
  }

  return (
    <div className={s.container}>
      <div className={s.inputWrapper}>
        <input
          type="text"
          value={value}
          className={s.input}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={onChange}
        />
        <label className={s.label}>lbs</label>
      </div>
      {error && <div className={s.error}>{error}</div>}
    </div>
  )
}

export default WeightPicker
