interface Coupon {
  basePrice: number
  amountOff: number
  percentOff: number
  amountOffAsPercent?: boolean
}
export const getDiscountAmountText = ({
  basePrice,
  amountOff,
  percentOff,
  amountOffAsPercent = false,
}: Coupon): string | null => {
  if (percentOff) {
    if (percentOff === 100) {
      return 'free trial'
    } else {
      return `${percentOff}% `
    }
  } else if (amountOff && amountOffAsPercent) {
    const percent = ((amountOff / basePrice) * 100).toFixed()
    return `${percent}% `
  } else if (amountOff) {
    return `$${amountOff / 100}`
  }
  return null
}
