import cx from 'classnames'

import {
  getDailyPrice,
  getDiscountedPrice,
  getCouponNote,
} from 'utilities/stripe'
import { DETAILS_BY_CALLS_PER_WEEK } from 'constants/products'
import Spinner from 'components/shared/spinner'
import ProductDetails from 'components/shared/product-details'
import s from './styles.module.css'

const Product = ({
  product,
  product: { name, isRecommended, callsPerWeek, prices },
  onSelect,
  submitting,
  productClassName,
  coupon,
  isCurrent = false,
  showRecommended = true,
  canSelectCurrent = false,
}) => {
  const details = DETAILS_BY_CALLS_PER_WEEK[callsPerWeek]
  const [price] = prices
  const [dailyPriceInDollars] = getDailyPrice({ price, coupon })
  const firstMonthPriceInDollars = Math.round(
    getDiscountedPrice({
      price,
      coupon,
      isFirstTerm: true,
    }) / 100
  )
  const secondMonthPriceInDollars = Math.round(
    getDiscountedPrice({
      price,
      coupon,
      isFirstTerm: false,
    }) / 100
  )
  const isOneMonthDiscount =
    firstMonthPriceInDollars !== secondMonthPriceInDollars

  return (
    <div
      className={cx(s.product, productClassName, {
        [s.productCurrent]: isCurrent,
      })}>
      <header className={s.header}>
        <h6 className={s.name}>{name}</h6>
        {showRecommended && isRecommended && (
          <em className={s.highlight}>Recommended</em>
        )}
        {isCurrent && <em className={s.highlight}>Current plan</em>}
      </header>
      <div className={s.details}>
        <ProductDetails details={details} />
      </div>
      <footer className={s.footer}>
        <div>
          <div className={s.perDay}>
            <span className={s.perDayAmount}>${dailyPriceInDollars}</span>
            <span className={s.perDayLabel}>/day</span>
          </div>
          {isOneMonthDiscount && (
            <div className={s.monthlyPrice}>
              First month only ${firstMonthPriceInDollars} - subsequent months $
              {secondMonthPriceInDollars}
            </div>
          )}
          {!isOneMonthDiscount && (
            <div className={s.monthlyPrice}>
              Billed once a month as ${secondMonthPriceInDollars}
              {getCouponNote({ coupon })}
            </div>
          )}
        </div>
        {(!isCurrent || canSelectCurrent) && (
          <button className={s.button} onClick={onSelect}>
            <span className={cx({ [s.invisible]: submitting })}>
              Select Plan
            </span>
            {submitting && (
              <div className={s.spinnerWrapper}>
                <Spinner className={s.spinner} />
              </div>
            )}
          </button>
        )}
      </footer>
    </div>
  )
}

export default Product
