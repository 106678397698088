import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { Button } from 'components/design-system'

import { LandingPageAnalytics } from '../analytics'

import urls from 'utilities/urls'

const CoachSignupLink = ({
  text = 'Get started',
  analyticProps = { location: '', label: null },
  buttonClassName = '',
}) => {
  const { query } = useRouter()
  const href = useMemo(() => {
    return urls.coachSignUp({ ref: query?.ref }).as
  }, [query])

  /**
   * Redirect the user to the apply page, making sure
   * to pass on the `ref` query parameter if it is present
   * Also make sure to kick off and log an analytics event
   */
  const handleClick = () => {
    LandingPageAnalytics.onCoachApply({
      location: analyticProps.location,
      label: analyticProps.label,
      ref: query?.ref,
    })
  }

  return (
    <Button to={href} onClick={handleClick} className={buttonClassName}>
      {text}
    </Button>
  )
}

export default CoachSignupLink
