import * as R from 'ramda'
import EditButton from '../../edit-button'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'
import SectionTitle from '../../experiment/common/section-title'

export const COACH_MATCH_PROMOTION_QUESTION_IDS = {
  WHY_I_LOVE_BEING_A_TRAINER: 199,
  MY_BACKGROUND: 200,
  MY_GO_TO_WORKOUT: 201,
  HEALTHY_FOOD_I_LOVE: 202,
  HEALTHY_FOOD_I_HATE: 203,
  FAVORITE_WORKOUT_MUSIC: 204,
  MOST_SURPRISED_TO_LEARN_ABOUT_ME: 205,
  WHY_TRAIN_WITH_ME: 137,
}

const CoachSurveyResponse = ({
  response,
}: {
  response: {
    question: string
    responseValue: string
    surveyQuestionId: number
  }
}) => {
  return (
    <div className={s.response}>
      <SectionTitle
        title={
          <>
            {response.question}
            <EditButton questionId={response.surveyQuestionId} />
          </>
        }
      />

      <p className={s.text}>{response.responseValue}</p>
    </div>
  )
}

interface CoachSurveyResponsesProps extends CoachProps {
  responseIds: number[]
}

const CoachSurveyResponses = ({
  coach,
  responseIds,
}: CoachSurveyResponsesProps) => {
  const responses = coach.promotionQuestionResponses.filter(p =>
    responseIds.includes(parseInt(p.surveyQuestionId, 10))
  )

  return (
    <div className={s.container}>
      {R.map(
        response => (
          <CoachSurveyResponse key={response.id} response={response} />
        ),
        responses
      )}
    </div>
  )
}

export default CoachSurveyResponses
