import { useEffect, useState } from 'react'
import throttle from 'lodash.throttle'

const THROTTLE = 100

const getPos = () => {
  if (typeof window === 'undefined') {
    return { x: 0, y: 0 }
  }

  return {
    x: window.scrollX,
    y: window.scrollY,
  }
}

const useScrollPos = () => {
  const [scrollPos, setScrollPos] = useState(getPos())

  useEffect(() => {
    const handleScroll = throttle(() => {
      setScrollPos(getPos())
    }, THROTTLE)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return scrollPos
}

export default useScrollPos
