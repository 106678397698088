import kickoffLogoStandard from 'images/kickoff-logo-standard.svg'
import s from './styles.module.css'
import GoalsDropdown from '../goal-dropdown'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'

const GetStartedExperiment = ({ landingPageOptions }) => {
  const targetAudience = useTargetAudience()
  const isGuestPass = targetAudience === Audience.GuestPass
  const isTYPGuestPass = targetAudience === Audience.ThankYouPageGuestPass

  return (
    <div className={s.container}>
      <div className={s.innerContainer}>
        <div className={s.logoContainer}>
          <img src={kickoffLogoStandard} alt="Kickoff" className={s.logo} />
        </div>
        <h2 className={s.title}>
          {isGuestPass || isTYPGuestPass
            ? 'Get started for free'
            : 'Get Started'}
        </h2>
        <GoalsDropdown
          analyticProps={{ location: 'Get Started' }}
          landingPageOptions={landingPageOptions}
        />
      </div>
    </div>
  )
}

export default GetStartedExperiment
