import { createContext, useContext, ReactNode } from 'react'

interface IModalConfig {
  theme?: 'black' | 'white'
  className?: string
  overlayClassName?: string
  disableClose?: boolean
  key?: string
  onClose?: () => void
}

interface IModalContext {
  isOpen: boolean
  open: (render: () => ReactNode, props?: {}, config?: IModalConfig) => void
  close: () => void
  setProps: (props: {}) => void
  setConfig: (newConfig: {}) => void
  isBlur: boolean
}

const ModalContext = createContext<IModalContext>({
  isOpen: false,
  open: () => {},
  close: () => {},
  setProps: () => {},
  setConfig: () => {},
  isBlur: false,
})

export default ModalContext

export const useModalContext = () => useContext(ModalContext)
