import cx from 'classnames'
import s from './styles.module.css'

const Section = ({ children, wrap = 'wrap' }) => {
  return (
    <div className={s.container}>
      <div
        className={cx(s.row, wrap === 'wrapReverse' ? s.wrapReverse : s.wrap)}>
        {children}
      </div>
    </div>
  )
}

export default Section
