import cx from 'classnames'
import EditButton from '../../edit-button'
import s from './styles.module.css'

interface CoachPhotoProps {
  url?: string
  alt?: string
  className?: string
  questionId?: number
}

const CoachPhoto = ({ url, alt, className, questionId }: CoachPhotoProps) => {
  if (!url) return null
  return (
    <div className={s.container}>
      <img src={url} alt={alt} className={cx(className, s.img)} />
      <EditButton questionId={questionId} isImageOverlay={true} />
    </div>
  )
}

export default CoachPhoto
