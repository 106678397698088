import { useState, useEffect } from 'react'

const useScrollThreshold = (scrollThreshold = 50) => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const onScroll = () => setIsScrolled(window.scrollY >= scrollThreshold)

    window.addEventListener('scroll', onScroll)
    onScroll()

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [scrollThreshold])

  return isScrolled
}

export default useScrollThreshold
