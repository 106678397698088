import Link from 'next/link'

import urls from 'utilities/urls'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const BlogPostPreview = ({
  post: {
    fields: { slug, title, excerpt, heroImage },
    sys: { createdAt },
  },
}) => {
  return (
    <Link {...urls.blog({ slug })} className={s.blogPostLink}>
      <article className={s.blogPost}>
        <div className={s.blogPostHeroWrapper}>
          {heroImage && (
            <CmsImage
              fields={heroImage.fields}
              className={s.blogPostHero}
              fm="jpg"
              fl="progressive"
              isProgressive={true}
              w={600}
            />
          )}
        </div>
        <div className={s.blogPostInfo}>
          <h4 className={s.blogPostTitle}>{title}</h4>
          <h6 className={s.blogPostExcerpt}>{excerpt}</h6>
        </div>
      </article>
    </Link>
  )
}

export default BlogPostPreview
