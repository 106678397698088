import * as R from 'ramda'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TextSelect from 'components/shared/text-select'
import s from './styles.module.css'

const MultiSelectInput = ({
  options = [],
  optionsLoading,
  placeholder,
  onChange,
  setClosestMatch,
  onFocus,
  onSelect,
  onRemove,
  values,
  forceClear,
  allowCustom = true,
}) => {
  const handleChange = ({ value, closestMatch }) => {
    onChange(value)
    setClosestMatch && setClosestMatch(closestMatch)
  }

  return (
    <div>
      <ul className={s.values}>
        {R.map(({ label, value }) => {
          return (
            <li key={value} className={s.value}>
              {label}
              <button
                className={s.deleteButton}
                onClick={() => onRemove(value)}>
                <FontAwesomeIcon icon={faTimes} className={s.deleteIcon} />
              </button>
            </li>
          )
        }, values)}
      </ul>
      <TextSelect
        options={options}
        allowCustom={allowCustom}
        showAddNew={allowCustom}
        loading={optionsLoading}
        onFocus={onFocus}
        onOptionSelect={onSelect}
        onCustomSelect={() => onSelect()}
        onChange={handleChange}
        placeholder={placeholder}
        forceClear={forceClear}
      />
    </div>
  )
}

export default MultiSelectInput
