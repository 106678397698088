export const NAV_LINKS = {
  HOW_IT_WORKS: 'howItWorks',
  TESTIMONIALS: 'testimonials',
  PRICING: 'pricing',
  TRAINERS: 'trainers',
  JOBS: 'jobs',
  LOGIN: 'login',
  HOME: 'home',
  COACH: 'coach',
  GIFTS: 'gifts',
}

export const DEFAULT_LINKS = [
  NAV_LINKS.HOW_IT_WORKS,
  NAV_LINKS.TESTIMONIALS,
  NAV_LINKS.PRICING,
  NAV_LINKS.TRAINERS,
  NAV_LINKS.JOBS,
  NAV_LINKS.GIFTS,
  NAV_LINKS.LOGIN,
]
