import * as R from 'ramda'

import Coach from './coach'
import Match from './match'
import KickOff from './kick-off'
import WorkOut from './work-out'
import s from './styles.module.css'

const SECTION_COMPONENTS = {
  Coach,
  Match,
  KickOff,
  WorkOut,
}

const HowItWorks = ({ fields }) => {
  const { header, subHeader, primaryItems: sections } = fields

  return (
    <section id="howItWorks" className={s.howItWorksSection}>
      <header className={s.headers}>
        <h2 className={s.header}>{header}</h2>
        <h4 className={s.subHeader}>{subHeader}</h4>
      </header>
      <div className={s.sections}>
        {R.addIndex(R.map)(({ fields }, idx) => {
          const { component } = fields
          const SectionComponent = SECTION_COMPONENTS[component]
          return <SectionComponent key={idx} idx={idx} fields={fields} />
        }, sections)}
      </div>
    </section>
  )
}

export default HowItWorks
