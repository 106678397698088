// sync any changes with node/utilities/target-audience.ts
export enum Audience {
  GuestPass = 'guestpass',
  ThankYouPageGuestPass = 'typ_guestpass',
  FreeTrial = 'freetrial',
  CoachFreeTrial = 'coachfreetrial',
  Client = 'client',
  Coach = 'coach',
}

export function getClientTargetAudience({
  ref,
  isCoachFreeTrial,
}: {
  ref?: string
  isCoachFreeTrial?: boolean
}) {
  if (isCoachFreeTrial) return Audience.CoachFreeTrial

  const [platform, freetrial, clientSlug, thankYouPageGuestPass, gpLength] =
    ref?.split('_') || []
  const isFreeTrial = freetrial === 'freetrial'
  const isCoachRefFreeTrial = freetrial === 'coachfreetrial'
  const isThankYouPageGuestPass =
    thankYouPageGuestPass === 'typ' && gpLength === '7'
  const isGuestPass = platform === 'gp' && clientSlug

  if (isCoachRefFreeTrial) return Audience.CoachFreeTrial
  if (isThankYouPageGuestPass) return Audience.ThankYouPageGuestPass
  if (isGuestPass) return Audience.GuestPass
  if (isFreeTrial) return Audience.FreeTrial

  return Audience.Client
}
