import { useContext } from 'react'
import { Paper } from 'components/design-system'
import s from './styles.module.css'
import RedeemIllustration from 'images/verified_illustration.svg'
import PersonalTrainerIllustration from 'images/personal_trainer_illustration.svg'
import TrainingIllustration from 'images/fitness_illustration.svg'
import CmsPageContext from 'contexts/cms-page-context'
import { useClientTrialDays } from 'hooks/use-client-trial-days'

const getContent = (coach: Record<string, any>, trialDays: number) => {
  const coachName = coach?.user?.firstName
  const FREE_TRIAL_CONTENT = {
    step1: {
      title: 'Redeem your free month',
      description: `Sign up today and pay nothing
      for your first ${trialDays} days of working with ${coachName}. You can download the app and get started immediately with our new client survey.`,
      illustration: RedeemIllustration,
    },
    step2: {
      title: 'Start training',
      description: `You and ${coachName} will discuss your background
      with fitness, nutrition and exercise preferences,
      schedule and goals. ${coachName} will create a customized workout and nutrition program for you and send it to you for review.`,
      illustration: TrainingIllustration,
    },
    step3: {
      title: `Work with ${coachName}`,
      description: `${coachName} will check in regularly (every day if you want!)
      on your program and how you’re feeling about your progress. You can make adjustments as often as you need to. Most clients start to see significant results within 1-2 weeks.`,
      illustration: PersonalTrainerIllustration,
    },
  }

  const PAID_CONTENT = {
    ...FREE_TRIAL_CONTENT,
    step1: {
      title: `Sign Up with ${coachName}`,
      description: `Sign up today and get started with our new client survey. ${coachName} will then reach out to schedule a consultation call.`,
      illustration: RedeemIllustration,
    },
  }
  return trialDays ? FREE_TRIAL_CONTENT : PAID_CONTENT
}

const Card = ({
  title,
  description,
  illustration,
}: {
  title: string
  description: string
  illustration: string
}) => {
  return (
    <Paper className={s.card}>
      <div className={s.illustrationContainer}>
        <img alt="illustration" src={illustration} />
      </div>
      <h6 className={s.cardTitle}>{title}</h6>
      <p className={s.cardText}>{description}</p>
    </Paper>
  )
}

const HowItWorks = ({ fields }) => {
  const { coach } = useContext(CmsPageContext)

  const { header } = fields

  const trialDays = useClientTrialDays()
  const content = getContent(coach, trialDays)

  return (
    <section id="howItWorks" className={s.howItWorksSection}>
      <header className={s.headers}>
        <h2 className={s.header}>{header}</h2>
        {/* <h4 className={s.subHeader}>{subHeader}</h4> */}
      </header>
      <div className={s.sections}>
        <Card
          title={content.step1.title}
          description={content.step1.description}
          illustration={content.step1.illustration}
        />
        <Card
          title={content.step2.title}
          description={content.step2.description}
          illustration={content.step2.illustration}
        />
        <Card
          title={content.step3.title}
          description={content.step3.description}
          illustration={content.step3.illustration}
        />
      </div>
    </section>
  )
}

export default HowItWorks
