import cx from 'classnames'

import useWindowSize from 'hooks/use-window-size'
import useTargetAudience from 'hooks/use-target-audience'
import CmsImage from 'components/shared/cms-image'
import CTALink from 'components/homepage/cta-link'
import s from './styles.module.css'

const Hero = ({
  fields,
  overridePrimaryImageFields,
  className,
  overlayClassName,
  mainClassName,
  windowHeightPercentage = 0.95,
  minHeight = 600,
  ctaText = 'Get started',
}) => {
  const targetAudience = useTargetAudience()
  const { height: windowHeight } = useWindowSize({ ignoreHeightChange: true })
  const height =
    windowHeight && Math.max(windowHeight * windowHeightPercentage, minHeight)
  const { header, subHeader, primaryImage, mobileImage, video } = fields

  return (
    <section
      className={cx(s.heroSection, className)}
      style={{ height: height ? `${height}px` : undefined }}>
      <CmsImage
        className={s.heroImage}
        fields={overridePrimaryImageFields || primaryImage?.fields}
        videoFields={video?.fields}
        mobileFields={mobileImage?.fields}
        fm="jpg"
        fl="progressive"
        isProgressive={true}
      />
      <div className={cx(s.heroImageOverlay, overlayClassName)} />
      <div className={cx(s.main, mainClassName)}>
        <header className={s.headerWrapper}>
          <h1 className={s.header}>{header}</h1>
          <h2 className={s.subHeader}>{subHeader}</h2>
          <CTALink
            analyticProps={{ location: 'Top Hero' }}
            targetAudience={targetAudience}
            buttonText={ctaText}
            className={s.getStartedLink}
          />
        </header>
      </div>
    </section>
  )
}

export default Hero
