import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import { TABLET_WIDTH } from 'constants/breakpoints'
import useIsFirstRender from 'hooks/use-is-first-render'

const BrandedHeaderExperiment = ({
  fields: { header, subHeader, primaryImage },
}) => {
  const isFirstRender = useIsFirstRender()
  const isMobile = useWindowSizeCondition(({ width }) => width <= TABLET_WIDTH)

  return (
    <section className={s.container}>
      <div className={s.headers}>
        <div className={s.header}>{header}</div>
        <div className={s.subheader}>{subHeader}</div>
      </div>
      <CmsImage
        className={s.image}
        fields={primaryImage.fields}
        mobileFields={primaryImage.fields}
        useNextImage
        objectFit={isMobile && !isFirstRender ? 'cover' : 'contain'}
      />
    </section>
  )
}

export default BrandedHeaderExperiment
