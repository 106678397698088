import { useState, useEffect } from 'react'

const DISABLED_STATE = { width: undefined, height: undefined }

const useWindowSize = ({ delayed = true, ignoreHeightChange = false } = {}) => {
  const [isEnabled, setIsEnabled] = useState(!delayed)

  const getSize = () => {
    return {
      width: process.browser ? window.innerWidth : undefined,
      height: process.browser ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!process.browser) return false

    setIsEnabled(true)

    const handleResize = () => {
      const newSize = getSize()

      // only updates on width change. useful for ignoring window height change events on scroll
      if (ignoreHeightChange && newSize.width === windowSize.width) return

      setWindowSize(newSize)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [ignoreHeightChange, windowSize.width])

  return isEnabled ? windowSize : DISABLED_STATE
}

export default useWindowSize
