import { PAYMENT_TOKEN } from 'constants/cookies'
import { NextPageContext, NextApiRequest } from 'next'
import { parseCookies } from 'nookies'

export const getCookie = (
  ctx: Pick<NextPageContext, 'req'> | { req: NextApiRequest },
  key: string
) => parseCookies(ctx)[key]
export const getPaymentToken = (ctx?: undefined) =>
  getCookie(ctx, PAYMENT_TOKEN)
