import { useContext } from 'react'
import cx from 'classnames'
import CmsPageContext from 'contexts/cms-page-context'
import s from './styles.module.css'
import * as R from 'ramda'
import { generateCMSDynamicLocalText } from '../helpers'

export const OtherStateCitiesGrid = ({ fields }) => {
  const { locationDetails } = useContext(CmsPageContext)
  const { otherCities } = locationDetails

  const { header } = fields
  const title = generateCMSDynamicLocalText(header, locationDetails)

  return (
    <section className={cx(s.container)}>
      <div className={s.content}>
        <div className={s.title}>{title}</div>
        <div className={s.grid}>
          {R.map(
            ({ name, slug, state: { abbreviation: stateAbbreviation } }) => {
              return (
                <a
                  key={slug}
                  href={`/local/${slug}/personal-trainers`}
                  className={s.gridItemLink}>
                  <div className={s.gridItem}>
                    {name}, {stateAbbreviation}
                  </div>
                </a>
              )
            },
            otherCities
          )}
        </div>
      </div>
    </section>
  )
}
