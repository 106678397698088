import { useMutation } from '@apollo/client'
import * as R from 'ramda'
import cx from 'classnames'
import Link from 'next/link'

import useQueryParams from 'hooks/use-query-params'
import useCurrentUser from 'hooks/use-current-user'
import urls from 'utilities/urls'
import { PAYMENT_QUESTION_ID } from 'components/sign-up/constants'
import {
  selectCoach as selectCoachMutation,
  logAnalyticsEventClientSignUpSelectCoach as logAnalyticsEventClientSignUpSelectCoachMutation,
} from './mutations.graphql'
import { TrackedA } from 'components/shared/analytics'
import s from './styles.module.css'
import { getPaymentToken } from 'utilities/cookies'
import { useContext } from 'react'
import CmsSurveyContext from 'contexts/cms-survey-context'
import { useCoachProfileContext } from 'contexts/coach-profile-context'
import { useExperimentSplit } from 'utilities/experiment/context'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'
import { CoachProfileAnalytics } from 'components/coach-profile/analytics'

interface TrainButtonProps extends CoachProps {
  buttonClassName?: string
  name?: string
  className?: string
}

const TrainButton = ({
  className,
  buttonClassName,
  name = 'CTA',
  coach,
}: TrainButtonProps) => {
  const { clientId } = useContext(CmsSurveyContext)
  const { editing, onCtaClick } = useCoachProfileContext()
  const clpSufVariant = useExperimentSplit('clpSuf')

  const currentUserPayload = useCurrentUser()
  const currentUserClientId = R.path(
    ['data', 'me', 'user', 'client', 'id'],
    currentUserPayload
  )

  const coachId = coach.id

  const queryParams = useQueryParams()
  const paymentUrl = extraPaymentParams =>
    urls.clientSignUp({
      ...queryParams,
      clientId,
      questionId: PAYMENT_QUESTION_ID,
      coachId: coach.id,
      ...extraPaymentParams,
    })

  const clientSignUpUrl = urls.clientSignUp({
    ...queryParams,
    clientId,
    refCoachId: coach.id,
  })

  const [logAnalyticsEventClientSignUpSelectCoach] = useMutation(
    logAnalyticsEventClientSignUpSelectCoachMutation
  )
  const [selectCoach] = useMutation(selectCoachMutation)

  const handleSelectCoach = () => {
    if (currentUserClientId === clientId) {
      // if the user themself is executing the actions
      // then log the analytics event client-side
      CoachProfileAnalytics.onSelectCoach({
        clientId,
        coachId,
      })
    } else {
      // if an admin is executing the actions
      // then log the analytics event server-side
      logAnalyticsEventClientSignUpSelectCoach({
        variables: {
          clientId: clientId,
          eventProperties: {
            'Client ID': clientId,
            'Coach ID': coachId,
          },
        },
      })
    }
  }

  const appSelectCoach = async () => {
    handleSelectCoach()

    // app users get redirected from payment page so select here
    await selectCoach({
      variables: { clientId, coachId },
    })

    const extraPaymentParams = { paymentToken: getPaymentToken() }

    // trigger full refresh so webview picks up on it
    window.location.href = paymentUrl(extraPaymentParams).as
  }

  const text = `Train with ${coach.user.firstName}`

  if (editing) return null

  return (
    <>
      {/* @ts-ignore */}
      {!onCtaClick && !window.ReactNativeWebView && (
        <>
          {/* @ts-ignore */}
          <Link {...paymentUrl()}>
            <a className={cx(s.link, className)} onClick={handleSelectCoach}>
              <button className={cx(s.button, buttonClassName)}>{text}</button>
            </a>
          </Link>
        </>
      )}
      {/* @ts-ignore */}
      {!onCtaClick && window.ReactNativeWebView && (
        <button
          className={cx(s.button, buttonClassName, className)}
          onClick={appSelectCoach}>
          {text}
        </button>
      )}
      {onCtaClick && clpSufVariant === 'B' && (
        <Link
          {...clientSignUpUrl}
          className={cx(s.link, className)}
          onClick={handleSelectCoach}>
          <button className={cx(s.button, buttonClassName)}>{text}</button>
        </Link>
      )}
      {onCtaClick && clpSufVariant !== 'B' && (
        <TrackedA
          name={name}
          className={cx(s.link, className)}
          href="#signUp"
          onClick={onCtaClick}>
          <button className={cx(s.button, buttonClassName)}>{text}</button>
        </TrackedA>
      )}
    </>
  )
}

export default TrainButton
