import { useContext } from 'react'

import Nav from 'components/homepage/nav'
import { NAV_LINKS } from 'components/homepage/nav/constants'
import CmsPageContext from 'contexts/cms-page-context'

const CoachProfileNav = ({ fields }) => {
  const { coach } = useContext(CmsPageContext)
  const { firstName } = coach.user

  return (
    <Nav
      fields={fields}
      linksToInclude={[
        NAV_LINKS.COACH,
        NAV_LINKS.HOW_IT_WORKS,
        NAV_LINKS.TESTIMONIALS,
        NAV_LINKS.PRICING,
      ]}
      coachLinkText={`Meet ${firstName}`}
    />
  )
}

export default CoachProfileNav
