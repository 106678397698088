import Head from 'next/head'

const buildQuestions = mainEntity => `
  ${mainEntity.map(
    question => `{
      "@type": "Question",
      "name": "${question.question}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${question.answer}"
      }
    }`
  )}`

const FaqPageJsonLd = ({ mainEntity }) => {
  const jsonld = `{
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [${buildQuestions(mainEntity)}]
  }`

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: jsonld }}
        key="jsonld-faq-page"
      />
    </Head>
  )
}

export default FaqPageJsonLd
