import urls from 'utilities/urls'
import Nav from 'components/homepage/nav'

const BlogNav = ({ fields, blogProps }) => {
  return (
    <Nav
      fields={fields}
      linksToInclude={['home']}
      ctaText="Find a trainer"
      logoLinkProps={blogProps.entry ? urls.blog() : urls.homePage()}
    />
  )
}

export default BlogNav
