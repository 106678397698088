import EditButton from '../../edit-button'
import { COACH_INTRO_VIDEO_QUESTION_ID } from 'constants/cms-survey'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/pro-duotone-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import s from './styles.module.css'
import { useRef, useState } from 'react'
import CoachSurveyResponses, {
  COACH_MATCH_PROMOTION_QUESTION_IDS,
} from '../coach-survey-responses'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

library.add(faPlayCircle as any)

const CoachIntroVideo = ({ coach }: CoachProps) => {
  const videoRef = useRef<HTMLVideoElement>()
  const [showControls, setShowControls] = useState(false)

  const handleInitalPlay = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.play()
      setShowControls(true)
    }
  }

  if (!coach.introVideo?.url) {
    return null
  }

  return (
    <div className={s.container}>
      <div className={s.leftCol}>
        <div className={s.videoWrapper}>
          {coach.introVideo ? (
            <video
              ref={videoRef}
              playsInline
              src={`${coach.introVideo.url}#t=0.04`} // ios hack to get preview of video
              className={s.video}
              controls={showControls}
            />
          ) : (
            <div className={s.videoPlaceholder}></div>
          )}
          <FontAwesomeIcon
            icon={['fad', 'play-circle']}
            style={
              {
                '--fa-secondary-color': 'black',
                display: showControls ? 'none' : 'initial',
              } as any
            }
            size="5x"
            className={s.playbutton}
            onClick={handleInitalPlay}
          />
          <EditButton
            questionId={COACH_INTRO_VIDEO_QUESTION_ID}
            isImageOverlay={true}
          />
        </div>
      </div>
      <div className={s.rightCol}>
        <CoachSurveyResponses
          coach={coach}
          responseIds={[
            COACH_MATCH_PROMOTION_QUESTION_IDS.WHY_I_LOVE_BEING_A_TRAINER,
            COACH_MATCH_PROMOTION_QUESTION_IDS.MY_BACKGROUND,
          ]}
        />
      </div>
    </div>
  )
}

export default CoachIntroVideo
