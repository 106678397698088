import CameraIcon from './icons/camera-icon.svg'
import ChatIcon from './icons/chat-icon.svg'
import DumbbellIcon from './icons/dumbbell-icon.svg'
import PuzzleIcon from './icons/puzzle-icon.svg'
import s from './styles.module.css'

const HowItWorksExperiment = ({ fields: { header } }) => {
  return (
    <section className={s.container} id="how-it-works">
      <div className={s.header}>{header}</div>
      <div className={s.content}>
        <div className={s.cardSection}>
          <div className={s.numberAndIcon}>
            <span className={s.number}>1</span>
            <img className={s.icon} src={PuzzleIcon} alt="Puzzle Icon" />
          </div>
          <div className={s.card}>
            Complete our 15-point quiz to match with the perfect coach
          </div>
        </div>
        <div className={s.cardSection}>
          <div className={s.numberAndIcon}>
            <span className={s.number}>2</span>
            <img className={s.icon} src={CameraIcon} alt="camera icon" />
          </div>
          <div className={s.card}>
            Video call to align on a custom exercise & nutrition plan
          </div>
        </div>
        <div className={s.cardSection}>
          <div className={s.numberAndIcon}>
            <span className={s.number}>3</span>
            <img className={s.icon} src={DumbbellIcon} alt="dumbbell icon" />
          </div>
          <div className={s.card}>
            Do workouts designed just for you while optimizing your nutrition
          </div>
        </div>
        <div className={s.cardSection}>
          <div className={s.numberAndIcon}>
            <span className={s.number}>4</span>
            <img className={s.icon} src={ChatIcon} alt="chat icon" />
          </div>
          <div className={s.card}>
            Stay accountable with daily checkins and optional calls
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorksExperiment
