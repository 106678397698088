export const isServer = () => typeof window === 'undefined'

// https://stackoverflow.com/a/21742107/3431180
function whichMobileOS() {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  // Windows Phone must come first because its UA also contains 'Android'
  if (/windows phone/i.test(ua)) {
    return 'Windows Phone'
  }
  if (/android/i.test(ua)) {
    return 'Android'
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return 'iOS'
  }
  return null
}

export function isMobile() {
  return Boolean(whichMobileOS())
}

export const hasTouch = () => {
  return (
    process.browser &&
    document.documentElement &&
    ('ontouchstart' in document.documentElement ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0)
  )
}

export const IS_PROD = process.env.APP_ENV === 'production'
export const IS_STAGING = process.env.APP_ENV === 'staging'
export const IS_DEV = process.env.APP_ENV === 'development'
