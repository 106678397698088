import * as R from 'ramda'
import * as D from 'date-fns'
import cx from 'classnames'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import urls from 'utilities/urls'
import { renderContentItems } from 'utilities/contentful'
import BlogPostPreview from './blog-post-preview'
import s from './styles.module.css'

library.add(faChevronLeft)

const BlogContent = ({
  blogProps: { entry, entries, hideBackArrow, hideDate },
}) => {
  return (
    <main className={cx(s.contentWrapper, { [s.contentWrapperPost]: !!entry })}>
      <section className={cx(s.content, { [s.contentPost]: !!entry })}>
        {entry && (
          <>
            {!hideBackArrow && (
              <Link {...urls.blog()} className={s.backLink}>
                <FontAwesomeIcon
                  icon={['fal', 'chevron-left']}
                  className={s.backIcon}
                />
                Back
              </Link>
            )}
            <article className={s.article}>
              <h1 className={s.title}>{entry.fields.title}</h1>
              {entry.fields.coachName && (
                <div className={s.by}>
                  <span>By </span>
                  {entry.fields.coachUsername && (
                    <a
                      className={s.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      href={urls.coachReferral({
                        username: entry.fields.coachUsername,
                      })}>
                      {entry.fields.coachName}
                    </a>
                  )}
                  {!entry.fields.coachUsername && entry.fields.coachName}
                </div>
              )}
              {!hideDate && (
                <time className={s.date}>
                  {D.format(new Date(entry.sys.createdAt), 'MMMM DD, YYYY')}
                </time>
              )}
              {renderContentItems(entry.fields.content.content, true)}
            </article>
          </>
        )}
        {entries && (
          <div className={s.postsWrapper}>
            <ol className={s.posts}>
              {R.map(post => {
                return (
                  <li key={post.fields.slug} className={s.postWrapper}>
                    <BlogPostPreview post={post} />
                  </li>
                )
              }, entries)}
            </ol>
          </div>
        )}
      </section>
    </main>
  )
}

export default BlogContent
