import s from './styles.module.css'

const ImpactStatementExperiment = ({ fields: { header } }) => {
  return (
    <section className={s.container}>
      <div className={s.text}>{header}</div>
    </section>
  )
}

export default ImpactStatementExperiment
export const ImpactStatement = ImpactStatementExperiment
