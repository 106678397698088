import { useContext } from 'react'

import CmsPageContext from 'contexts/cms-page-context'
import CoachProfileExperimentDesignSystem from '../coach-variants/experiment-design-system'

export interface CoachProps {
  coach: {
    id: number
    profileImage?: {
      url: string
    }
    actionImage?: {
      url: string
    }
    bodyImage?: {
      url: string
    }
    humanizingImage?: {
      url: string
    }
    rating: {
      score: number
      total: number
    }
    specialties: any[]
    certifications: any[]
    missionStatement: string
    quote: string
    user: {
      firstName: string
      username: string
    }
    tags?: any[]
    tagsByVariant?: any[]
    introVideo?: {
      url: string
    }
    promotionQuestionResponses?: any[]
    whyTrainWithMe: string
    reviews: any[]
    sourcingAuditPassed: boolean
  }
}

const CoachProfileHero = () => {
  const { coach, onAnchorLinkClick } = useContext(CmsPageContext)

  if (!coach) {
    return null
  }
  return (
    <CoachProfileExperimentDesignSystem
      coach={coach}
      onCtaClick={onAnchorLinkClick}
    />
  )
}

export default CoachProfileHero
