const DiplomaIcon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Diploma Verified Icon">
      <path
        id="Subtract"
        d="M1.32129 11.9286V7.35714C1.32129 4.12466 1.32129 2.50841 2.32549 1.50421C3.3297 0.5 4.94594 0.5 8.17843 0.5H17.3213C20.5538 0.5 22.17 0.5 23.1742 1.5042C24.1784 2.50841 24.1784 4.12465 24.1784 7.35714V11.9286C24.1784 15.1611 24.1784 16.7773 23.1742 17.7815C22.219 18.7367 20.7101 18.7833 17.7832 18.7856C17.9461 18.1802 17.8129 17.5128 17.3835 17.0089C17.1167 16.6958 16.9555 16.3066 16.9228 15.8966C16.8464 14.9397 16.0866 14.18 15.1298 14.1036C14.7198 14.0709 14.3305 13.9096 14.0175 13.6429C13.2869 13.0203 12.2124 13.0203 11.4818 13.6429C11.1688 13.9096 10.7795 14.0709 10.3695 14.1036C9.41268 14.18 8.65289 14.9397 8.57654 15.8966C8.54382 16.3066 8.38258 16.6958 8.11579 17.0089C7.68641 17.5128 7.55317 18.1802 7.71606 18.7856C4.78946 18.7833 3.28063 18.7366 2.32549 17.7815C1.32129 16.7773 1.32129 15.1611 1.32129 11.9286Z"
        fill="#B5C1D5"
      />
      <g id="Vector">
        <path
          d="M8.46467 5.0715C8.46467 4.59811 8.84843 4.21436 9.32181 4.21436H16.179C16.6523 4.21436 17.0361 4.59811 17.0361 5.0715C17.0361 5.54489 16.6523 5.92864 16.179 5.92864H9.32181C8.84843 5.92864 8.46467 5.54489 8.46467 5.0715Z"
          fill="white"
        />
        <path
          d="M7.0361 8.21436C6.56271 8.21436 6.17896 8.59811 6.17896 9.0715C6.17896 9.54489 6.56271 9.92864 7.0361 9.92864H18.4647C18.9381 9.92864 19.3218 9.54489 19.3218 9.0715C19.3218 8.59811 18.9381 8.21436 18.4647 8.21436H7.0361Z"
          fill="white"
        />
      </g>
      <path
        id="Vector_2"
        d="M11.5803 19.3605L11.785 19.5516L11.9897 19.3605L14.1824 17.3139C14.4684 17.047 14.9165 17.0625 15.1834 17.3485C15.4503 17.6344 15.4349 18.0826 15.1489 18.3495L12.2682 21.0381C11.9961 21.2921 11.5738 21.2921 11.3017 21.0381L10.1494 19.9627C9.86348 19.6958 9.84802 19.2476 10.1149 18.9616C10.3818 18.6757 10.83 18.6602 11.1159 18.9271L11.5803 19.3605ZM14.3348 13.4967C13.3633 12.6688 11.9345 12.6688 10.963 13.4967C10.6428 13.7696 10.2447 13.9345 9.82532 13.9679C8.55298 14.0695 7.54263 15.0798 7.44109 16.3522C7.40763 16.7715 7.24272 17.1697 6.96984 17.4899C6.14195 18.4614 6.14195 19.8902 6.96984 20.8617C7.24272 21.1819 7.40763 21.58 7.44109 21.9994C7.54263 23.2717 8.55298 24.2821 9.82532 24.3836C10.2447 24.4171 10.6428 24.582 10.963 24.8549C11.9345 25.6828 13.3633 25.6828 14.3348 24.8549C14.655 24.582 15.0532 24.4171 15.4725 24.3836C16.7449 24.2821 17.7552 23.2717 17.8568 21.9994C17.8902 21.58 18.0551 21.1819 18.328 20.8617C19.1559 19.8902 19.1559 18.4614 18.328 17.4899C18.0551 17.1697 17.8902 16.7715 17.8568 16.3522C17.7552 15.0798 16.7449 14.0695 15.4725 13.9679C15.0532 13.9345 14.655 13.7696 14.3348 13.4967Z"
        fill="url(#paint0_linear_2760_79)"
        stroke="white"
        strokeWidth="0.6"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2760_79"
        x1="16.207"
        y1="13.1758"
        x2="7.76234"
        y2="24.6235"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1CA00" />
        <stop offset="1" stopColor="#9F8500" />
      </linearGradient>
    </defs>
  </svg>
)

export default DiplomaIcon
