import * as R from 'ramda'
import { useRef } from 'react'
import { useDraggable } from 'react-use-draggable-scroll'
import s from './styles.module.css'
import { NAV_LINKS } from '../../nav/constants'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import CmsImage from 'components/shared/cms-image'
import DiplomaIcon from './diploma-icon'
const imgComponents = [
  () => (
    <div className={s.certImage}>
      <img
        className={s.certImg}
        alt="NASM Certification logo"
        src="https://res.cloudinary.com/kickoff-personal-training/image/upload/f_auto,q_auto/v1/static/b29f4cd6524f064cc86404db7073628e_mkmjhh"
      />
    </div>
  ),
  () => (
    <div className={s.certImage}>
      <img
        className={s.certImg}
        alt="NSCA-CPT Certification logo"
        src="https://res.cloudinary.com/kickoff-personal-training/image/upload/f_auto,q_auto,w_240,h_240,c_crop,g_west/v1/static/483c774350b2c9fc6cd757de9085d9a5_lpywo4"
      />
    </div>
  ),
  () => (
    <div className={s.certImage}>
      <DiplomaIcon />
    </div>
  ),
  () => (
    <div className={s.certImage}>
      <img
        className={s.certImg}
        alt="ISSA Certification logo"
        src="https://res.cloudinary.com/kickoff-personal-training/image/upload/f_auto,q_auto/v1/static/3d35b1c132f26f84630450c73c1dc2e5_q8xmmd"
      />
    </div>
  ),
]

const TrainerCard = ({
  fields: { header, primaryImage, text, subHeader },
  index,
}) => {
  const ImgComponent = imgComponents[index]
  return (
    <div className={s.trainerCard}>
      <CmsImage className={s.trainerImage} fields={primaryImage.fields} />
      <ImgComponent />
      <div className={s.trainerName}>{header}</div>
      <div className={s.trainerCerts}>{subHeader}</div>
      <div className={s.trainerSpecialty}>{`Specialty: ${text}`}</div>
    </div>
  )
}

const TrainersExperiment = ({ fields }) => {
  const { header, text } = fields
  const targetAudience = useTargetAudience()
  const ref = useRef<HTMLDivElement>(null)
  const draggable = useDraggable(ref)
  const isGuestPass = targetAudience === Audience.GuestPass
  const isTYPGuestPass = targetAudience === Audience.ThankYouPageGuestPass

  const trainers = R.propOr([], 'primaryItems', fields)

  return (
    <section id={NAV_LINKS.trainers} className={s.container}>
      <div className={s.content}>
        <div className={s.header}>{header}</div>
        <div className={s.text}>{text}</div>
        <div className={s.trainers} ref={ref} {...draggable.events}>
          {trainers.map((trainer, idx) => (
            <TrainerCard
              key={`trainer-${idx}`}
              fields={trainer.fields}
              index={idx}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default TrainersExperiment
