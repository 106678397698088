import GoalForm from 'components/homepage/goal-form'

import s from './styles.module.css'

const GetStarted = ({ fields: { id } }) => {
  return (
    <section className={s.getStarted} id={id}>
      <div className={s.content}>
        <div className={s.main}>
          <header className={s.headers}>
            <h2 className={s.header}>Get started</h2>
          </header>
          <div className={s.form}>
            <GoalForm id={id} analyticProps={{ location: 'Bottom' }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default GetStarted
