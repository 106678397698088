import { library } from '@fortawesome/fontawesome-svg-core'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'

import { AnalyticsManager } from 'utilities'
import s from './styles.module.css'
import { useCoachProfileContext } from 'contexts/coach-profile-context'
import Chip from 'components/shared/chip'

library.add(faPen as any)

interface EditButtonProps {
  questionId: number
  isImageOverlay?: boolean
  label?: string
  className?: string
}

const EditButton = ({
  questionId,
  isImageOverlay = false,
  label = 'Edit',
  className,
}: EditButtonProps) => {
  const { editing, getSurveyUrl, isProfileEdit } = useCoachProfileContext()

  if (!editing) return null

  const handleClick = () => {
    AnalyticsManager.logEvent('Coach Profile Review Page - Edit', {
      'Question Id': questionId,
    })
  }

  return (
    <Chip
      active
      clickable
      label={label}
      value="active-chip"
      key={`chip-${questionId}`}
      icon={faPen}
      className={cx(s.chip, { [s.imageOverlay]: isImageOverlay }, className)}
      onClick={handleClick}
      to={
        getSurveyUrl({ questionId, isProfileEdit: isProfileEdit ? 1 : 0 }).href
      }
    />
  )
}

export default EditButton
