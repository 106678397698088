import { BaseTypography } from 'components/design-system/type-system'
import CmsImage from '../../../../shared/cms-image'
import s from './styles.module.css'

const CoachCard = ({ fields }) => {
  return (
    <div className={s.container}>
      <div className={s.card}>
        <CmsImage
          className={s.profileImage}
          fields={fields.primaryImage.fields}
        />
        <BaseTypography
          tag="h3"
          className={s.title}
          size="small">{`Coach ${fields.header}`}</BaseTypography>
        <BaseTypography tag="span" className={s.subtitle}>
          {fields.subHeader}
        </BaseTypography>
        <BaseTypography tag="span" className={s.tagline}>
          {`Specialty: ${fields.text}`}
        </BaseTypography>
      </div>
    </div>
  )
}

export default CoachCard
