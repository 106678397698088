import * as R from 'ramda'
import { Swiper, SwiperSlide } from 'components/shared/swiper'
import CTAButton from '../cta-button'
import s from './styles.module.css'
import Star from './star'
import CmsImage from '../../../shared/cms-image'
import { NAV_LINKS } from '../nav/constants'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import Markdown from 'markdown-to-jsx'
import { LandingPageExperimentAnalytics } from '../analytics'
import { useContext } from 'react'
import CmsPageContext from 'contexts/cms-page-context'

const ReviewCard = ({ fields }) => {
  return (
    <div className={s.reviewCard}>
      <div className={s.stars}>
        {R.addIndex(R.map)(
          (_, idx) => (
            <Star className={s.star} key={`star-${idx}`} />
          ),
          Array.from({ length: 5 })
        )}
      </div>
      <div className={s.text}>
        <Markdown>{fields.text}</Markdown>
      </div>
      <div className={s.reviewer}>
        <CmsImage
          className={s.headshot}
          fields={fields.primaryImage.fields}
          mobileFields={fields.primaryImage.fields}
          useNextImage
        />
        <div>
          <div className={s.reviewerName}>{fields.header}</div>
          <div className={s.reviewerSource}>{fields.subHeader}</div>
        </div>
      </div>
    </div>
  )
}

const ReviewsExperiment = ({ fields, landingPageOptions }) => {
  const { isCoachPage } = useContext(CmsPageContext)
  const targetAudience = useTargetAudience()
  const reviews = R.propOr([], 'primaryItems', fields)
  const isGuestPass = targetAudience === Audience.GuestPass
  const isTYPGuestPass = targetAudience === Audience.ThankYouPageGuestPass

  const handleSlideChange = () => {
    LandingPageExperimentAnalytics.onClick({ location: 'Real People - Next' })
  }

  return (
    <section id={NAV_LINKS.successStories} className={s.container}>
      <div className={s.titleContainer}>
        <div className={s.title}>{fields.header}</div>
        <div className={s.buttonContainer}>
          <CTAButton
            analyticProps={{ location: 'Real People' }}
            href={targetAudience === Audience.Coach ? '/apply' : ''}>
            {targetAudience === Audience.Coach
              ? 'Get Started'
              : isCoachPage
              ? 'Start Training'
              : isGuestPass || isTYPGuestPass
              ? 'Claim Guest Pass'
              : landingPageOptions?.overrideCTA || 'Get Matched'}
          </CTAButton>
        </div>
      </div>
      <div className={s.reviewsContainer}>
        <Swiper
          slidesPerView={'auto'}
          centeredSlides={false}
          spaceBetween={24}
          navigation
          onSlideChange={handleSlideChange}>
          {R.addIndex(R.map)(
            (review, idx) => (
              <SwiperSlide key={`review-${idx}`}>
                <ReviewCard fields={review.fields} />
              </SwiperSlide>
            ),
            reviews
          )}
        </Swiper>
      </div>
    </section>
  )
}

export default ReviewsExperiment
