export const FULL_HEIGHT_STYLES = (
  <style jsx global>{`
    html,
    body,
    #__next,
    #app {
      height: 100%;
      overflow: hidden;
    }

    #app {
      min-height: auto;
    }
  `}</style>
)

export const HIDE_CRISP_WIDGET = (
  <style jsx global>{`
    /* hide default widget */
    #crisp-chatbox > div > a {
      display: none !important;
    }
  `}</style>
)

export const GLOBAL_LAYOUT_STYLES = (
  <style jsx global>{`
    html {
      font-size: 16px;
    }

    body {
      overflow-x: hidden;
    }

    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
    }

    select,
    input,
    button {
      font: inherit;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }
    body {
      line-height: normal;
    }
    ol,
    ul {
      list-style: none;
    }
    blockquote,
    q {
      quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    [contenteditable]:focus {
      outline: 0px solid transparent;
    }
  `}</style>
)

export const PRISM_STYLES = (
  <style jsx global>
    {`
      code[class*='language-'],
      pre[class*='language-'] {
        color: #ccc;
        background: 0 0;
        font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        font-size: 1em;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;
        -moz-tab-size: 4;
        -o-tab-size: 4;
        tab-size: 4;
        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
      }
      pre[class*='language-'] {
        padding: 1em;
        margin: 0.5em 0;
        overflow: auto;
      }
      :not(pre) > code[class*='language-'],
      pre[class*='language-'] {
        background: #2d2d2d;
      }
      :not(pre) > code[class*='language-'] {
        padding: 0.1em;
        border-radius: 0.3em;
        white-space: normal;
      }
      .token.block-comment,
      .token.cdata,
      .token.comment,
      .token.doctype,
      .token.prolog {
        color: #999;
      }
      .token.punctuation {
        color: #ccc;
      }
      .token.attr-name,
      .token.deleted,
      .token.namespace,
      .token.tag {
        color: #e2777a;
      }
      .token.function-name {
        color: #6196cc;
      }
      .token.boolean,
      .token.function,
      .token.number {
        color: #f08d49;
      }
      .token.class-name,
      .token.constant,
      .token.property,
      .token.symbol {
        color: #f8c555;
      }
      .token.atrule,
      .token.builtin,
      .token.important,
      .token.keyword,
      .token.selector {
        color: #cc99cd;
      }
      .token.attr-value,
      .token.char,
      .token.regex,
      .token.string,
      .token.variable {
        color: #7ec699;
      }
      .token.entity,
      .token.operator,
      .token.url {
        color: #67cdcc;
      }
      .token.bold,
      .token.important {
        font-weight: 700;
      }
      .token.italic {
        font-style: italic;
      }
      .token.entity {
        cursor: help;
      }
      .token.inserted {
        color: green;
      }
    `}
  </style>
)
