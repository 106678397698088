import * as R from 'ramda'
import { useState, useEffect } from 'react'

import { FaqAnalytics } from '../analytics'
import s from './styles.module.css'

const Faq = ({ faq: { question, answer }, clientId, coachId }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(R.not)

  useEffect(() => {
    if (isOpen) {
      FaqAnalytics.onToggleQuestion({ question, clientId, coachId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <div className={s.container}>
      <button className={s.question} onClick={toggleOpen}>
        {question}
      </button>
      {isOpen && <div className={s.answer}>{answer}</div>}
    </div>
  )
}

export default Faq
