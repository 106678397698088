import s from './styles.module.css'
import useCurrentUser from 'hooks/use-current-user'
import { Button } from '../../design-system'
import { useMutation } from '@apollo/client'

import { markCoachAgreementAgreedAt as markCoachAgreementAgreedAtMutation } from './mutations.graphql'
import Router, { useRouter } from 'next/router'
import urls from 'utilities/urls'
import { ArrowLeftIcon } from '../../design-system/kickoff-icons'

export default function CoachAgreementCheck() {
  const currentUser = useCurrentUser().data?.me?.user
  const { query } = useRouter()
  const coach = currentUser?.coach
  const hasAgreedToCoachAgreement = coach?.hasAgreedToCoachAgreement
  const [markCoachAgreementAgreedAt] = useMutation(
    markCoachAgreementAgreedAtMutation
  )

  if (query?.fromApplication === '1') return

  const handleAccept = async () => {
    const level = await markCoachAgreementAgreedAt({
      variables: {
        coachId: coach?.id,
        date: new Date(),
      },
    })

    if (level?.data?.updateCoachOnboardingProgress?.levelId) {
      await Router.push(urls.todosDashboard({ clientId: null }))
    }
  }

  if (!coach?.id || hasAgreedToCoachAgreement) {
    return (
      <div className={s.backContainer}>
        <Button
          className={s.backButton}
          onClick={() => Router.back()}
          variant="text"
          size="medium"
          iconLeft={<ArrowLeftIcon color="black" />}>
          Back
        </Button>
      </div>
    )
  }

  return (
    <div className={s.container}>
      <span>Read this term and press:</span>
      <Button onClick={handleAccept}>I accept</Button>
    </div>
  )
}
