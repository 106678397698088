import { useQuery } from '@apollo/client'
import * as R from 'ramda'
import { useEffect, useMemo } from 'react'

import { buildPayload } from 'utilities/apollo'
import CurrentUserContext from 'contexts/current-user-context'
import { AnalyticsManager } from 'utilities'
import { getCurrentUser } from './queries.graphql'
import bugsnagClient from '../../clients/bugsnag'
import { useExperimentKit } from 'utilities/experiment/context'

const CurrentUserProvider = ({ shouldIdentifyUser = true, children }) => {
  const currentUserResponse = useQuery(getCurrentUser, {
    fetchPolicy: 'cache-and-network',
  })
  const currentUserPayload = buildPayload(currentUserResponse)

  const isAdmin = R.pathOr(
    false,
    ['data', 'me', 'user', 'coach', 'isAdmin'],
    currentUserPayload
  )

  const contextValue = useMemo(
    () => ({ ...currentUserPayload, isAdmin }),
    [currentUserPayload, isAdmin]
  )

  // Set Bugsnag user data (while converting 'username' to 'name')
  const user = currentUserResponse?.data?.me?.user
  useEffect(() => {
    const keysMap = { username: 'name' }
    bugsnagClient.user = R.unless(
      R.isNil,
      R.pipe(
        R.pick(['id', 'username', 'email']),
        R.toPairs,
        R.transpose,
        R.converge(R.zipObj, [
          R.pipe(
            R.head,
            R.map(key => keysMap[key] || key)
          ),
          R.last,
        ])
      ),
      user
    )
    if (user) {
      bugsnagClient.setUser(user.id, user.email, user.fullName)
    }
  }, [user])

  useEffect(() => {
    if (shouldIdentifyUser) {
      AnalyticsManager.identifyUser({
        me: currentUserResponse.data?.me,
      })
    }
  }, [currentUserResponse.data, shouldIdentifyUser])

  // useEffect(() => {
  //   if (typeof cookieStore === 'undefined') return

  //   // reload page if signed out from another tab.
  //   // 500ms delay in case sign out occured in this tab.
  //   // only works on Chrome for now
  //   const onCookiesChanged = change => {
  //     if (
  //       change.deleted.length &&
  //       R.any(R.propEq('name', SESSION_TOKEN), change.deleted)
  //     ) {
  //       setTimeout(() => router.reload(), 500)
  //     }
  //   }

  //   window.cookieStore.addEventListener('change', onCookiesChanged)

  //   return () => {
  //     window.cookieStore.removeEventListener('change', onCookiesChanged)
  //   }
  // }, [])

  const ek = useExperimentKit()
  ek.setUser(user)

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export default CurrentUserProvider
