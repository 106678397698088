import cx from 'classnames'
import s from './styles.module.css'

const Section = ({ id, children, wrap = 'wrap', className }) => {
  return (
    <section id={id} className={s.container}>
      <div
        className={cx(
          s.row,
          wrap === 'wrapReverse' ? s.wrapReverse : s.wrap,
          className
        )}>
        {children}
      </div>
    </section>
  )
}

export default Section
