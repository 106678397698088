import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { getClientTargetAudience, Audience } from 'utilities/target-audience'
import { getFirstStringFromOptionalArray } from 'utilities/strings'

const COACH_LANDING_PAGES = ['coachwithkickoff']
const COACH_FREE_TRIAL_PATHNAME = '/coaches/[id]/free'

export { Audience }

const useTargetAudience = (): Audience => {
  const { query, pathname } = useRouter()
  const { slug: pageSlug } = query || {}

  return useMemo(() => {
    if (
      COACH_LANDING_PAGES.includes(getFirstStringFromOptionalArray(pageSlug))
    ) {
      return Audience.Coach
    }

    return getClientTargetAudience({
      ref: getFirstStringFromOptionalArray(query?.ref),
      isCoachFreeTrial:
        pathname === COACH_FREE_TRIAL_PATHNAME ||
        query?.ref === 'gp_freetrial_coach',
    })
  }, [pageSlug, pathname, query?.ref])
}

export default useTargetAudience
