import * as R from 'ramda'

import { capitalize as capitalizeStr } from 'utilities/strings'

export const fullName = R.unless(
  R.isNil,
  R.pipe(
    ({ firstName, lastName }) => [firstName, lastName],
    R.reject(val => !val),
    R.join(' ')
  )
)

export const getHeShe = ({ gender, capitalize = false }) => {
  let value = 'they'
  if (gender && R.toUpper(gender) === 'M') {
    value = 'he'
  } else if (gender && R.toUpper(gender) === 'F') {
    value = 'she'
  }

  return capitalize ? capitalizeStr(value) : value
}

export const getHisHer = ({ gender, capitalize = false }) => {
  let value = 'their'
  if (gender && R.toUpper(gender) === 'M') {
    value = 'his'
  } else if (gender && R.toUpper(gender) === 'F') {
    value = 'her'
  }

  return capitalize ? capitalizeStr(value) : value
}

export const getHimHer = ({ gender, capitalize = false }) => {
  let value = 'them'
  if (gender && R.toUpper(gender) === 'M') {
    value = 'him'
  } else if (gender && R.toUpper(gender) === 'F') {
    value = 'her'
  }

  return capitalize ? capitalizeStr(value) : value
}

export const getHimselfHerself = ({ gender, capitalize = false }) => {
  let value = 'themself'
  if (gender && R.toUpper(gender) === 'M') {
    value = 'himself'
  } else if (gender && R.toUpper(gender) === 'F') {
    value = 'herself'
  }

  return capitalize ? capitalizeStr(value) : value
}
