import { COACH_BODY_IMAGE_QUESTION_ID } from 'constants/cms-survey'
import CoachMatchPhoto from '../coach-photo'
import CoachSurveyResponses, {
  COACH_MATCH_PROMOTION_QUESTION_IDS,
} from '../coach-survey-responses'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

const CoachHealthyFood = ({ coach }: CoachProps) => {
  return (
    <div className={s.container}>
      <div className={s.leftCol}>
        <CoachSurveyResponses
          coach={coach}
          responseIds={[
            COACH_MATCH_PROMOTION_QUESTION_IDS.MY_GO_TO_WORKOUT,
            COACH_MATCH_PROMOTION_QUESTION_IDS.HEALTHY_FOOD_I_LOVE,
            COACH_MATCH_PROMOTION_QUESTION_IDS.HEALTHY_FOOD_I_HATE,
          ]}
        />
      </div>
      <div className={s.rightCol}>
        {coach.bodyImage?.url && (
          <CoachMatchPhoto
            url={coach.bodyImage?.url}
            alt="BodyImage"
            questionId={COACH_BODY_IMAGE_QUESTION_ID}
          />
        )}
      </div>
    </div>
  )
}

export default CoachHealthyFood
