import { AnalyticsManager } from 'utilities'

/*
 ** Analytics class
 */
export class CoachProfileAnalytics {
  static onViewCoach({ clientId, coachId, location }) {
    if (!location) return

    AnalyticsManager.logEvent(location, {
      'Client ID': clientId,
      'Coach ID': coachId,
    })
  }

  static onSelectCoach({ clientId, coachId }) {
    // Amplitude
    AnalyticsManager.logEvent('Coach Landing Page - Select Coach', {
      'Client ID': clientId,
      'Coach ID': coachId,
    })

    // Google
    // @ts-ignore
    window.dataLayer.push({
      event: 'google_event',
      event_action: 'Selected',
      event_category: 'Coach',
      event_label: coachId,
    })
  }
}
