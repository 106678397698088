import cx from 'classnames'
import { COACH_PROFILE_IMAGE_QUESTION_ID } from 'constants/cms-survey'
import s from './styles.module.css'
import TrainButton from '../train-button'

import { CoachProps } from 'components/coach-profile/coach-profile-hero'
import CoachPhoto from '../coach-photo'
import CoachName from '../coach-name'
import CoachCertifications from '../coach-certifications'
import CoachSpecialties from '../coach-specialties'

interface CoachHeaderProps extends CoachProps {
  tags?: string[]
  bioCard?: boolean
}
const CoachHeader = ({ coach, bioCard = false }: CoachHeaderProps) => {
  return (
    <div className={cx(bioCard ? s.bio : '', s.container)}>
      <div className={cx(bioCard ? s.bio : '', s.leftCol)}>
        <CoachPhoto
          className={s.image}
          url={coach.profileImage?.url}
          alt="ProfileImage"
          questionId={COACH_PROFILE_IMAGE_QUESTION_ID}
        />
      </div>
      <div className={cx(bioCard ? s.bio : '', s.rightCol)}>
        <div className={cx(bioCard ? s.center : '')}>
          <CoachName coach={coach} bioCard={bioCard} />
          {bioCard && <div className={s.border}></div>}
        </div>

        <CoachCertifications coach={coach} bioCard={bioCard} />
        <div className={s.buttonWrapperMobile}>
          <TrainButton
            buttonClassName={s.trainButton}
            name="Header CTA"
            coach={coach}
          />
        </div>
        {!bioCard && (
          <>
            <CoachSpecialties coach={coach} bioCard={bioCard} />
          </>
        )}

        <div className={s.buttonWrapperDesktop}>
          <TrainButton
            buttonClassName={s.trainButton}
            name="Header CTA"
            coach={coach}
          />
        </div>

        {bioCard && (
          <>
            <CoachSpecialties coach={coach} bioCard={bioCard} />
            <CoachCertifications coach={coach} bioCard={bioCard} />
          </>
        )}
      </div>
    </div>
  )
}

export default CoachHeader
