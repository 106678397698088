import * as R from 'ramda'
import { useState } from 'react'

import { Swiper, SwiperSlide } from 'components/shared/swiper'
import rightArrow from 'images/long-right-arrow-grey.svg'
import leftArrow from 'images/long-left-arrow-grey.svg'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import CmsImage from 'components/shared/cms-image'
import CTALink from 'components/homepage/cta-link'
import { AnalyticsSection } from 'containers/analytics'
import { TrackedButton } from 'components/shared/analytics'
import s from './styles.module.css'

/**
 * Utility component used to isolate the logic
 * of displaying the appropriate media component
 * (i.e. image or video)
 */
const CmsMedia = ({ media, placement }) => {
  if (!media) return null

  const { contentType } = media?.fields?.file
  if (contentType?.includes('video')) {
    return (
      <CmsImage
        className={s[`${placement}ImageWrapper`]}
        imageClassName={s.image}
        videoClassName={s.video}
        videoFields={media.fields}
        videoProps={{
          autoPlay: false,
          muted: false,
          controls: true,
          playsInline: false,
        }}
      />
    )
  }
  return (
    <CmsImage
      className={s[`${placement}ImageWrapper`]}
      imageClassName={s.image}
      fields={media.fields}
    />
  )
}

const Testimonials = ({ fields }) => {
  const [testimonialSwiper, setTestimonialSwiper] = useState(null)
  const [testimonialSwiper2, setTestimonialSwiper2] = useState(null)
  const targetAudience = useTargetAudience()

  const { header, primaryItems: testimonials } = fields

  return (
    <AnalyticsSection name="Testimonials">
      <section id="testimonials" className={s.testimonialsSection}>
        <div className={s.testimonialsSectionContent}>
          <div className={s.details}>
            <div>
              <h2 className={s.header}>{header}</h2>
              <Swiper
                pagination={{ clickable: true }}
                navigation={{
                  prevEl: '.prev',
                  nextEl: '.next',
                }}
                onSwiper={setTestimonialSwiper}
                controller={{ control: testimonialSwiper2 }}>
                {R.addIndex(R.map)(({ fields }, idx) => {
                  const { header: name, subHeader: label, text } = fields

                  return (
                    <SwiperSlide key={`image-quote-${idx}`}>
                      <div key={idx} className={s.testimonial}>
                        <div className={s.testimonialText}>"{text}"</div>
                        <div className={s.testimonialName}>{name}</div>
                        <div className={s.testimonialLabel}>{label}</div>
                        <div className={s.testimonialTextMobile}>"{text}"</div>
                      </div>
                    </SwiperSlide>
                  )
                }, testimonials)}
                <TrackedButton className={'prev'} name="Previous">
                  <img
                    src={leftArrow}
                    alt="Arrow left"
                    className={s.arrowIcon}
                  />
                </TrackedButton>
                <TrackedButton className={'next'} name="Next">
                  <img
                    src={rightArrow}
                    alt="Arrow right"
                    className={s.arrowIcon}
                  />
                </TrackedButton>
              </Swiper>
            </div>
          </div>
          <div className={s.images}>
            <Swiper
              onSwiper={setTestimonialSwiper2}
              controller={{ control: testimonialSwiper }}
              pagination={{ clickable: true }}>
              {R.addIndex(R.map)(({ fields }, idx) => {
                const {
                  primaryImage: afterImage,
                  secondaryImage: beforeImage,
                } = fields

                return (
                  <SwiperSlide key={`image-swiper-${idx}`}>
                    <div className={s.testimonialImages}>
                      {afterImage && (
                        <CmsMedia media={afterImage} placement="after" />
                      )}
                      {beforeImage && (
                        <CmsMedia media={beforeImage} placement="before" />
                      )}
                    </div>
                  </SwiperSlide>
                )
              }, testimonials)}
            </Swiper>
          </div>
          <h2 className={s.mobileHeader}>{header}</h2>
        </div>
        <CTALink
          analyticProps={{ location: 'Testimonials' }}
          className={s.getStartedWrapper}
          buttonText="Start training"
          promptText={targetAudience === Audience.Coach ? '' : "It's your turn"}
          promptTextClassName={
            targetAudience === Audience.Coach ? '' : s.getStartedPrompt
          }
          targetAudience={targetAudience}
          name="CTA"
        />
      </section>
    </AnalyticsSection>
  )
}

export default Testimonials
