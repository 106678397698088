export default () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.1665 5.7C3.38147 6.79347 4.06265 7.40653 5.27762 8.5L11.4998 1.5"
      stroke="url(#paint0_linear_2462_485)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2462_485"
        x1="6.83317"
        y1="1.5"
        x2="6.83317"
        y2="8.5"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#04D800" />
        <stop offset="1" stopColor="#04BE00" />
      </linearGradient>
    </defs>
  </svg>
)
