import * as R from 'ramda'
import useTargetAudience from 'hooks/use-target-audience'
import CTALink from '../cta-link'

import s from './styles.module.css'

const COMPONENTS = { CTALink }

const CTA = ({ fields, fields: { header, subHeader, primaryItems } }) => {
  const targetAudience = useTargetAudience()
  return (
    <div className={s.container}>
      <h2 className={s.title}>
        {header}
        <span className={s.bold}>{subHeader}</span>
      </h2>
      <div className={s.primaryItems}>
        {R.addIndex(R.map)(({ fields }, idx) => {
          const mappedComponentName =
            fields.component === 'GetStartedLink' ? 'CTALink' : fields.component
          const Component = COMPONENTS[mappedComponentName]
          return (
            <Component
              analyticProps={{ location: 'CTA' }}
              key={idx}
              buttonText={fields.header}
              targetAudience={targetAudience}
            />
          )
        }, primaryItems)}
      </div>
    </div>
  )
}

export default CTA
