import { useContext } from 'react'

import { ChevronRightIcon } from 'components/design-system/kickoff-icons'
import Text from 'components/shared/text'
import { Flex, Hidden } from 'components/design-system'

import CmsPageContext from 'contexts/cms-page-context'

import s from './styles.module.css'
import Link from 'next/link'

export const Breadcrumb = ({ isMobile }) => {
  const { locationDetails } = useContext(CmsPageContext)

  const { city, state } = locationDetails

  const countryAbbreviation =
    city?.state?.country?.abbreviation || state?.country?.abbreviation
  const countrySlug = city?.state?.country?.slug || state?.country?.slug
  const stateSlug = city?.state?.slug || state?.slug

  return (
    <header className={s.root}>
      <nav className={s.content}>
        <Flex alignItems="center">
          Home
          <ChevronRightIcon className={s.chevron} />
          <Link
            href={`/local/${countrySlug}/personal-trainers`}
            className={s.link}>
            {countryAbbreviation}
          </Link>
          <ChevronRightIcon className={s.chevron} />
          {city?.name ? (
            <Link
              href={`/local/${countrySlug}/${stateSlug}/personal-trainers`}
              className={s.link}>
              {city.state.name}
            </Link>
          ) : (
            <Text className={s.cityColor}>{state.name}</Text>
          )}
          <Hidden visible={city?.name}>
            <ChevronRightIcon className={s.chevron} />
            <Text className={s.cityColor}>{city?.name}</Text>
          </Hidden>
        </Flex>
      </nav>
    </header>
  )
}
