import { useContext } from 'react'
import cx from 'classnames'
import CmsPageContext from 'contexts/cms-page-context'
import s from './styles.module.css'
import * as R from 'ramda'
import { generateCMSDynamicLocalText } from '../helpers'

export const OtherStateTrainersGrid = ({ fields }) => {
  const { locationDetails } = useContext(CmsPageContext)
  const { stateCoaches } = locationDetails

  const { header, subHeader } = fields

  const title = generateCMSDynamicLocalText(header, locationDetails)
  const subTitle = generateCMSDynamicLocalText(subHeader, locationDetails)

  return (
    <section className={cx(s.container)}>
      <div className={s.content}>
        <div className={s.title}>{title}</div>
        <div className={s.subTitle}>{subTitle}</div>
        <div className={s.grid}>
          {R.map(({ firstName, lastName, username }) => {
            return (
              <a
                key={username}
                href={`/coaches/${username}`}
                className={s.gridItemLink}>
                <div className={s.gridItem}>
                  {firstName} {lastName}
                </div>
              </a>
            )
          }, stateCoaches)}
        </div>
      </div>
    </section>
  )
}
