import { useContext } from 'react'
import cx from 'classnames'

import CmsPageContext from 'contexts/cms-page-context'
import useWindowSize from 'hooks/use-window-size'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const Hero = ({
  fields,
  overridePrimaryImageFields,
  className,
  overlayClassName,
  mainClassName,
  windowHeightPercentage = 0.95,
  minHeight = 600,
}) => {
  const { isCoachPage, signUp } = useContext(CmsPageContext)
  const { height: windowHeight } = useWindowSize({ ignoreHeightChange: true })
  const height =
    windowHeight && Math.max(windowHeight * windowHeightPercentage, minHeight)
  const { header, subHeader, primaryImage, mobileImage, video } = fields

  const onSubmit = async () => {
    try {
      signUp()
    } catch (serverError) {}
  }

  const handleClick = ev => {
    ev.preventDefault()

    if (isCoachPage) {
      document
        .querySelector(ev.currentTarget.getAttribute('href'))
        .scrollIntoView({ behavior: 'smooth' })
    } else {
      onSubmit()
    }
  }

  return (
    <section
      className={cx(s.heroSection, className)}
      style={{ height: height ? `${height}px` : undefined }}>
      <CmsImage
        className={s.heroImage}
        fields={overridePrimaryImageFields || primaryImage?.fields}
        videoFields={video?.fields}
        mobileFields={mobileImage?.fields}
        fm="jpg"
        fl="progressive"
        isProgressive={true}
      />
      <div className={cx(s.main, mainClassName)} onClick={handleClick}>
        <header className={s.headerWrapper}>
          <h1 className={s.header}>{header}</h1>
          <h2 className={s.subHeader}>{subHeader}</h2>
        </header>
      </div>
    </section>
  )
}

export default Hero
