import { Fragment, useState } from 'react'
import * as R from 'ramda'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import s from './styles.module.css'
import FaqPageJsonLd from './jsonld'

library.add(faChevronDown)

const Faq = ({ fields }) => {
  const [activeSections, setActiveSections] = useState([])

  const handleClick = key => {
    const opened = activeSections.includes(key)

    if (opened) {
      const updated = activeSections.filter(k => key !== k)
      return setActiveSections(updated)
    }

    return setActiveSections([...activeSections, key])
  }

  const groupings = R.reduce(
    (acc, item) => {
      const { grouping, question, answer } = item.fields
      if (!acc[grouping]) {
        acc[grouping] = []
      }
      acc[grouping].push({ question, answer })
      return acc
    },
    {},
    fields.primaryItems
  )

  const all = R.map(item => {
    const { question, answer } = item.fields
    return { question, answer: answer.replace(/\n+/g, ' ') }
  }, fields.primaryItems)

  return (
    <>
      <div className={s.faqPage}>
        <FaqPageJsonLd mainEntity={all} />
      </div>
      <div className={s.container}>
        <div className={s.leftColumn}>
          <h1>Frequently asked questions</h1>
          <p>
            Can't find the answer you're looking for? Reach out to our{' '}
            <a href="mailto:hello@trainwithkickoff.com">customer support</a>{' '}
            team.
          </p>
        </div>
        <div className={s.rightColumn}>
          {R.map(
            key => (
              <Fragment key={key}>
                <label className={s.grouping}>{key}</label>
                <div className={s.sections}>
                  {groupings[key].map((section, index) => (
                    <div
                      key={section.question}
                      className={cx(s.section, {
                        [s.sectionActive]: activeSections.includes(
                          `${key}${index}`
                        ),
                      })}
                      onClick={() => handleClick(`${key}${index}`)}>
                      <label className={s.label}>
                        {section.question}
                        <FontAwesomeIcon
                          icon={['fal', 'chevron-down']}
                          className={s.icon}
                        />
                      </label>
                      <div className={s.content}>{section.answer}</div>
                    </div>
                  ))}
                </div>
              </Fragment>
            ),
            R.keys(groupings)
          )}
        </div>
      </div>
    </>
  )
}

export default Faq
