import { useContext } from 'react'
import cx from 'classnames'
import CmsPageContext from 'contexts/cms-page-context'
import s from './styles.module.css'
import { generateCMSDynamicLocalText } from '../helpers'

export const IntroCopy = ({ fields }) => {
  const { locationDetails } = useContext(CmsPageContext)

  const { header, subHeader } = fields

  const title = generateCMSDynamicLocalText(header, locationDetails)
  const subTitle = generateCMSDynamicLocalText(subHeader, locationDetails)

  return (
    <section className={cx(s.container)}>
      <div className={s.content}>
        <div className={s.title}>{title}</div>
        <div className={s.subTitle}>{subTitle}</div>
      </div>
    </section>
  )
}
