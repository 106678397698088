import * as R from 'ramda'
import cx from 'classnames'
import checkIcon from 'images/circle-check-purple.png'
import s from './styles.module.css'

const ProductDetails = ({
  details: propDetails,
  checkIcon: customCheckIcon,
  verbose = false,
  detailItemStyle,
  textStyle,
  boldTextStyle,
  getContent,
}) => {
  // if verbose, expand detail items
  const allDetails = R.pipe(
    R.map(detailItem => {
      if (!verbose) return detailItem

      return detailItem.verbose || detailItem
    }),
    R.flatten
  )(R.filter(p => !p.empty, propDetails))

  return (
    <ul>
      {R.addIndex(R.map)((detailItem, idx) => {
        return (
          <li key={idx}>
            {getContent && getContent(idx)}
            <div className={cx(s.detailItem, detailItemStyle)}>
              {!customCheckIcon && (
                <img src={checkIcon} alt="Check" className={s.checkIcon} />
              )}
              {customCheckIcon}
              <div
                className={cx(
                  {
                    [s.bold]: detailItem.bold,
                    [boldTextStyle]: detailItem.bold,
                  },
                  textStyle
                )}>
                {detailItem.text}
              </div>
            </div>
          </li>
        )
      }, allDetails)}
    </ul>
  )
}

export default ProductDetails
