import { useMutation } from '@apollo/client'
import * as R from 'ramda'
import Router from 'next/router'
import { setCookie } from 'nookies'
import { useContext, useState } from 'react'
import cx from 'classnames'

import useInterval from 'hooks/use-interval'
import Spinner from 'components/shared/spinner'
import { LandingPageAnalytics } from '../analytics'
import { DAY } from 'constants/seconds'

import CmsPageContext from 'contexts/cms-page-context'
import urls from 'utilities/urls'
import { getAdjustedUtcOffset } from 'utilities/dates'
import Select from 'components/shared/select'
import { SESSION_TOKEN } from 'constants/cookies'
import { FITNESS_GOALS_QUESTION_ID_V3 } from 'constants/cms-survey'
import { submitClientSignUpSurvey as submitClientSignUpSurveyMutation } from './mutations.graphql'
import { useLeadsContext } from 'contexts/leads-context'
import s from './styles.module.css'

const DEFAULT_PLACEHOLDER = 'Select your goal...'

const GoalForm = ({
  id,
  bordered = true,
  buttonText = 'Find your coach',
  analyticProps = { location: '' },
}) => {
  const [placeholderIdx, setPlaceholderIdx] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [responseId, setResponseId] = useState(null)

  const { signUpSurveyQuestions, surveyId, query, getRef, couponCode, coach } =
    useContext(CmsPageContext)
  const [submitClientSignUpSurvey] = useMutation(
    submitClientSignUpSurveyMutation
  )
  const { getLeadInfo, setLeadId } = useLeadsContext()

  const fitnessGoalsQuestion = R.find(
    R.propEq('id', FITNESS_GOALS_QUESTION_ID_V3),
    signUpSurveyQuestions
  )
  const { responses } = fitnessGoalsQuestion
  const responseOptions = R.map(
    ({ id: value, shortResponseText: label }) => ({ value, label }),
    responses
  )

  useInterval(() => {
    if (R.isNil(placeholderIdx)) return setPlaceholderIdx(0)

    setPlaceholderIdx((placeholderIdx + 1) % responseOptions.length)
  }, 4500)

  const onSubmit = async ev => {
    ev.preventDefault()

    if (submitting) return

    setSubmitting(true)

    const { href, as } = urls.clientSignUp({
      questionId: 219,
      refCoachId: coach.id,
    })
    Router.prefetch(href)

    const responseParams = responseId
      ? [
          {
            surveyId,
            surveyQuestionId: FITNESS_GOALS_QUESTION_ID_V3,
            surveyQuestionResponseIds: [responseId],
          },
        ]
      : []

    const fitnessGoals = R.pipe(
      R.find(R.propEq('id', FITNESS_GOALS_QUESTION_ID_V3)),
      R.propOr([], 'responses'),
      R.filter(({ id }) => parseInt(id) === parseInt(responseId)),
      R.map(R.prop('text'))
    )(signUpSurveyQuestions)

    const utcOffset = getAdjustedUtcOffset()

    try {
      const {
        data: {
          submitClientSignUpSurvey: { sessionToken, leadId },
        },
      } = await submitClientSignUpSurvey({
        variables: {
          ref: getRef(),
          gclid: query.gclid,
          msclkid: query.msclkid,
          fbclid: query.fbclid,
          utcOffset,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          fitnessGoals,
          responses: responseParams,
          couponCode,
          ...getLeadInfo(),
        },
      })

      setLeadId(leadId)

      LandingPageAnalytics.onSignUp({
        location: analyticProps.location,
      })

      await setCookie({}, SESSION_TOKEN, sessionToken, {
        path: '/',
        maxAge: 30 * DAY,
      })

      Router.push(href, as)
    } catch (serverError) {
      setSubmitting(false)
      setError(`Something went wrong. We're fixing it now`)
    }
  }

  return (
    <form className={s.form} onSubmit={onSubmit}>
      <label className={s.goals}>
        <Select
          options={responseOptions}
          value={responseId}
          onChange={ev => setResponseId(ev.target.value)}
          isV2={true}
          className={s.selectWrapper}
          inputClassName={cx(s.select, { [s.selectBordered]: bordered })}
          includeBlank={true}
          placeholder={
            R.isNil(placeholderIdx)
              ? DEFAULT_PLACEHOLDER
              : responseOptions[placeholderIdx].label
          }
          placeholderClassName={s.selectPlaceholder}
          crossfadePlaceholder={true}
        />
        <button
          type="submit"
          className={cx(s.submit, { [s.submitDisabled]: submitting })}>
          <span className={cx({ [s.invisible]: submitting })}>buttonText</span>
          {submitting && (
            <div className={s.spinnerWrapper}>
              <Spinner className={s.spinner} />
            </div>
          )}
        </button>
      </label>
      {error && <div className={s.error}>{error}</div>}
    </form>
  )
}

export default GoalForm
