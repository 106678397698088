import * as R from 'ramda'
import cx from 'classnames'
import {
  COACH_CLIENT_GOALS_QUESTION_ID,
  COACH_SPECIALTIES_QUESTION_ID,
} from 'constants/cms-survey'
import { useRouter } from 'next/router'
import EditButton from '../../edit-button'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

interface CoachSpecialtiesProps extends CoachProps {
  bioCard?: boolean
}

const CoachSpecialties = ({
  coach,
  bioCard = false,
}: CoachSpecialtiesProps) => {
  const coachTags =
    coach.tags && coach.tags.length ? coach.tags : coach.tagsByVariant
  const { route } = useRouter()
  const isCoachPage = R.includes('/coaches', route) && !bioCard

  return (
    <div className={s.container}>
      <h6 className={s.header}>Specialties</h6>
      <div className={s.editSpecialtiesRow}>
        <EditButton
          questionId={COACH_SPECIALTIES_QUESTION_ID}
          label={'Edit Skill Specialties'}
          className={s.noButtonMargin}
        />
        <EditButton
          questionId={COACH_CLIENT_GOALS_QUESTION_ID}
          label={'Edit Goal Specialties'}
          className={s.noButtonMargin}
        />
      </div>
      {/* todo add icons */}
      <ul className={s.specialties}>
        {R.addIndex(R.map)(
          ({ name, tag }, index) => (
            <li key={`specialaties-b-${index}`} className={s.specialty}>
              <div className={cx(bioCard ? s.bio : '', s.specialtyContent)}>
                <div className={s.specialtyImageBackground} />
                <label className={s.specialtyName}>{name || tag}</label>
              </div>
            </li>
          ),
          isCoachPage
            ? coach.specialties
            : R.take(4, coachTags || coach.specialties)
        )}
      </ul>
    </div>
  )
}

export default CoachSpecialties
