import { AnalyticsManager } from 'utilities'

export class FaqAnalytics {
  static onToggleQuestion({ clientId, coachId, question }) {
    AnalyticsManager.logEvent(`FAQ - Toggle Question`, {
      'Client ID': clientId,
      'Coach ID': coachId,
      Question: question,
    })
  }
}
