import * as R from 'ramda'
import { useState } from 'react'

import Select from 'components/shared/select'
import s from './styles.module.css'

const buildOptions = R.map(value => ({ value, label: value }))

const FEET_OPTIONS = buildOptions(R.range(0, 8))
const INCH_OPTIONS = buildOptions(R.range(0, 12))

const getFeet = totalInches => (totalInches ? Math.floor(totalInches / 12) : '')
const getInches = totalInches => {
  return totalInches ? totalInches % 12 : ''
}

const HeightPicker = ({ onChange, inches: totalInches, label = 'Height' }) => {
  const [feet, setFeet] = useState(getFeet(totalInches))
  const [inches, setInches] = useState(getInches(totalInches))

  const onFeetChange = ev => {
    const newFeet = parseInt(ev.target.value) || 0
    setFeet(newFeet)
    onChange && onChange(newFeet * 12 + (inches || 0))
  }

  const onInchesChange = ev => {
    const newInches = parseInt(ev.target.value) || 0
    setInches(newInches)
    onChange && onChange(feet * 12 + newInches)
  }

  return (
    <div className={s.container}>
      <label className={s.label}>{label}</label>
      <div className={s.inputs}>
        <Select
          options={FEET_OPTIONS}
          value={feet}
          isV2={true}
          className={s.select}
          placeholder="ft"
          onChange={onFeetChange}
          includeBlank={true}
        />
        <Select
          options={INCH_OPTIONS}
          value={inches}
          isV2={true}
          className={s.select}
          placeholder="in"
          onChange={onInchesChange}
          includeBlank={true}
        />
      </div>
    </div>
  )
}

export default HeightPicker
