import Markdown from 'markdown-to-jsx'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const WhatIsKickoff = ({ fields: { header, text, primaryImage } }) => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <h2 className={s.header}>{header}</h2>
        {primaryImage && (
          <div className={s.imageContainer}>
            <CmsImage
              className={s.image}
              fields={primaryImage?.fields}
              w={960}
            />
          </div>
        )}
        <div className={s.description}>
          <Markdown
            options={{
              overrides: {
                ul: { props: { className: s.list } },
                li: { props: { className: s.bullet } },
              },
            }}>
            {text}
          </Markdown>
        </div>
      </div>
    </section>
  )
}

export default WhatIsKickoff
