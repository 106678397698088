import cx from 'classnames'

import CmsImage from 'components/shared/cms-image'
import ss from '../styles.module.css'
import s from './styles.module.css'

const Match = ({ idx, fields }) => {
  const { header, text } = fields

  return (
    <div className={s.section}>
      <div className={cx(ss.sectionMain, s.sectionMain)}>
        <header className={ss.sectionHeaderWrapper}>
          <div className={ss.sectionHeaderIdx}>{idx + 1}</div>
          <h4 className={ss.sectionHeader}>{header}</h4>
        </header>
        <div className={ss.sectionText}>{text}</div>
      </div>
      <div className={s.images}>
        <CmsImage
          className={s.perfectCoachImage}
          fields={fields?.primaryImage?.fields}
          w={600}
        />
      </div>
    </div>
  )
}

export default Match
