import cx from 'classnames'
import { useContext } from 'react'
import Image from 'next/legacy/image'
import CmsPageContext from 'contexts/cms-page-context'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import s from './styles.module.css'

const DEFAULT_FM = 'png'
const DEFAULT_FL = 'png8'

const CmsImage = ({
  className,
  imageClassName,
  videoClassName,
  thumbnailMobileClassName,
  videoProps,
  fields,
  videoFields,
  mobileFields,
  isProgressive = false,
  fm = DEFAULT_FM,
  fl = DEFAULT_FL,
  w = '',
  h = '',
  useNextImage = false,
  priority = false,
  layout = 'fill',
  objectFit = 'contain',
  loading,
}) => {
  const { isSafari } = useContext(CmsPageContext)
  const enableVideo = useWindowSizeCondition(({ width }) => width > 992, false)

  if (!fields && !videoFields) return null

  const { title, file } = fields || videoFields

  if (!isSafari && fm == DEFAULT_FM && fl == DEFAULT_FL) {
    fm = 'webp'
    fl = ''
    if (w === '') {
      w = 350
    }
  }

  if (!file) return null

  const { url } = file
  const hasMobile = !!(mobileFields && mobileFields.file)

  if (useNextImage) {
    const imageFields = mobileFields || fields
    if (videoFields && enableVideo) {
      return (
        <div className={cx(s.container, className)}>
          <video
            className={cx(s.video, videoClassName)}
            src={`https:${videoFields.file.url}?w=${w}&h=${h}`}
            loop
            autoPlay={true}
            playsInline={true}
            muted={true}
            {...videoProps}
          />
        </div>
      )
    }
    return (
      <div className={cx(s.container, className)}>
        <Image
          alt={imageFields.title}
          src={`https:${imageFields.file.url}?fm=${fm}`}
          layout={layout}
          objectFit={layout === 'responsive' ? 'initial' : objectFit}
          width={imageFields.file.details.image.width}
          height={imageFields.file.details.image.height}
          priority={priority}
          loading={loading}
        />
      </div>
    )
  }

  return (
    <>
      <div
        className={cx(
          s.container,
          { [s.containerDesktop]: hasMobile },
          className
        )}>
        {!!fields && (
          <>
            {isProgressive && (
              <img
                alt={title}
                className={s.thumbnail}
                src={`https:${url}?fm=${fm}&fl=${fl}&w=200&h=100`}
                loading="lazy"
              />
            )}
            <img
              alt={title}
              className={cx(s.image, imageClassName, {
                [s.imageProgressive]: isProgressive,
              })}
              src={`https:${url}?fm=${fm}&fl=${fl}&w=${w}&h=${h}`}
              loading="lazy"
            />
          </>
        )}
        {videoFields && (
          <video
            className={cx(s.video, videoClassName)}
            src={`https:${videoFields.file.url}?w=${w}&h=${h}`}
            loop
            autoPlay={true}
            playsInline={true}
            muted={true}
            {...videoProps}
          />
        )}
      </div>
      {hasMobile && (
        <div className={cx(s.container, s.containerMobile, className)}>
          {isProgressive && (
            <img
              alt={mobileFields.title}
              className={cx(s.thumbnail, thumbnailMobileClassName)}
              src={`https:${mobileFields.file.url}?fm=${fm}&fl=${fl}&w=200&h=100`}
              loading="lazy"
            />
          )}
          <img
            alt={mobileFields.title}
            className={cx(s.image, imageClassName, {
              [s.imageProgressive]: isProgressive,
            })}
            src={`https:${mobileFields.file.url}?fm=${fm}&fl=${fl}&w=1200&h=1200`}
            loading="lazy"
          />
        </div>
      )}
    </>
  )
}

export default CmsImage
