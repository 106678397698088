import { useQuery, useMutation } from '@apollo/client'
import * as R from 'ramda'
import { useState, useMemo, useContext } from 'react'

import CmsSurveyContext from 'contexts/cms-survey-context'
import MultiSelectInput from 'components/shared/multi-select-input'
import LoadingScreen from 'components/shared/loading-screen'
import { getExcludedIngredients, getIngredients } from './queries.graphql'
import { updateClientIngredientExclusions as updateClientIngredientExclusionsMutation } from './mutations.graphql'

const ExcludedIngredientSelect = () => {
  const [searchText, setSearchText] = useState('')
  const [excludedIngredients, setExcludedIngredients] = useState(null)
  const [hasFocused, setHasFocused] = useState(false)
  const excludeIngredientIds = useMemo(() => {
    return R.map(R.prop('value'), excludedIngredients || [])
  }, [excludedIngredients])

  const { clientId } = useContext(CmsSurveyContext)
  useQuery(getExcludedIngredients, {
    variables: { clientId },
    onCompleted: data => {
      setExcludedIngredients(
        R.pipe(
          R.map(({ id, name }) => ({ value: id, label: name })),
          R.sortBy(R.prop('label'))
        )(data.client.excludedIngredients)
      )
    },
  })

  const ingredientPayload = useQuery(getIngredients, {
    variables: { searchText, excludeIngredientIds },
    skip: !hasFocused || !searchText,
  })
  const ingredientOptions = useMemo(() => {
    const excludedIngredientIds = new Set(
      R.map(R.prop('value'), excludedIngredients || [])
    )

    return R.pipe(
      R.pathOr([], ['ingredientCollection', 'ingredients']),
      R.reject(({ id }) => excludedIngredientIds.has(id)),
      R.map(({ id, name }) => ({ value: id, label: name }))
    )(ingredientPayload.data)
  }, [ingredientPayload.data, excludedIngredients])

  const [updateClientIngredientExclusions] = useMutation(
    updateClientIngredientExclusionsMutation
  )

  const update = async newExcludedIngredients => {
    setExcludedIngredients(newExcludedIngredients)
    await updateClientIngredientExclusions({
      variables: {
        clientId,
        ingredientIds: R.map(R.prop('value'), newExcludedIngredients),
      },
    })
  }

  const onSelect = (value, label) => {
    update(R.append({ value, label }, excludedIngredients))
  }

  const onRemove = value => {
    update(R.reject(R.propEq('value', value), excludedIngredients))
  }

  if (R.isNil(excludedIngredients)) return <LoadingScreen />

  return (
    <MultiSelectInput
      options={ingredientOptions}
      values={excludedIngredients}
      optionsLoading={ingredientPayload.loading}
      onChange={setSearchText}
      onFocus={() => setHasFocused(true)}
      onSelect={onSelect}
      onRemove={onRemove}
      placeholder="Search for ingredients to exclude"
      allowCustom={false}
    />
  )
}

export default ExcludedIngredientSelect
