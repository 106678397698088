import { useContext } from 'react'
import cx from 'classnames'
import CmsPageContext from 'contexts/cms-page-context'
import s from './styles.module.css'
import * as R from 'ramda'
import CTAButton from 'components/homepage/experiment/cta-button'
import { generateCMSDynamicLocalText } from '../helpers'

const mapIndexed = R.addIndex(R.map)

export const IndexedReasonList = ({ fields }) => {
  const { locationDetails } = useContext(CmsPageContext)

  const { header, subHeader, secondaryItems } = fields

  const title = generateCMSDynamicLocalText(header, locationDetails)
  const subTitle = generateCMSDynamicLocalText(subHeader, locationDetails)

  return (
    <section className={cx(s.container)}>
      <div className={s.content}>
        <div className={s.leftContainer}>
          <h2 className={s.title}>{title}</h2>
          <p className={s.subTitle}>{subTitle}</p>
          <div className={s.ctaContainer}>
            <CTAButton
              className={s.cta}
              analyticProps={{ location: 'Reason List' }}
              href={'/apply'}>
              Start Training Today
            </CTAButton>
          </div>
        </div>
        <div className={s.rightContainer}>
          {mapIndexed(({ fields: { header, subHeader } }, idx) => {
            const index = idx + 1

            return (
              <div className={s.sublineColumns} key={index}>
                <div className={s.indexContainer}>
                  <div className={s.circle}>{index}</div>
                </div>
                <div className={s.listItem}>
                  <h3 className={s.listItemTitle}>{header}</h3>
                  <p className={s.listItemText}>{subHeader}</p>
                </div>
              </div>
            )
          }, secondaryItems)}
        </div>
      </div>
    </section>
  )
}
