import React, { useContext } from 'react'
import cx from 'classnames'
import CmsPageContext from 'contexts/cms-page-context'
import s, { WHITE } from './styles.module.css'
import * as R from 'ramda'
import CTAButton from '../../homepage/experiment/cta-button'
import withProps from 'recompose/withProps'
import {
  faCommentDots,
  faHandshake,
  faRunning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Markdown from 'markdown-to-jsx'
import Image from 'next/legacy/image'
import { generateCMSDynamicLocalText } from '../helpers'

export const CityHero = ({
  fields: { header, subHeader, secondaryItems, primaryImage },
}) => {
  const { locationDetails } = useContext(CmsPageContext)

  const title = generateCMSDynamicLocalText(header, locationDetails)
  const subTitle = generateCMSDynamicLocalText(subHeader, locationDetails)

  const CommentDots = withProps({
    icon: faCommentDots,
    style: { color: WHITE, width: '2rem', height: '2rem' },
  })(FontAwesomeIcon)

  const Handshake = withProps({
    icon: faHandshake,
    style: { color: WHITE, width: '2rem', height: '2rem' },
  })(FontAwesomeIcon)

  const Run = withProps({
    icon: faRunning,
    style: { color: WHITE, width: '2rem', height: '2rem' },
  })(FontAwesomeIcon)

  return (
    <section
      className={cx(s.container, { [s.containerNoImage]: !primaryImage })}>
      <div className={s.backgroundImage}>
        {primaryImage ? (
          <Image
            objectFit="cover"
            layout="fill"
            priority
            src={`https:${primaryImage.fields.file.url}`}
          />
        ) : null}
      </div>
      <div className={s.overlay} />
      <div className={s.content}>
        <div className={s.titleContainer}>
          <h1 className={s.title}>{title}</h1>
          <p className={s.subTitle}>{subTitle}</p>
          <div className={s.ctaContainer}>
            <CTAButton
              className={s.cta}
              analyticProps={{ location: 'Hero' }}
              href={'/apply'}>
              Start Training Today
            </CTAButton>
          </div>
        </div>
        <div className={s.secondLine}>
          {R.map(({ fields: { header, subHeader } }) => {
            let result = header.replace(/{dots}/gi, '<CommentDots />')
            result = result.replace(/{shake}/gi, '<Handshake />')
            result = result.replace(/{run}/gi, '<Run />')

            return (
              <div className={s.lineColumn} key={header}>
                <h3 className={s.secondLineHeader}>
                  <Markdown
                    children={result}
                    options={{
                      overrides: {
                        CommentDots: {
                          component: CommentDots,
                        },
                        Handshake: {
                          component: Handshake,
                        },
                        Run: {
                          component: Run,
                        },
                      },
                    }}
                  />
                </h3>
                <p className={s.secondLineSubHeader}>{subHeader}</p>
              </div>
            )
          }, secondaryItems)}
        </div>
      </div>
    </section>
  )
}
