import { useContext } from 'react'
import cn from 'classnames'
import NextImage from 'next/image'
import kickoffLogoStandard from 'images/kickoff-logo-standard.svg'
import kickoffLogoLight from 'images/kickoff-logo-standard-white.svg'

import CmsPageContext from 'contexts/cms-page-context'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import s from './styles.module.css'
import { NEW_MOBILE_MAX } from 'constants/breakpoints'
import { HIDE_CRISP_WIDGET } from 'constants/styles'

const Checkmark = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="18" height="18" rx="9" fill="#00D656" />
    <path
      d="M5.66675 10C6.53458 10.781 7.02114 11.219 7.88897 12L12.3334 7"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export function HeroMedia({ imageFields, videoFields, isFullScreen }) {
  const { isSafari } = useContext(CmsPageContext)
  const fm = isSafari ? 'jpg' : 'webp'

  const enableVideo = useWindowSizeCondition(({ width }) => width > 992, false)
  const isMobile = useWindowSizeCondition(
    ({ width }) => width < NEW_MOBILE_MAX,
    true
  )
  let nextImageProps = {
    alt: imageFields.title,
    src: `https:${imageFields.file.url}?fm=${fm}`,
    priority: true,
  }

  if (isFullScreen) {
    nextImageProps = {
      ...nextImageProps,
      layout: 'fill',
      objectFit: 'cover',
      objectPosition: 'top',
    }
  } else {
    nextImageProps = {
      ...nextImageProps,
      width: imageFields.file.details.image.width,
      height: imageFields.file.details.image.height,
      layout: 'responsive',
      objectFit: 'initial',
    }
  }

  return (
    <div
      className={cn(s.container, {
        [s.fullScreen]: isFullScreen,
      })}>
      {isFullScreen && (
        <img
          className={s.logo}
          src={isMobile ? kickoffLogoLight : kickoffLogoStandard}
          alt="Kickoff"
        />
      )}
      <NextImage {...nextImageProps} />
      {enableVideo && videoFields && (
        <video
          className={s.video}
          src={`https:${videoFields.file.url}`}
          loop
          autoPlay={true}
          playsInline={true}
          muted={true}
        />
      )}
      {!isFullScreen && <div className={s.overlay} />}
      {isFullScreen && (
        <>
          <div className={s.valueProps}>
            <div className={s.valueProp}>
              <Checkmark />
              Daily Accountability
            </div>
            <div className={s.valueProp}>
              <Checkmark />
              Personalized Plans
            </div>
            <div className={s.valueProp}>
              <Checkmark />
              Affordable: $3/Day
            </div>
          </div>
          {HIDE_CRISP_WIDGET}
        </>
      )}
    </div>
  )
}
