import { useQuery, useMutation } from '@apollo/client'
import * as R from 'ramda'
import { useState, useContext } from 'react'

import CmsSurveyContext from 'contexts/cms-survey-context'
import LoadingScreen from 'components/shared/loading-screen'
import { MEALS } from 'constants/meals'
import NestedMultiSelect from '../nested-multi-select'
import { getClientMeals } from './queries.graphql'
import { updateClientMeals as updateClientMealsMutation } from './mutations.graphql'

const MEAL_OPTIONS = R.map(
  ({ id, name }) => ({ value: id.toString(), label: name }),
  MEALS
)

const MealSelect = () => {
  const [mealIds, setMealIds] = useState(R.map(R.prop('value'), MEAL_OPTIONS))

  const { clientId } = useContext(CmsSurveyContext)
  useQuery(getClientMeals, {
    variables: { clientId },
    onCompleted: data => {
      if (data.client.meals.length) {
        setMealIds(R.map(R.prop('id'), data.client.meals))
      } else {
        setMealIds(R.map(R.prop('value'), MEAL_OPTIONS))
      }
    },
  })

  const [updateClientMeals] = useMutation(updateClientMealsMutation)

  const onChange = async newMealIds => {
    setMealIds(newMealIds)
    await updateClientMeals({ variables: { clientId, mealIds: newMealIds } })
  }

  if (R.isNil(mealIds)) return <LoadingScreen />

  return (
    <NestedMultiSelect
      options={MEAL_OPTIONS}
      initialSelectedOptions={mealIds}
      onChange={onChange}
    />
  )
}

export default MealSelect
