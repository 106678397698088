import { useState } from 'react'
import VennDiagram from './venn-diagram'
import s from './styles.module.css'

const Accountability = ({ fields }) => {
  const [topic, setTopic] = useState(null)
  const { header, text } = fields

  return (
    <section className={s.accountabilitySection}>
      <div className={s.diagram}>
        <VennDiagram setTopic={setTopic} topic={topic} />
      </div>
      <div className={s.textWrapper}>
        <h2 className={s.header}>{header}</h2>
        {topic && <h4 className={s.subHeader}>{topic.value}</h4>}
        <div className={s.text}>{topic ? topic.text : text}</div>
      </div>
    </section>
  )
}

export default Accountability
