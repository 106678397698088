import * as R from 'ramda'
import { useContext, useMemo } from 'react'

import useProducts from 'hooks/use-products'
import GetStartedLink from 'components/homepage/get-started-link'
import check from 'images/orange-check.svg'
import CmsImage from 'components/shared/cms-image'
import CmsPageContext from 'contexts/cms-page-context'
import { getDailyPrice } from 'utilities/stripe'
import VideoPricing from './video-pricing'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import s from './styles.module.css'

const SimplePricing = ({
  coach,
  coupon,
  fields,
  trialDays,
  dailyPriceInDollars,
}) => {
  const { header, subHeader: cmsSubHeader, primaryImage, primaryItems } = fields

  const productsPayload = useProducts({
    excludeMultiMonth: true,
    excludeLiveVideo: true,
  })

  const price = productsPayload.data?.products[0].prices[0]

  const subHeader = useMemo(() => {
    if (coach) {
      if (!price) return ''
      if (trialDays === 30) return 'A free month with a certified trainer'
      if (trialDays === 7) return 'A free week with a certified trainer'
      if (trialDays) return `${trialDays} free days with a certified trainer`

      const [costPerDay] = getDailyPrice({ price, coupon })

      return `A certified trainer for just $${costPerDay}/day`
    }

    return cmsSubHeader
  }, [cmsSubHeader, coach, coupon, trialDays, price])

  const points = useMemo(() => {
    if (trialDays || (coach && coach.priceVersion)) {
      if (!price) return []

      return [
        {
          fields: {
            header:
              trialDays === 30
                ? `First month free, then $${price.unitAmount / 100}/month`
                : `$${price.unitAmount / 100}/month`,
          },
        },
        { fields: { header: 'No contracts or hidden fees' } },
      ]
    }

    return primaryItems
  }, [coach, primaryItems, price, trialDays])

  return (
    <>
      <div className={s.main}>
        <div>
          <h2 className={s.header}>
            {header.replace(/{price}/g, dailyPriceInDollars || 3)}
          </h2>
          {/* <h4 className={s.subHeader}>{subHeader}</h4> */}
          <ul className={s.points}>
            {R.addIndex(R.map)(({ fields }, idx) => {
              const { header, subHeader } = fields

              return (
                <li key={idx} className={s.point}>
                  <img src={check} alt="Check" className={s.checkIcon} />
                  <div>
                    {header && (
                      <span className={s.pointTextPrimary}>{header} </span>
                    )}
                    {subHeader && (
                      <span className={s.pointTextSecondary}>{subHeader} </span>
                    )}
                  </div>
                </li>
              )
            }, points)}
          </ul>
        </div>
        <GetStartedLink text={'Get started'} />
      </div>
      <CmsImage
        fields={primaryImage.fields}
        className={s.imageWrapper}
        imageClassName={s.image}
      />
    </>
  )
}

const Pricing = ({ fields, dailyPriceInDollars }) => {
  const { coach, coupon } = useContext(CmsPageContext)
  const trialDays = useClientTrialDays()
  const isOfferingLiveVideoWorkouts = useMemo(() => {
    if (trialDays) return false
    if (!coach) return true

    return coach.isOfferingLiveVideoWorkouts
  }, [coach, trialDays])

  return (
    <section id="pricing" className={s.pricingSection}>
      {isOfferingLiveVideoWorkouts && <VideoPricing />}
      {!isOfferingLiveVideoWorkouts && (
        <SimplePricing
          fields={fields}
          coach={coach}
          coupon={coupon}
          trialDays={trialDays}
          dailyPriceInDollars={dailyPriceInDollars}
        />
      )}
    </section>
  )
}

export default Pricing
