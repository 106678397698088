import { RadioGroup } from 'components/design-system/inputs'
import s from './styles.module.css'

const SingleSelect = ({ options, value, onChange }) => {
  return (
    <RadioGroup
      options={options}
      value={value}
      onChange={onChange}
      optionClassName={s.option}
      optionLabelClassName={s.label}
      optionSubTextClassName={s.subText}
      optionCheckedClassName={s.optionChecked}
      optionIconClassName={s.icon}
    />
  )
}

export default SingleSelect
