import { useState } from 'react'
import * as R from 'ramda'

import { Swiper, SwiperSlide } from 'components/shared/swiper'
import CmsImage from 'components/shared/cms-image'
import quotationIcon from 'images/quotation.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import s from './styles.module.css'

const TestimonialsB = ({ fields: { primaryItems } }) => {
  const [swiperControl, setSwiperControl] = useState(null)
  const [swiperControl2, setSwiperControl2] = useState(null)

  return (
    <div className={s.container}>
      <Swiper
        onSwiper={setSwiperControl}
        controller={{ control: swiperControl2 }}
        pagination={{ clickable: true }}
        navigation={{
          prevEl: '.prev',
        }}
        spaceBetween={0}>
        {R.addIndex(R.map)(({ fields }, idx) => {
          return (
            <SwiperSlide key={`image-swiper-${idx}`}>
              <div className={s.imageContainer}>
                <CmsImage
                  className={s.afterImageWrapper}
                  imageClassName={s.image}
                  fields={fields.primaryImage.fields}
                />
              </div>
            </SwiperSlide>
          )
        }, primaryItems)}
        <div className="prev">
          <FontAwesomeIcon icon={faArrowLeft} size="3x" />
        </div>
      </Swiper>
      <Swiper
        onSwiper={setSwiperControl2}
        navigation={{
          nextEl: '.next',
        }}
        controller={{ control: swiperControl }}>
        {R.addIndex(R.map)(({ fields }, idx) => {
          return (
            <SwiperSlide key={`image-quote-${idx}`}>
              <div className={s.testimonial}>
                <img className={s.quoteIcon} src={quotationIcon} alt="Quote" />
                <div>{fields.text}</div>
                <div className={s.name}>{fields.header}</div>
                <div>{fields.subHeader}</div>
              </div>
            </SwiperSlide>
          )
        }, primaryItems)}
        <div className="next">
          <FontAwesomeIcon icon={faArrowRight} size="3x" />
        </div>
      </Swiper>
    </div>
  )
}

export default TestimonialsB
