const RedXIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M12 2.00004L2 12M1.99996 2L11.9999 12"
      stroke="url(#paint0_linear_2398_10679)"
      strokeWidth="2.8"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2398_10679"
        x1="12"
        y1="7"
        x2="2"
        y2="7"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF6969" />
        <stop offset="1" stopColor="#D80000" />
      </linearGradient>
    </defs>
  </svg>
)

export default RedXIcon
