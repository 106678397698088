import shadeHexColor from './shade-hex-color'

const Star = ({ className, color = 'currentColor', percentFilled = 100 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}>
      {Array.from({ length: 11 }).map((_, i) => {
        const offset = i * 10
        return (
          <defs>
            <linearGradient
              id={`progress${offset}`}
              x1="0"
              y1="0"
              x2="1"
              y2="0">
              <stop id="stop1" offset={`${offset}%`} stopColor="currentColor" />
              <stop
                id="stop2"
                offset={`${offset}%`}
                stopColor={
                  color !== 'currentColor'
                    ? shadeHexColor(color, 0.7)
                    : 'transparent'
                }
              />
            </linearGradient>
          </defs>
        )
      })}

      <polygon
        fill={`url(#progress${percentFilled})`}
        points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
    </svg>
  )
}

export default Star
