import cx from 'classnames'
import bikeEmoji from 'images/how-it-works/bike-emoji.png'

import CmsImage from 'components/shared/cms-image'
import ss from '../styles.module.css'
import s from './styles.module.css'

const KickOff = ({ idx, fields }) => {
  const { header, subHeader, text } = fields

  return (
    <div className={cx(ss.section, s.kickOff, s.alignCenter)}>
      <div className={cx(ss.sectionMain, s.sectionMain)}>
        <header className={ss.sectionHeaderWrapper}>
          <div className={ss.sectionHeaderIdx}>{idx + 1}</div>
          <h4 className={ss.sectionHeader}>{header}</h4>
        </header>
        <div className={ss.sectionText}>{text}</div>
        <div className={ss.detailWrapper}>
          <div className={s.detail}>
            <img className={s.bikeEmoji} src={bikeEmoji} alt="Bike" />
            <h6 className={s.detailText}>{subHeader}</h6>
          </div>
        </div>
      </div>
      <div className={s.images}>
        <CmsImage
          className={s.image}
          fields={fields?.primaryImage?.fields}
          w={600}
        />
      </div>
    </div>
  )
}

export default KickOff
