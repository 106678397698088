import { DAY } from 'constants/seconds'
import { useRouter } from 'next/router'
import { parseCookies, setCookie } from 'nookies'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as dd from 'react-device-detect'
import { SERVER_URL } from 'utilities/urls'
import { getFirstStringFromOptionalArray } from 'utilities/strings'

// import { useMutation } from 'react-apollo'
// import { setLeadExitPage } from './mutations.graphql'

const BASE_URL = SERVER_URL

const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', '')
    )

const LeadsContext = createContext(null)

export function useLeadsContext() {
  const context = useContext(LeadsContext)
  if (context === null) {
    throw new Error('useLeadsContext must be used within a LeadsProvider')
  }
  return context
}

export function LeadsProvider(props: {
  referrer: string
  value: any
  children: ReactNode
}) {
  const router = useRouter()
  const utms = useMemo(() => {
    return Object.keys(router.query)
      .filter(k => k.includes('utm'))
      .reduce((acc, k) => {
        const key = snakeToCamel(k)
        const value = router.query[k]
        // if duplicate values are provided, use the first one
        acc[key] = Array.isArray(value) ? value[0] : value
        return acc
      }, {})
  }, [router.query])
  const referrerRef = useRef(props.referrer || '')
  const [leadId, setLeadId] = useState()
  // const [setLeadExitPageMutation] = useMutation(setLeadExitPage)

  useEffect(() => {
    const { lead } = parseCookies()
    if (!lead) {
      setCookie(
        null,
        'lead',
        JSON.stringify({
          ...utms,
          ref: getFirstStringFromOptionalArray(router.query.ref),
          landingPageUrl: `${BASE_URL}${router.asPath}`,
          deviceType: dd.deviceType,
          referrer: referrerRef.current,
          setLeadId,
        }),
        {
          path: '/',
          maxAge: DAY,
        }
      )
    } else {
      // utms don't show up in router.query until second useEffect on some pages
      const parsedLead = JSON.parse(lead)
      const missingUtms = Object.keys(utms).filter(
        utmKey => !parsedLead[utmKey]
      )
      if (missingUtms.length) {
        setCookie(
          null,
          'lead',
          JSON.stringify({
            ...utms,
            ref: router.query.ref,
            ...parsedLead,
          }),
          {
            path: '/',
            maxAge: DAY,
          }
        )
      }
    }
  }, [router.asPath, router.query.ref, utms])

  useEffect(() => {
    if (document) {
      referrerRef.current = document.referrer
    }
  }, [])

  // useEffect(() => {
  //   const handleBrowseAway = async () => {
  //     destroyCookie(null, 'utms')

  //     if (leadId) {
  //       await setLeadExitPageMutation({
  //         variables: { id: leadId, exitPageUrl: document.location.href },
  //       })
  //     }
  //   }
  //   window?.addEventListener('beforeunload', handleBrowseAway)

  //   return () => {
  //     window?.removeEventListener('beforeunload', handleBrowseAway)
  //   }
  // }, [leadId, router.events, setLeadExitPageMutation])

  const getLeadInfo = () => {
    const { lead } = parseCookies()
    if (lead) {
      const parsedLead = JSON.parse(lead)
      const cleanedLead = {}

      for (const key of Object.keys(parsedLead)) {
        const value = parsedLead[key]

        // if duplicate values were provided and cached in the cookie,
        // use the first one
        if (key.includes('utm') && Array.isArray(value)) {
          cleanedLead[key] = value[0]
        } else {
          cleanedLead[key] = value
        }
      }

      return cleanedLead
    }
  }

  return (
    <LeadsContext.Provider value={{ getLeadInfo, setLeadId }}>
      {props.children}
    </LeadsContext.Provider>
  )
}
