import { createContext, useContext } from 'react'

interface ICoachProfileContext {
  editing: boolean
  isProfileEdit: boolean
  getSurveyUrl: (p?: any) => any
  onCtaClick: () => void
}

export const CoachProfileContext = createContext<ICoachProfileContext>({
  editing: false,
  isProfileEdit: false,
  getSurveyUrl: () => {},
  onCtaClick: () => {},
})

export const useCoachProfileContext = () => useContext(CoachProfileContext)
