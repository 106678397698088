import * as R from 'ramda'
import cx from 'classnames'
import { useState } from 'react'

import s from './styles.module.css'
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from 'components/design-system/kickoff-icons'
import { generateCMSDynamicLocalText } from 'components/city-page/helpers'
import { useAnalytics } from 'hooks/use-analytics'

const Faq = ({ faq: { question, answer }, locationDetails }) => {
  const [isOpen, setIsOpen] = useState(false)

  const questionTitle = generateCMSDynamicLocalText(question, locationDetails)
  const questionAnswer = generateCMSDynamicLocalText(answer, locationDetails)

  const logQuestionClick = useAnalytics('Question Click', {
    location: questionTitle,
  })

  const toggleOpen = () => {
    setIsOpen(R.not)
    logQuestionClick()
  }

  return (
    <>
      <button
        className={cx(s.question, isOpen ? s.open : '')}
        onClick={toggleOpen}>
        <div className={s.questionRow}>
          <span>{questionTitle}</span>
          <div>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
        </div>
        {isOpen && <div className={s.answer}>{questionAnswer}</div>}
      </button>
    </>
  )
}

export default Faq
