export const TOPICS = {
  sleep: {
    value: 'Sleep',
    text: 'Sleep is essential to performing at your best and is required for fitness results. Lack of sleep slows your metabolism and inhibits muscle growth. Yet most people do not get enough. Your trainer keeps tabs on your sleep via reports in our app and helps you troubleshoot barriers. Clients are shocked by how much this overlooked strategy helps.',
  },
  nutrition: {
    value: 'Nutrition',
    text: 'Nutrition is the most important driver of fitness results, and the hardest to improve. Only through daily accountability and troubleshooting can you make the long term, reasonable behavior changes necessary to drive incredible results. Your trainer makes it happen while ensuring you love what you eat.',
  },
  exercise: {
    value: 'Exercise',
    text: 'Your coach builds your workouts each week and follows progress closely. He or she checks in daily via text to troubleshoot any barriers, answer questions, and fit workouts into the time you can make.',
  },
}
