import React, { useContext, useMemo, useState } from 'react'
import Image from 'next/image'
import cx from 'classnames'
import * as R from 'ramda'
import * as D from 'date-fns'

import LoadingScreen from 'components/shared/loading-screen'
import { Paper } from 'components/design-system'
import { AnalyticsSection } from 'containers/analytics'
import CmsPageContext from 'contexts/cms-page-context'
import Chip from 'components/shared/chip'
import s from './styles.module.css'
import { generateCMSDynamicLocalText } from '../helpers'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'components/design-system/kickoff-icons'
import CTAButton from 'components/homepage/experiment/cta-button'
import Link from 'next/link'

const CoachCard = ({
  coach: {
    id: coachId,
    profileImage,
    firstName,
    lastName,
    username,
    specialties,
    certifications,
  },
  city,
}) => {
  return (
    <AnalyticsSection name="Coach Card" properties={{ coachId }}>
      <Paper className={s.coachCardContainer}>
        <div className={s.coachCardInformationRow}>
          <div className={s.coachImage}>
            {profileImage && (
              <Image
                alt={`${firstName} image`}
                className={s.image}
                layout="fill"
                objectFit="cover"
                src={profileImage.url}
              />
            )}
          </div>
          <div className={s.coachDetails}>
            <Link href={`/coaches/${username}`} className={s.coachName}>
              {firstName} {lastName?.charAt(0).toUpperCase()}.
            </Link>
            <div className={s.coachDetailRow}>
              Location: In person ({city}) & Remote
            </div>
            <div className={s.coachDetailRow}>
              Certifications: {R.join(', ', certifications)}
            </div>
            <div className={s.cta}>
              <CTAButton
                className={s.coachButton}
                analyticProps={{ location: 'Coach Card' }}>
                Get Matched
              </CTAButton>
            </div>
          </div>
        </div>
        <div className={s.coachSubjectsRow}>
          {R.map(
            ({ name }) => (
              <Chip
                active
                clickable
                label={name}
                value="active-chip"
                key={`chip-${coachId}-${name}`}
              />
            ),
            specialties
          )}
        </div>
      </Paper>
    </AnalyticsSection>
  )
}

const CoachList = ({ coaches, city }) => {
  const itemsPerPage = 5
  const [currentPage, setCurrentPage] = useState(1)

  const paginatedCoaches = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage
    const end = start + itemsPerPage
    return coaches.slice(start, end)
  }, [coaches, currentPage])

  const totalPages = Math.ceil(coaches.length / itemsPerPage)
  const handlePageClick = page => {
    setCurrentPage(page)
  }

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <div>
      <div className={s.upperRow}>
        <div className={s.upperText}>Free consultation with any trainer</div>
        <div className={s.upperText}>
          Showing {paginatedCoaches.length} of {coaches.length}
        </div>
      </div>
      {R.map(
        coach => (
          <CoachCard
            coach={coach}
            city={coach.cityName || city}
            key={coach.id}
          />
        ),
        paginatedCoaches
      )}
      <div className={s.paginationRow}>
        <ChevronLeftIcon className={s.chevron} onClick={handlePrevClick} />
        {R.times(i => {
          const pageNumber = i + 1
          return (
            <button
              key={pageNumber}
              className={s.pageButton}
              onClick={() => handlePageClick(pageNumber)}
              disabled={pageNumber === currentPage}>
              {pageNumber}
            </button>
          )
        }, totalPages)}
        <ChevronRightIcon className={s.chevron} onClick={handleNextClick} />
      </div>
    </div>
  )
}

export const NearCoachesList = ({ fields }) => {
  const { locationDetails } = useContext(CmsPageContext)
  const { city, cityCoaches } = locationDetails
  const { name } = city

  const { header, subHeader } = fields

  const title = generateCMSDynamicLocalText(header, locationDetails)
  const subTitle = generateCMSDynamicLocalText(
    subHeader,
    locationDetails
  ).replace(/{date}/g, D.format(new Date(), 'MMMM do, YYYY'))

  if (R.isNil(cityCoaches) || R.isEmpty(cityCoaches)) {
    return <LoadingScreen />
  }

  return (
    <section className={cx(s.container)}>
      <div className={s.content}>
        <div className={s.headerContainer}>
          <div className={s.preHeader}>{subTitle}</div>
          <h2 className={s.header}>{title}</h2>
        </div>
        <div className={s.listContainer}>
          <div className={s.list}>
            <CoachList coaches={cityCoaches} city={name} />
          </div>
        </div>
      </div>
    </section>
  )
}
