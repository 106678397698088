const Facebook = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6759 0H1.32414C0.592837 0 0 0.592837 0 1.32414V22.6759C0 23.4072 0.592837 24 1.32414 24H12.8276V14.7186H9.70345V11.0855H12.8276V8.41241C12.8276 5.3131 14.7228 3.62483 17.4869 3.62483C18.4184 3.6228 19.3493 3.66976 20.2759 3.76552V7.00552H18.3724C16.8662 7.00552 16.5724 7.71724 16.5724 8.76828V11.0814H20.1724L19.7048 14.7145H16.5517V24H22.6759C23.4072 24 24 23.4072 24 22.6759V1.32414C24 0.592837 23.4072 0 22.6759 0Z"
        fill="url(#paint0_linear_859:46623)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_859:46623"
          x1="-2.4"
          y1="30.96"
          x2="31.1873"
          y2="25.1032"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9429A" />
          <stop offset="1" stopColor="#FF9171" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Facebook
