import { fullName } from 'utilities/user'
import { COACH_FIRST_NAME_QUESTION_ID } from 'constants/cms-survey'
import EditButton from '../../edit-button'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

interface CoachNameProps extends CoachProps {
  bioCard?: boolean
}
const CoachName = ({ coach, bioCard }: CoachNameProps) => {
  const name = fullName(coach.user)
  return (
    <h1 className={s.name}>
      {bioCard ? `Coach ${name}` : name}
      <EditButton questionId={COACH_FIRST_NAME_QUESTION_ID} />
    </h1>
  )
}

export default CoachName
