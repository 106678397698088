import cx from 'classnames'

import detailArrow from 'images/how-it-works/detail-arrow-short.png'
import loveEmoji from 'images/how-it-works/love-emoji.png'
import CmsImage from 'components/shared/cms-image'

import ss from '../styles.module.css'
import s from './styles.module.css'

const WorkOut = ({ idx, fields }) => {
  const { header, subHeader, text } = fields
  return (
    <div className={cx(ss.section, s.workOut)}>
      <div className={ss.sectionMain}>
        <header className={ss.sectionHeaderWrapper}>
          <div className={ss.sectionHeaderIdx}>{idx + 1}</div>
          <h4 className={ss.sectionHeader}>{header}</h4>
        </header>
        <div className={ss.sectionText}>{text}</div>
        <div className={ss.detailWrapper}>
          <div className={s.detail}>
            <img className={s.loveEmoji} src={loveEmoji} alt="Love" />
            <h6 className={s.detailText}>{subHeader}</h6>
            <img className={s.detailArrow} src={detailArrow} alt="Arrow" />
          </div>
        </div>
      </div>
      <div className={s.images}>
        <CmsImage
          className={s.textsImage}
          fields={fields?.primaryImage?.fields}
          w={600}
        />
      </div>
    </div>
  )
}

export default WorkOut
