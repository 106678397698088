import * as R from 'ramda'
import CmsImage from 'components/shared/cms-image'

import s from './styles.module.css'

const HowItWorksB = ({ fields: { header, primaryItems } }) => {
  return (
    <div className={s.container}>
      <div className={s.content}>
        <h2 className={s.header}>{header}</h2>
        <div className={s.items}>
          {R.addIndex(R.map)(({ fields }, idx) => {
            if (!fields.primaryImage) return null
            return (
              <div key={`how-it-works-${idx}`} className={s.item}>
                <CmsImage
                  className={s.imageWrapper}
                  imageClassName={s.image}
                  fields={fields.primaryImage.fields}
                />
                <h3 className={s.subHeader}>{fields.header}</h3>
                <p className={s.description}>{fields.subHeader}</p>
              </div>
            )
          }, primaryItems)}
        </div>
      </div>
    </div>
  )
}

export default HowItWorksB
