import * as R from 'ramda'

import CmsImage from 'components/shared/cms-image'

import s from './styles.module.css'

const ValueProp = ({ fields: { primaryItems } }) => {
  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.details}>
          {R.map(
            ({ sys: { id }, fields: { header, subHeader, primaryImage } }) => {
              return (
                <div key={id} className={s.detail}>
                  <CmsImage
                    fields={primaryImage.fields}
                    className={s.detailEmoji}
                    imageClassName={s.image}
                    isProgressive={false}
                    w={800}
                  />
                  <h4 className={s.detailText}>{header}</h4>
                  <h6 className={s.detailSubText}>{subHeader}</h6>
                </div>
              )
            },
            primaryItems
          )}
        </div>
      </div>
    </div>
  )
}

export default ValueProp
