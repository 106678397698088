import * as R from 'ramda'
import cn from 'classnames'
import Star from './star'
import s from './styles.module.css'

interface StarRatingProps {
  rating: number
  color: string
  showRating?: boolean
  className: string
}

const StarRating = ({
  rating,
  color = 'currentColor',
  showRating = false,
  className,
}: StarRatingProps) => {
  const number = Math.floor(rating)
  const remainder = parseFloat(Number(rating - number).toFixed(1))

  return (
    <div className={cn(s.stars, className)}>
      {R.addIndex(R.map)((_, idx: number) => {
        const percentFilled =
          (idx < number ? 1 : idx > number ? 0 : remainder) * 100
        return (
          <Star
            className={s.star}
            key={`star-rating-${idx}`}
            color={color}
            percentFilled={percentFilled}
          />
        )
      }, Array.from({ length: 5 }))}
      {showRating && <div className={s.rating}>{rating} / 5</div>}
    </div>
  )
}

export default StarRating
