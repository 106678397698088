import { useQuery, useMutation } from '@apollo/client'
import * as R from 'ramda'
import { useState, useContext } from 'react'

import CmsSurveyContext from 'contexts/cms-survey-context'
import LoadingScreen from 'components/shared/loading-screen'
import NestedMultiSelect from '../nested-multi-select'
import { getClientRecipeCategories, getCuisines } from './queries.graphql'
import { updateClientRecipeCategories as updateClientRecipeCategoriesMutation } from './mutations.graphql'

const CuisineSelect = () => {
  const [cuisineOptions, setCuisineOptions] = useState(null)
  const [recipeCategoryIds, setRecipeCategoryIds] = useState(null)

  const { clientId } = useContext(CmsSurveyContext)
  useQuery(getClientRecipeCategories, {
    variables: { clientId },
    onCompleted: data => {
      setRecipeCategoryIds(R.map(R.prop('id'), data?.client?.recipeCategories))
    },
  })
  useQuery(getCuisines, {
    onCompleted: data => {
      setCuisineOptions(
        R.map(
          ({ id, name }) => ({ value: id, label: name }),
          data?.recipeCategories
        )
      )
    },
  })

  const [updateClientRecipeCategories] = useMutation(
    updateClientRecipeCategoriesMutation
  )

  const onChange = async newRecipeCategoryIds => {
    setRecipeCategoryIds(newRecipeCategoryIds)

    await updateClientRecipeCategories({
      variables: { clientId, recipeCategoryIds: newRecipeCategoryIds },
    })
  }

  if (R.isNil(recipeCategoryIds) || R.isNil(cuisineOptions)) {
    return <LoadingScreen />
  }

  return (
    <NestedMultiSelect
      options={cuisineOptions}
      initialSelectedOptions={recipeCategoryIds}
      onChange={onChange}
    />
  )
}

export default CuisineSelect
