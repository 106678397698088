import s from './styles.module.css'

const Checkbox = ({ checked }) => {
  return (
    <div className={s.container}>
      <input
        type="checkbox"
        className={s.input}
        checked={checked}
        readOnly
        onClick={ev => ev.stopPropagation()}
      />
      {checked ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 3H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3ZM19 18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18ZM10.9212 13.39L14.7012 8.38998H14.6912C15.0281 7.94815 15.6594 7.86309 16.1012 8.19998C16.543 8.53687 16.6281 9.16815 16.2912 9.60998L11.7212 15.61C11.5324 15.8552 11.2407 15.9992 10.9312 16C10.6234 16.0016 10.332 15.8615 10.1412 15.62L7.71121 12.51C7.37155 12.0737 7.4499 11.4446 7.88621 11.105C8.32251 10.7653 8.95155 10.8437 9.29121 11.28L10.9212 13.39Z"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 21H6H6C4.34315 21 3 19.6569 3 18V6V6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44771 18.5523 5 18 5L6 5Z"
          />
        </svg>
      )}
    </div>
  )
}

export default Checkbox
