import { createContext, useContext, useMemo } from 'react'

const ClientReferralContext = createContext({ clientFirstName: '' })

const ClientReferralProvider = ({
  referrer: { clientFirstName = '' },
  children,
}) => {
  const state = useMemo(() => ({ clientFirstName }), [clientFirstName])

  return (
    <ClientReferralContext.Provider value={state}>
      {children}
    </ClientReferralContext.Provider>
  )
}

export const useClientReferral = () => useContext(ClientReferralContext)

export default ClientReferralProvider
