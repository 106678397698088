import * as R from 'ramda'
import cx from 'classnames'
import Markdown from 'markdown-to-jsx'
import CmsImage from '../../../shared/cms-image'
import Section from '../common/section'
import SectionColumn from '../common/section-column'
import SectionTitle from '../common/section-title'
import s from './styles.module.css'
import { NAV_LINKS } from '../nav/constants'
import { useContext } from 'react'
import CmsPageContext from 'contexts/cms-page-context'

const ImageComponent = ({ fields, align }) => (
  <CmsImage
    className={cx(s.imageContainer, {
      [s.flexStart]: align === 'left',
      [s.flexEnd]: align === 'right',
    })}
    fields={fields.primaryImage.fields}
    mobileFields={fields.mobileImage?.fields}
    fm="png"
    w={600}
    h={800}
    useNextImage
  />
)

const ContentCompoent = ({ fields }) => {
  const { coach } = useContext(CmsPageContext)
  const { firstName, lastName } = R.propOr({}, 'user', coach)
  const coachFirstName = firstName || 'Coach'
  const coachLastName = lastName || 'Coach'
  const coachFullName = `${firstName || 'Your'} ${lastName || 'Coach'}`

  let header = R.replace('{coachFullName}', coachFullName, fields.header)
  header = R.replace('{coachFirstName}', coachFirstName, header)
  header = R.replace('{coachLastName}', coachLastName, header)

  let subHeader = R.replace('{coachFullName}', coachFullName, fields.subHeader)
  subHeader = R.replace('{coachFirstName}', coachFirstName, subHeader)
  subHeader = R.replace('{coachLastName}', coachLastName, subHeader)

  let text = R.replace('{coachFullName}', coachFullName, fields.text)
  text = R.replace('{coachFirstName}', coachFirstName, text)
  text = R.replace('{coachLastName}', coachLastName, text)

  return (
    <>
      <SectionTitle subtitle={subHeader} title={header} />
      <div className={s.content}>
        <Markdown
          options={{
            overrides: {
              p: { props: { className: s.p } },
              ul: { props: { className: s.ul } },
              li: { props: { className: s.li } },
            },
          }}>
          {text}
        </Markdown>
      </div>
    </>
  )
}

const FeatureExperiment = ({ fields }) => {
  const imageLocation =
    fields.options &&
    fields.options.imageLocation &&
    fields.options.imageLocation === 'right'
      ? 'right'
      : 'left'
  const title =
    (fields?.options?.title?.enabled && fields?.options?.title?.text) || null

  return (
    <section id={NAV_LINKS.howItWorks}>
      {title && <h2 className={s.title}>{title}</h2>}
      <Section
        wrap={imageLocation === 'right' ? 'wrapReverse' : 'wrap'}
        className={title && s.hasTitle}>
        <SectionColumn imageColumn={imageLocation === 'left'}>
          {imageLocation === 'left' && (
            <ImageComponent fields={fields} align="left" />
          )}
          {imageLocation === 'right' && <ContentCompoent fields={fields} />}
        </SectionColumn>
        <SectionColumn imageColumn={imageLocation === 'right'}>
          {imageLocation === 'right' && (
            <ImageComponent fields={fields} align="right" />
          )}
          {imageLocation === 'left' && <ContentCompoent fields={fields} />}
        </SectionColumn>
      </Section>
    </section>
  )
}

export default FeatureExperiment

export const Feature = FeatureExperiment
