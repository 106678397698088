import cx from 'classnames'

import plusIcon from 'images/plus-purple.svg'
import minusIcon from 'images/minus-purple.svg'
import s from './styles.module.css'

const CallFrequencySelect = ({ value, hasPrev, hasNext, onPrev, onNext }) => {
  return (
    <div className={s.container}>
      <button
        className={cx(s.button, { [s.buttonDisabled]: !hasPrev })}
        onClick={onPrev}>
        <img src={minusIcon} alt="Minus" className={s.icon} />
      </button>
      <div className={s.value}>{value}</div>
      <button
        className={cx(s.button, { [s.buttonDisabled]: !hasNext })}
        onClick={onNext}>
        <img src={plusIcon} alt="Plus" className={s.icon} />
      </button>
    </div>
  )
}

export default CallFrequencySelect
