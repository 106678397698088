import cx from 'classnames'
import s from './styles.module.css'
import { useContext } from 'react'
import * as R from 'ramda'
import CmsPageContext from 'contexts/cms-page-context'
import CmsImage from 'components/shared/cms-image'
import { generateCMSDynamicLocalText } from '../helpers'
import Section from 'components/shared/coach-match/coach-variants/experiment/common/section'
import SectionColumn from 'components/shared/coach-match/coach-variants/experiment/common/section-column'
import SectionTitle from 'components/shared/coach-match/coach-variants/experiment/common/section-title'

export const StateCopy = ({ fields }) => {
  const { locationDetails } = useContext(CmsPageContext)
  const {
    country: { slug: countrySlug },
    slug: stateSlug,
  } = locationDetails.state
  const { header, options } = fields

  const title = generateCMSDynamicLocalText(header, locationDetails)
  const text = R.path([countrySlug, stateSlug], options)

  if (!text) return null

  return (
    <Section wrap={'wrapReverse'}>
      <SectionColumn>
        <SectionTitle title={title} showBorder={false} />
        <div className={s.content}>{text}</div>
      </SectionColumn>
      <SectionColumn>
        <CmsImage
          className={cx(s.imageContainer, s.flexEnd)}
          fields={fields.primaryImage.fields}
          mobileFields={fields.mobileImage?.fields}
          fm="png"
          w={600}
          h={800}
          useNextImage
        />
      </SectionColumn>
    </Section>
  )
}
