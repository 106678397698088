import { BaseTypography } from 'components/design-system/type-system'
import s from './styles.module.css'
import React from 'react'

interface SectionTitleProps {
  title: React.ReactNode
  subtitle?: string
  className?: string
  showBorder?: boolean
}

const SectionTitle = ({
  title,
  subtitle,
  className,
  showBorder = true,
}: SectionTitleProps) => {
  return (
    <div className={className}>
      <BaseTypography tag="span" className={s.subtitle}>
        {subtitle}
      </BaseTypography>
      <BaseTypography tag="h3" className={s.title}>
        {title}
      </BaseTypography>
      {showBorder && <div className={s.border}></div>}
    </div>
  )
}

export default SectionTitle
