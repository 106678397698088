import { useContext, useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useRouter } from 'next/router'

import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import CmsPageContext from 'contexts/cms-page-context'
import { LandingPageAnalytics } from '../analytics'
import s from './styles.module.css'

const GetStartedB = ({ fields: { header } }) => {
  const { query } = useRouter()
  const targetAudience = useTargetAudience()
  const { signUp } = useContext(CmsPageContext)
  const [error, setError] = useState(null)
  const href = useMemo(() => {
    if (targetAudience === Audience.Coach) {
      const refString = query?.ref ? `?ref=${query?.ref}` : ''
      const route = `/apply${refString}`
      return route
    }
    return '#signUp'
  }, [query, targetAudience])

  const handleClick = () => {
    if (targetAudience === Audience.Coach) {
      LandingPageAnalytics.onCoachApply({
        location: 'Bottom - Get Started B',
        ref: query?.ref,
      })
    } else {
      try {
        signUp()
      } catch (serverError) {
        setError(`Something went wrong. We're fixing it now`)
      }
    }
  }
  return (
    <div className={s.container}>
      <div className={s.content}>
        <h2 className={s.header}>{header}</h2>
        <div>
          <a href={href} onClick={handleClick}>
            <FontAwesomeIcon className={s.icon} icon={faArrowRight} size="3x" />
          </a>
        </div>
        {error && <div className={s.error}>{error}</div>}
      </div>
    </div>
  )
}

export default GetStartedB
