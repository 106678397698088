import * as R from 'ramda'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const Affiliates = ({ fields, fields: { header, primaryItems } }) => {
  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.header}>{header}</div>
        <div className={s.logos}>
          {R.map(
            ({ sys: { id }, fields: { header, subHeader, primaryImage } }) => {
              return (
                <div key={id} className={s.logo}>
                  <CmsImage
                    fields={primaryImage.fields}
                    className={s.detailEmoji}
                    imageClassName={s.image}
                    isProgressive={false}
                    w={332}
                  />
                </div>
              )
            },
            primaryItems
          )}
        </div>
      </div>
    </div>
  )
}

export default Affiliates
