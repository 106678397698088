import * as R from 'ramda'
import { useState, useRef, useEffect } from 'react'
import cx from 'classnames'

import { Swiper, SwiperSlide } from 'components/shared/swiper'
import rightArrow from 'images/long-right-arrow-grey.svg'
import leftArrow from 'images/long-left-arrow-grey.svg'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const IMAGE_SWIPER_PARAMS = {
  touchRatio: 0.2,
  slideToClickedSlide: true,
}

const TEXT_SWIPER_PARAMS = {
  spaceBetween: 10,
}

const Carousel = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const textSwiperRef = useRef(null)
  const imageSwiperRef = useRef(null)

  useEffect(() => {
    const imageSwiper = imageSwiperRef.current?.swiper
    const textSwiper = textSwiperRef.current?.swiper

    if (
      imageSwiper !== null &&
      imageSwiper.controller &&
      textSwiper !== null &&
      textSwiper.controller
    ) {
      imageSwiper.controller.control = textSwiper
      textSwiper.controller.control = imageSwiper
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSwiperRef.current, textSwiperRef.current])

  useEffect(() => {
    const textSwiper = textSwiperRef.current?.swiper
    if (!textSwiper) return

    const onChange = () => setActiveIndex(textSwiper.activeIndex)

    textSwiper.on('slideChange', onChange)

    return () => textSwiper && textSwiper.off('slideChange', onChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSwiperRef.current])

  return (
    <div className={s.carousel}>
      <div className={s.details}>
        <div>
          <Swiper {...TEXT_SWIPER_PARAMS} ref={textSwiperRef}>
            {R.addIndex(R.map)(({ fields }, idx) => {
              const { header: name, subHeader: label, text } = fields

              return (
                <SwiperSlide key={idx} className={s.card}>
                  <div className={s.cardText}>"{text}"</div>
                  <div className={s.cardName}>{name}</div>
                  <div className={s.cardLabel}>{label}</div>
                  <div className={s.cardTextMobile}>"{text}"</div>
                </SwiperSlide>
              )
            }, items)}
          </Swiper>
        </div>
        <div className={s.controls}>
          <button
            className={s.swiperButtonPrevious}
            onClick={() => textSwiperRef.current.swiper.slidePrev()}>
            <img src={leftArrow} alt="Arrow left" className={s.arrowIcon} />
          </button>
          {R.map(idx => {
            return (
              <button
                key={idx}
                onClick={() => textSwiperRef.current.swiper.slideTo(idx)}
                className={cx(s.swiperBullet, {
                  [s.swiperBulletActive]: idx === activeIndex,
                })}
              />
            )
          }, R.range(0, items.length))}
          <button
            className={s.swiperButtonNext}
            onClick={() => textSwiperRef.current.swiper.slideNext()}>
            <img src={rightArrow} alt="Arrow right" className={s.arrowIcon} />
          </button>
        </div>
      </div>
      <div className={s.images}>
        <Swiper {...IMAGE_SWIPER_PARAMS} ref={imageSwiperRef}>
          {R.addIndex(R.map)(({ fields }, idx) => {
            const { primaryImage, secondaryImage } = fields

            return (
              <SwiperSlide key={idx} className={s.cardImages}>
                {primaryImage && (
                  <CmsImage
                    className={s.primaryImageWrapper}
                    imageClassName={s.image}
                    fields={primaryImage.fields}
                    fm="jpg"
                    fl="progressive"
                  />
                )}
                {secondaryImage && (
                  <CmsImage
                    className={s.secondaryImageWrapper}
                    imageClassName={s.image}
                    fields={secondaryImage.fields}
                    fm="jpg"
                    fl="progressive"
                  />
                )}
              </SwiperSlide>
            )
          }, items)}
        </Swiper>
      </div>
    </div>
  )
}

export default Carousel
