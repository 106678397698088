import cx from 'classnames'

import purpleCheck from 'images/purple-check.svg'
import s from './styles.module.css'

const CheckBox = ({ checked, className }) => {
  return (
    <div className={cx(s.container, className)} tabIndex="-1">
      <input
        type="checkbox"
        checked={checked}
        className={s.checkboxInput}
        readOnly
        onClick={ev => ev.stopPropagation()}
        tabIndex="-1"
      />
      <div className={s.checkbox} tabIndex="-1">
        {checked && (
          <img src={purpleCheck} alt="Check" className={s.checkIcon} />
        )}
      </div>
    </div>
  )
}

export default CheckBox
