import { useContext } from 'react'
import cx from 'classnames'

import CmsPageContext from 'contexts/cms-page-context'
import useWindowSize from 'hooks/use-window-size'
import CmsImage from 'components/shared/cms-image'
import appleIconOrange from 'images/apple-icon-orange.svg'
import googlePlayIconOrange from 'images/google-play-icon-orange.svg'
import { IOS_APP_LINK, ANDROID_APP_LINK } from 'constants/app'
import GoalForm from 'components/homepage/goal-form'
import GetStartedLink from 'components/homepage/get-started-link'
import DetailedStars from 'components/shared/detailed-stars'
import { AnalyticsSection } from 'containers/analytics'
import s from './styles.module.css'

const Hero = ({
  fields,
  overridePrimaryImageFields,
  className,
  overlayClassName,
  mainClassName,
  windowHeightPercentage = 0.95,
  minHeight = 600,
  ctaText = 'Get started',
}) => {
  const { isCoachPage, isBlog, hasBanner } = useContext(CmsPageContext)
  const { height: windowHeight } = useWindowSize({ ignoreHeightChange: true })
  const height =
    windowHeight && Math.max(windowHeight * windowHeightPercentage, minHeight)
  const { header, subHeader, primaryImage, mobileImage, video } = fields

  return (
    <AnalyticsSection name="Hero">
      <section
        className={cx(s.heroSection, className)}
        style={{ height: height ? `${height}px` : undefined }}>
        <CmsImage
          className={s.heroImage}
          fields={overridePrimaryImageFields || primaryImage?.fields}
          videoFields={video?.fields}
          mobileFields={mobileImage?.fields}
          fm="jpg"
          fl="progressive"
          isProgressive={true}
        />
        <div className={cx(s.heroImageOverlay, overlayClassName)} />
        <div
          className={cx(s.main, mainClassName, { [s.mainBanner]: hasBanner })}>
          <header className={s.headerWrapper}>
            <h1 className={s.header}>{header}</h1>
            <h2 className={s.subHeader}>{subHeader}</h2>
            {!isCoachPage && !isBlog && (
              <div className={s.form}>
                <GoalForm
                  showLabel={false}
                  bordered={false}
                  analyticProps={{ location: 'Hero' }}
                />
              </div>
            )}
            {(isCoachPage || isBlog) && (
              <GetStartedLink text={ctaText} className={s.getStartedLink} />
            )}
          </header>
          <div className={s.footer}>
            <div className={s.appStoreLinks}>
              <a
                className={s.appStoreLink}
                target="_blank"
                rel="noreferrer noopener"
                href={IOS_APP_LINK}>
                <img
                  className={s.appStoreIcon}
                  src={appleIconOrange}
                  alt="Apple"
                />
                App store
                <DetailedStars rating={4.8} className={s.appStoreRating} />
              </a>
              <a
                className={s.appStoreLink}
                target="_blank"
                rel="noreferrer noopener"
                href={ANDROID_APP_LINK}>
                <img
                  className={s.appStoreIcon}
                  src={googlePlayIconOrange}
                  alt="Google"
                />
                Google Play
                <DetailedStars rating={4.8} className={s.appStoreRating} />
              </a>
            </div>
          </div>
        </div>
      </section>
    </AnalyticsSection>
  )
}

export default Hero
