import { CoachProps } from 'components/coach-profile/coach-profile-hero'
import { COACH_ACTION_IMAGE_QUESTION_ID } from 'constants/cms-survey'
import EditButton from '../../edit-button'
import CoachMatchPhoto from '../coach-photo'
import CoachSurveyResponses, {
  COACH_MATCH_PROMOTION_QUESTION_IDS,
} from '../coach-survey-responses'
import s from './styles.module.css'
import SectionTitle from '../../experiment/common/section-title'

const CoachWhyTrain = ({ coach }: CoachProps) => {
  return (
    <div className={s.container} id="coach">
      <div className={s.leftCol}>
        {coach.actionImage?.url && (
          <CoachMatchPhoto
            url={coach.actionImage?.url}
            alt="BodyImage"
            questionId={COACH_ACTION_IMAGE_QUESTION_ID}
          />
        )}
      </div>
      <div className={s.rightColMobile}>
        <div className={s.response}>
          <SectionTitle
            title={
              <>
                Why Train With Me
                <EditButton
                  questionId={
                    COACH_MATCH_PROMOTION_QUESTION_IDS.WHY_TRAIN_WITH_ME
                  }
                />
              </>
            }
          />

          <p className={s.text}>{coach.whyTrainWithMe}</p>
        </div>
        <CoachSurveyResponses
          coach={coach}
          responseIds={[
            COACH_MATCH_PROMOTION_QUESTION_IDS.MY_GO_TO_WORKOUT,
            COACH_MATCH_PROMOTION_QUESTION_IDS.HEALTHY_FOOD_I_LOVE,
            COACH_MATCH_PROMOTION_QUESTION_IDS.HEALTHY_FOOD_I_HATE,
          ]}
        />
      </div>
      <div className={s.rightCol}>
        <div className={s.response}>
          <SectionTitle
            title={
              <>
                Why Train With Me
                <EditButton
                  questionId={
                    COACH_MATCH_PROMOTION_QUESTION_IDS.WHY_TRAIN_WITH_ME
                  }
                />
              </>
            }
          />
          <p className={s.text}>{coach.whyTrainWithMe}</p>
        </div>
      </div>
    </div>
  )
}

export default CoachWhyTrain
