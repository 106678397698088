import { AnalyticsManager } from 'utilities'

export class ProductSelectAnalytics {
  static onSelectBillingPeriod({
    analyticsPageName,
    clientId,
    coachId,
    billingPeriodInMonths,
  }) {
    if (!analyticsPageName) return null

    AnalyticsManager.logEvent(`${analyticsPageName} - Select Billing Period`, {
      'Client ID': clientId,
      'Coach ID': coachId,
      'Billing Period In Months': billingPeriodInMonths,
    })
  }

  static onSelectPlan({
    analyticsPageName,
    clientId,
    coachId,
    billingPeriodInMonths,
    callsPerWeek,
    productId,
  }) {
    if (!analyticsPageName) return null

    AnalyticsManager.logEvent(`${analyticsPageName} - Select Plan`, {
      'Client ID': clientId,
      'Coach ID': coachId,
      'Billing Period In Months': billingPeriodInMonths,
      'Calls Per Week': callsPerWeek,
      'Product ID': productId,
    })
  }
}
