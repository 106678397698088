import cn from 'classnames'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'
import CoachHeader from './coach-header'
import { CoachProfileContext } from 'contexts/coach-profile-context'
import CoachMission from './coach-mission'
import CoachIntroVideo from './coach-intro-video'
import CoachQuote from './coach-quote'
import TrainButton from './train-button'
import CoachWhyTrain from './coach-why-train'
import CoachHealthyFood from './coach-healthy-food'
import CoachMusicAboutMe from './coach-music-about-me'

interface CoachProfileExperimentDesignSystemProps extends CoachProps {
  editing?: boolean
  isProfileEdit?: boolean
  getSurveyUrl?: () => void
  onCtaClick?: () => void
}

export default function CoachProfileExperimentDesignSystem({
  coach,
  editing,
  getSurveyUrl,
  onCtaClick,
  isProfileEdit,
}: CoachProfileExperimentDesignSystemProps) {
  const value = { editing, getSurveyUrl, onCtaClick, isProfileEdit }
  return (
    <div
      className={cn(
        s.coachHeroProfileSection,
        editing ? s.minimalPadding : s.fullPadding
      )}>
      <CoachProfileContext.Provider value={value}>
        <CoachHeader coach={coach} />
        <CoachMission coach={coach} />
        <CoachIntroVideo coach={coach} />
        <CoachQuote coach={coach} />
        <CoachWhyTrain coach={coach} />
        <CoachHealthyFood coach={coach} />
        <CoachMusicAboutMe coach={coach} />
        <div className={s.buttonWrapper}>
          <TrainButton
            coach={coach}
            buttonClassName={s.trainButton}
            name="Footer CTA"
          />
        </div>
      </CoachProfileContext.Provider>
    </div>
  )
}
