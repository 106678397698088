import Markdown from 'markdown-to-jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import withProps from 'recompose/withProps'

import s, { SHADES_BLACK, NEUTRAL_GRAY_100 } from './styles.module.css'
import CTAButton from '../../homepage/experiment/cta-button'
import cx from 'classnames'
import React, { useContext } from 'react'
import { BaseTypography } from 'components/design-system/type-system'
import { generateCMSDynamicLocalText } from '../helpers'
import CmsPageContext from 'contexts/cms-page-context'

export const CityComparisonTable = ({
  fields: { header, text, subHeader },
}) => {
  const { locationDetails } = useContext(CmsPageContext)

  const title = generateCMSDynamicLocalText(header, locationDetails)

  const Checkmark = withProps({
    icon: faCheck,
    style: { color: SHADES_BLACK, width: 16, height: 16 },
  })(FontAwesomeIcon)

  const Times = withProps({
    icon: faTimes,
    style: { color: NEUTRAL_GRAY_100, width: 16, height: 16 },
  })(FontAwesomeIcon)

  let result = generateCMSDynamicLocalText(text, locationDetails)
  result = result.replace(/{check}/gi, '<Checkmark />')
  result = result.replace(/{nocheck}/gi, '<Times />')

  return (
    <section className={s.container}>
      <div className={s.content}>
        <BaseTypography className={cx(s.label)}>{subHeader}</BaseTypography>
        <div className={s.headerContainer}>
          <div className={s.dash} />
          <h2 className={s.header}>{title}</h2>
          <div className={s.dash} />
        </div>
        <div className={s.tableLine}>
          <Markdown
            children={result}
            options={{
              overrides: {
                Checkmark: {
                  component: Checkmark,
                },
                Times: {
                  component: Times,
                },
              },
            }}
          />
        </div>
        <div className={s.ctaContainer}>
          <CTAButton
            className={s.cta}
            analyticProps={{ location: 'City Comparison Table' }}
            href={'/apply'}>
            Start Training Today
          </CTAButton>
        </div>
      </div>
    </section>
  )
}
