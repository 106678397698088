import * as R from 'ramda'
import { useState } from 'react'
import TextInput from '../text-input'
import s from './styles.module.css'
import { Button } from 'components/design-system/button'
import { Body } from 'components/design-system/type-system'
import Spinner from 'components/shared/spinner'

const ConfirmationModal = ({
  prompt,
  onConfirm,
  onCancel = null,
  cancelButtonText = 'No',
  confirmButtonText = 'Yes',
  shouldRequireInputText = false,
  initialInputTextValue = '',
  inputTextPlaceholder = '',
  hideCancelButton = false,
  bottomLinkText = null,
  bottomLinkURL = null,
  submitting = null,
}) => {
  const [inputText, setInputText] = useState(initialInputTextValue)

  return (
    <div className={s.confirmModal}>
      <Body className={s.prompt} size="small">
        {prompt}
      </Body>
      {shouldRequireInputText && (
        <div>
          <TextInput
            inputClassName={s.inputText}
            isV2={true}
            maxLength={255}
            value={inputText}
            placeholder={inputTextPlaceholder}
            onChange={value => setInputText(value)}
          />
        </div>
      )}
      <div className={s.buttons}>
        {!hideCancelButton && (
          <>
            <Button
              variant="secondary"
              size="small"
              className={s.cancelButtonB}
              onClick={onCancel}>
              {cancelButtonText}
            </Button>
          </>
        )}
        <Button
          size="small"
          className={s.confirmButtonB}
          onClick={() => onConfirm({ inputText })}
          disabled={shouldRequireInputText && R.isEmpty(inputText)}>
          {confirmButtonText}
          {submitting && (
            <div className={s.spinner}>
              <Spinner />
            </div>
          )}
        </Button>
      </div>

      {bottomLinkText && bottomLinkURL && (
        <a className={s.bottomLink} href={bottomLinkURL}>
          {bottomLinkText}
        </a>
      )}
    </div>
  )
}

export default ConfirmationModal
