import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'

import s from './styles.module.css'

/**
 * The Chip component can be used for a variety of applications
 * ranging from displaying data to as part of a filer or list control
 * component.
 */
const Chip = ({
  active = false,
  className,
  clickable = true,
  icon,
  label,
  onClick = () => {},
  to,
  value = undefined,
}) => {
  const mappedClassName = cx(className, s.root, {
    [s.active]: active,
    [s.disabled]: !clickable,
  })

  const handleClick = value => e => {
    if (!clickable) return
    onClick(value)
  }

  // render the link variant
  if (to) {
    return (
      <Link href={to} className={mappedClassName} onClick={handleClick(value)}>
        {icon && <FontAwesomeIcon icon={icon} className={s.icon} />}
        <span title={label} className={s.label}>
          {' '}
          {label}
        </span>
      </Link>
    )
  }

  return (
    <div className={mappedClassName} onClick={handleClick(value)}>
      {icon && <FontAwesomeIcon icon={icon} className={s.icon} />}
      <span title={label} className={s.label}>
        {' '}
        {label}
      </span>
    </div>
  )
}

Chip.propTypes = {
  /**
   * Whether the chip should appear in an active state
   */
  active: PropTypes.bool,
  /**
   * CSS class name to apply to the chip root
   */
  className: PropTypes.string,
  /**
   * Whether the user should be able to click on the chip
   */
  clickable: PropTypes.bool,
  /**
   * Optional icon to display to the left of the label
   */
  icon: PropTypes.object,
  /**
   * Text that should be displayed
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  /**
   * Optional event handler that fires when the user clicks on
   * the chip. The chip value is exposed as a function parameter.
   */
  onClick: PropTypes.func,
  /**
   * An optional property that allows the chip to function as a link
   */
  to: PropTypes.string,
  /**
   * Internal value associated with the chip, i.e. a database index
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Chip
