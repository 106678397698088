import Markdown from 'markdown-to-jsx'
import s from './styles.module.css'

export default function DefaultMarkdown({ fields }) {
  return (
    <div className={s.container}>
      <Markdown children={fields.text} class="markdown" />
    </div>
  )
}
