import * as R from 'ramda'
import { Swiper, SwiperSlide } from 'components/shared/swiper'
import CTAButton from 'components/homepage/experiment/cta-button'
import s from './styles.module.css'
import CmsImage from 'components/shared/cms-image'
import Markdown from 'markdown-to-jsx'
import { LandingPageExperimentAnalytics } from 'components/homepage/experiment/analytics'
import { useEffect, useState } from 'react'
import contentfulClient from 'clients/contentful'
import cx from 'classnames'
import { useIsMobileLayout } from '../../dashboard/clients/hooks'
import { ChevronDownIcon } from '../../design-system/kickoff-icons'

const PrunedText = ({
  showMoreTextButton,
  setShowMoreTextButton,
  setIsExpanded,
  isMobile,
  fields,
}) => {
  const TEXT_TRIM_SIZE = 225

  const { text } = fields
  const willPrune = isMobile && text.length > TEXT_TRIM_SIZE

  if (!willPrune) {
    return (
      <>
        <Markdown>{text}</Markdown>
        <div className={s.testimonialName}>
          -{fields.header}
          {fields.subHeader && `, ${fields.subHeader}`}
        </div>
      </>
    )
  }

  const trimmedText = text.slice(0, TEXT_TRIM_SIZE)

  const handleShowMoreClick = () => {
    setShowMoreTextButton(false)
    setIsExpanded(true)
  }

  return showMoreTextButton ? (
    <>
      <Markdown>{`${trimmedText}...`}</Markdown>
      <div onClick={handleShowMoreClick} className={s.readMoreText}>
        Read more
        <ChevronDownIcon className={s.chevronDown} size="small" />
      </div>
    </>
  ) : (
    <>
      <Markdown>{text}</Markdown>
      <div className={s.testimonialName}>
        -{fields.header}
        {fields.subHeader && `, ${fields.subHeader}`}
      </div>
    </>
  )
}

const TestimonialCard = ({ fields, showCTA, isMobile }) => {
  const { primaryImage } = fields
  const [isExpanded, setIsExpanded] = useState(false)
  const [showMoreTextButton, setShowMoreTextButton] = useState(true)

  return (
    <div
      className={cx(s.testimonialCard, {
        [s.testimonialCardExpanded]: isExpanded,
      })}
      id={`tc-${fields.header}`}>
      <div className={s.pictures}>
        {primaryImage && (
          <CmsImage
            className={s.headshot}
            fields={primaryImage.fields}
            mobileFields={primaryImage.fields}
            useNextImage
          />
        )}
      </div>
      <div className={s.text}>
        <PrunedText
          fields={fields}
          showMoreTextButton={showMoreTextButton}
          setShowMoreTextButton={setShowMoreTextButton}
          setIsExpanded={setIsExpanded}
          isMobile={isMobile}
        />
        <div className={s.ctaContainer}>
          {showCTA && !isMobile && (
            <CTAButton
              className={s.cta}
              analyticProps={{ location: 'Testimonial' }}
              href={'/apply'}>
              {'Sign up for Kickoff'}
            </CTAButton>
          )}
        </div>
      </div>
    </div>
  )
}

const TESTIMONIAL_CONTENT_ID_BY_GENDER = {
  F: '1cg2gbalJ4ViAtb4O1ffeH',
  M: '1rKSEmLLIHm0VNFuqTLXVi',
  X: '7hDxL0d7kdeP90QStnDRZq',
}
const TestimonialsV2 = ({ fields = null, client = null }) => {
  const [content, setContent] = useState(null)
  const isMobile = useIsMobileLayout()

  const gender = client?.user.gender || 'X'

  useEffect(() => {
    if (fields) {
      return setContent(fields)
    }
    const fetchContent = async () => {
      const { fields: pulledFields } = await contentfulClient.getEntry(
        TESTIMONIAL_CONTENT_ID_BY_GENDER[gender],
        {
          include: 2,
        }
      )
      setContent(pulledFields)
    }

    if (!content) {
      fetchContent()
    }
  }, [content, fields, gender])

  if (!content) return null

  const testimonials = R.propOr([], 'primaryItems', content)

  const handleSlideChange = () => {
    LandingPageExperimentAnalytics.onClick({ location: 'Testimonials - Next' })
  }

  return (
    <section className={s.container}>
      <div className={s.titleContainer}>
        <div className={s.title}>{content.header}</div>
      </div>
      <div className={s.testimonialContainer}>
        <Swiper
          slidesPerView={'auto'}
          centeredSlides={true}
          autoHeight={true}
          spaceBetween={6}
          pagination={{ clickable: true }}
          navigation={!isMobile}
          onSlideChange={handleSlideChange}>
          {R.addIndex(R.map)(
            (testimonial, idx) => (
              <SwiperSlide key={`t-${idx}`}>
                <TestimonialCard
                  fields={testimonial.fields}
                  showCTA={!!fields}
                  isMobile={isMobile}
                />
              </SwiperSlide>
            ),
            testimonials
          )}
        </Swiper>
      </div>
    </section>
  )
}

export default TestimonialsV2
