import { useState, useMemo } from 'react'
import * as R from 'ramda'
import cx from 'classnames'
import { COACH_CERTIFICATIONS_QUESTION_ID } from 'constants/cms-survey'
import EditButton from '../../edit-button'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

interface CoachCertificationsProps extends CoachProps {
  bioCard?: boolean
}

const CERTIFICATION_LIMIT = 5

const CoachCertifications = ({ coach, bioCard }: CoachCertificationsProps) => {
  const [showMore, setShowMore] = useState(false)

  const certifications = useMemo(() => {
    let certifications = []
    if (coach.sourcingAuditPassed) {
      certifications = showMore
        ? [...coach.certifications, 'Kickoff Certified']
        : [
            ...R.take(CERTIFICATION_LIMIT, coach.certifications),
            'Kickoff Certified',
          ]
    } else {
      certifications = showMore
        ? coach.certifications
        : R.take(CERTIFICATION_LIMIT, coach.certifications)
    }
    return certifications
  }, [coach.certifications, coach.sourcingAuditPassed, showMore])

  return (
    <div className={s.container}>
      <EditButton
        questionId={COACH_CERTIFICATIONS_QUESTION_ID}
        isImageOverlay
      />
      {bioCard && <h6 className={s.header}>{'Certifications'}</h6>}
      <ul className={cx(bioCard ? s.bio : '', s.certifications)}>
        {R.map(
          certification => (
            <li key={certification} className={s.certification}>
              {certification}
            </li>
          ),
          certifications
        )}
      </ul>
      {coach?.certifications?.length > CERTIFICATION_LIMIT && !showMore && (
        <p className={s.certificationCount} onClick={() => setShowMore(true)}>
          {`+${coach.certifications.length - CERTIFICATION_LIMIT} more`}
        </p>
      )}
    </div>
  )
}

export default CoachCertifications
