import { useContext, useState } from 'react'

import CmsPageContext from 'contexts/cms-page-context'
import ProductSelect from 'components/shared/product-select'
import plankImage from 'images/homepage/plank.webp'
import plankImageForSafari from 'images/homepage/plank.jp2'
import s from './styles.module.css'
import { LandingPageAnalytics } from '../../analytics'
import useQueryParams from 'hooks/use-query-params'
import urls from 'utilities/urls'
import Router from 'next/router'

const VideoPricing = ({ coupon: propCoupon }) => {
  const [submittingProductId, setSubmittingProductId] = useState(null)
  const {
    signUp,
    coupon: contextCoupon,
    isSafari,
    isCoachPage,
    selectPriceId,
    coach,
  } = useContext(CmsPageContext)
  const queryParams = useQueryParams()
  const coupon = propCoupon || contextCoupon

  const onProductSelect = async (product, price) => {
    if (submittingProductId) return
    const priceId = price.id

    if (isCoachPage) {
      selectPriceId(priceId)

      return document
        .querySelector('#signUp')
        .scrollIntoView({ behavior: 'smooth' })
    } else if (isCoachPage) {
      setSubmittingProductId(product.id)
      const { href, as } = urls.clientSignUp({
        ...queryParams,
        questionId: 228,
        priceId,
        refCoachId: coach?.id,
      })
      return Router.push(href, as)
    }

    setSubmittingProductId(product.id)
    await signUp({ surveyParams: { priceId } })

    LandingPageAnalytics.onSignUp({
      location: `Select Plan - ${product.name}`,
    })
  }

  return (
    <div className={s.section}>
      <h2 className={s.header}>The Best Plan for You</h2>
      <h4 className={s.subHeader}>
        No hidden fees. Unlimited workouts, meal planning, & support. Change
        your trainer any time.
      </h4>
      <div className={s.content}>
        <div className={s.plans}>
          <ProductSelect
            onSelect={onProductSelect}
            submittingProductId={submittingProductId}
            coupon={coupon}
            coachId={coach?.id}
            billingPeriod={1}
            discountStyle={s.discount}
            hideRecommendedBorder={true}
            analyticsPageName="Landing Page"
          />
        </div>
        <div className={s.imageWrapper}>
          <img
            className={s.image}
            src={isSafari ? plankImageForSafari : plankImage}
            alt="Plank"
          />
        </div>
      </div>
    </div>
  )
}

export default VideoPricing
