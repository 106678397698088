import s from './styles.module.css'

const AppStore = () => {
  return (
    <>
      <svg
        width="96"
        height="20"
        viewBox="0 0 96 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={s.mobile}>
        <path
          d="M9.82617 7.82057C9.83331 7.2569 9.98053 6.70423 10.2541 6.21398C10.5277 5.72372 10.9188 5.31181 11.391 5.01658C11.0911 4.58089 10.6953 4.22233 10.2353 3.96938C9.77519 3.71644 9.26345 3.57608 8.74071 3.55944C7.62561 3.44041 6.54457 4.23803 5.97633 4.23803C5.3971 4.23803 4.52221 3.57126 3.58011 3.59097C2.97072 3.611 2.37683 3.79121 1.8563 4.11405C1.33576 4.4369 0.906336 4.89137 0.609859 5.43317C-0.674415 7.69445 0.283538 11.0178 1.51377 12.8456C2.12927 13.7406 2.84863 14.7404 3.78989 14.705C4.71096 14.6661 5.05497 14.1077 6.16679 14.1077C7.26829 14.1077 7.59104 14.705 8.5514 14.6824C9.53976 14.6661 10.1625 13.7834 10.7564 12.8799C11.1986 12.2421 11.5389 11.5373 11.7647 10.7914C11.1905 10.5445 10.7005 10.131 10.3557 9.60273C10.011 9.07443 9.82684 8.45461 9.82617 7.82057Z"
          fill="currentColor"
        />
        <path
          d="M8.01177 2.3573C8.55067 1.6994 8.81616 0.853768 8.75187 0C7.92856 0.0879412 7.16805 0.488111 6.62187 1.12078C6.35483 1.42985 6.1503 1.78942 6.01999 2.17892C5.88967 2.56843 5.83612 2.98024 5.86239 3.39081C6.2742 3.39512 6.68159 3.30435 7.0539 3.12533C7.42621 2.94631 7.75372 2.68371 8.01177 2.3573Z"
          fill="currentColor"
        />
        <path
          d="M28.954 11.1342H24.2799L23.1575 14.4206H21.1777L25.6049 2.26172H27.6618L32.0889 14.4206H30.0755L28.954 11.1342ZM24.764 9.61766H28.469L26.6425 4.28407H26.5914L24.764 9.61766Z"
          fill="currentColor"
        />
        <path
          d="M41.6509 9.98868C41.6509 12.7435 40.1639 14.5133 37.9199 14.5133C37.3515 14.5428 36.7862 14.413 36.2887 14.1387C35.7912 13.8644 35.3817 13.4567 35.107 12.9624H35.0646V17.3532H33.2295V5.55581H35.0058V7.03025H35.0395C35.3268 6.53827 35.7433 6.13272 36.2445 5.85676C36.7458 5.58079 37.3132 5.44474 37.8862 5.46306C40.1552 5.46307 41.6509 7.24156 41.6509 9.98868ZM39.7647 9.98868C39.7647 8.19394 38.8293 7.014 37.4021 7.014C36 7.014 35.0569 8.21879 35.0569 9.98868C35.0569 11.7748 36 12.971 37.4021 12.971C38.8293 12.971 39.7647 11.7997 39.7647 9.98868Z"
          fill="currentColor"
        />
        <path
          d="M51.4907 9.98868C51.4907 12.7434 50.0038 14.5133 47.7598 14.5133C47.1914 14.5428 46.626 14.413 46.1286 14.1387C45.6311 13.8644 45.2216 13.4567 44.9469 12.9624H44.9044V17.3532H43.0693V5.5558H44.8456V7.03024H44.8793C45.1667 6.53826 45.5831 6.13271 46.0844 5.85675C46.5856 5.58079 47.153 5.44473 47.726 5.46306C49.9951 5.46306 51.4907 7.24156 51.4907 9.98868ZM49.6045 9.98868C49.6045 8.19393 48.6691 7.01399 47.242 7.01399C45.8398 7.01399 44.8967 8.21878 44.8967 9.98868C44.8967 11.7748 45.8398 12.971 47.242 12.971C48.6691 12.971 49.6045 11.7997 49.6045 9.98868H49.6045Z"
          fill="currentColor"
        />
        <path
          d="M57.9938 11.0329C58.1297 12.2387 59.311 13.0303 60.9253 13.0303C62.472 13.0303 63.5849 12.2386 63.5849 11.1514C63.5849 10.2077 62.9137 9.64259 61.3245 9.25533L59.7353 8.87572C57.4836 8.33643 56.4383 7.29228 56.4383 5.59792C56.4383 3.50005 58.2821 2.05908 60.9002 2.05908C63.4913 2.05908 65.2676 3.50005 65.3274 5.59792H63.4749C63.364 4.38452 62.3524 3.65209 60.8741 3.65209C59.3958 3.65209 58.3843 4.39314 58.3843 5.47171C58.3843 6.33132 59.0304 6.83714 60.6109 7.22435L61.9619 7.55328C64.4778 8.14325 65.5231 9.14535 65.5231 10.9238C65.5231 13.1986 63.6957 14.6233 60.7893 14.6233C58.0699 14.6233 56.2338 13.232 56.1152 11.0328L57.9938 11.0329Z"
          fill="currentColor"
        />
        <path
          d="M69.4835 3.45752V5.55539H71.1836V6.99636H69.4835V11.8834C69.4835 12.6426 69.8239 12.9964 70.5712 12.9964C70.7731 12.9929 70.9746 12.9789 71.1749 12.9543V14.3867C70.8389 14.4489 70.4974 14.4771 70.1556 14.4708C68.3456 14.4708 67.6397 13.7967 67.6397 12.0775V6.99636H66.3398V5.55539H67.6397V3.45752H69.4835Z"
          fill="currentColor"
        />
        <path
          d="M72.168 9.98864C72.168 7.19946 73.8247 5.44678 76.4081 5.44678C79.0001 5.44678 80.6491 7.19942 80.6491 9.98864C80.6491 12.7855 79.0088 14.5305 76.4081 14.5305C73.8082 14.5305 72.168 12.7855 72.168 9.98864ZM78.7793 9.98864C78.7793 8.07532 77.895 6.94607 76.4081 6.94607C74.9211 6.94607 74.0378 8.08393 74.0378 9.98864C74.0378 11.9096 74.9211 13.0303 76.4081 13.0303C77.895 13.0303 78.7793 11.9096 78.7793 9.98864H78.7793Z"
          fill="currentColor"
        />
        <path
          d="M82.1631 5.55573H83.9133V7.06457H83.9558C84.0742 6.59333 84.3526 6.17687 84.7442 5.88521C85.1358 5.59354 85.6168 5.44444 86.1062 5.46297C86.3177 5.46223 86.5286 5.48501 86.7349 5.53086V7.23286C86.468 7.15197 86.1895 7.11483 85.9104 7.12291C85.6438 7.11218 85.378 7.15879 85.1313 7.25953C84.8846 7.36027 84.6628 7.51275 84.4812 7.70651C84.2995 7.90028 84.1623 8.13072 84.0789 8.38204C83.9955 8.63335 83.968 8.89958 83.9982 9.16244V14.4205H82.1631L82.1631 5.55573Z"
          fill="currentColor"
        />
        <path
          d="M95.1935 11.8169C94.9466 13.4261 93.3661 14.5305 91.3439 14.5305C88.7432 14.5305 87.1289 12.8027 87.1289 10.0307C87.1289 7.25014 88.7518 5.44678 91.2668 5.44678C93.7403 5.44678 95.2957 7.13157 95.2957 9.8194V10.4428H88.9814V10.5528C88.9522 10.879 88.9934 11.2077 89.1022 11.5169C89.211 11.8262 89.3849 12.109 89.6124 12.3464C89.8398 12.5839 90.1157 12.7707 90.4215 12.8944C90.7274 13.018 91.0563 13.0758 91.3864 13.0637C91.82 13.104 92.2552 13.0044 92.6272 12.7798C92.9992 12.5551 93.2881 12.2174 93.451 11.8169L95.1935 11.8169ZM88.99 9.17111H93.4596C93.4761 8.87776 93.4313 8.58421 93.3279 8.30887C93.2245 8.03353 93.0649 7.78234 92.859 7.57108C92.6531 7.35982 92.4053 7.19304 92.1314 7.0812C91.8574 6.96937 91.563 6.9149 91.2668 6.92121C90.9679 6.91944 90.6717 6.97638 90.3952 7.08872C90.1187 7.20107 89.8673 7.36661 89.6556 7.57578C89.444 7.78496 89.2762 8.03363 89.1619 8.30745C89.0477 8.58126 88.9893 8.8748 88.99 9.17112V9.17111Z"
          fill="currentColor"
        />
      </svg>
      <svg
        width="134"
        height="28"
        viewBox="0 0 134 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={s.desktop}>
        <path
          d="M13.7566 10.9488C13.7666 10.1597 13.9727 9.38592 14.3558 8.69957C14.7388 8.01321 15.2864 7.43654 15.9475 7.02321C15.5275 6.41324 14.9735 5.91126 14.3294 5.55714C13.6853 5.20301 12.9688 5.00651 12.237 4.98322C10.6759 4.81657 9.1624 5.93324 8.36687 5.93324C7.55594 5.93324 6.3311 4.99976 5.01215 5.02736C4.15901 5.05539 3.32757 5.30769 2.59882 5.75967C1.87007 6.21166 1.26887 6.84791 0.853803 7.60644C-0.944181 10.7722 0.396953 15.4249 2.11927 17.9838C2.98098 19.2369 3.98808 20.6366 5.30584 20.587C6.59535 20.5326 7.07696 19.7507 8.63351 19.7507C10.1756 19.7507 10.6275 20.587 11.972 20.5554C13.3557 20.5326 14.2275 19.2968 15.0589 18.0319C15.6781 17.139 16.1545 16.1522 16.4706 15.108C15.6667 14.7622 14.9807 14.1835 14.498 13.4438C14.0154 12.7042 13.7576 11.8365 13.7566 10.9488Z"
          fill="white"
        />
        <path
          d="M11.2165 3.30022C11.9709 2.37915 12.3426 1.19528 12.2526 0C11.1 0.123118 10.0353 0.683356 9.27062 1.56909C8.89676 2.00179 8.61043 2.50518 8.42798 3.05049C8.24554 3.5958 8.17057 4.17234 8.20735 4.74713C8.78387 4.75317 9.35423 4.62609 9.87546 4.37546C10.3967 4.12483 10.8552 3.75719 11.2165 3.30022Z"
          fill="white"
        />
        <path
          d="M40.5342 15.5874H33.9905L32.4191 20.1884H29.6475L35.8455 3.16602H38.7251L44.9232 20.1884H42.1043L40.5342 15.5874ZM34.6683 13.4643H39.8552L37.2982 5.9973H37.2266L34.6683 13.4643Z"
          fill="white"
        />
        <path
          d="M58.31 13.9837C58.31 17.8403 56.2282 20.3182 53.0867 20.3182C52.2908 20.3595 51.4994 20.1777 50.8029 19.7937C50.1065 19.4097 49.5331 18.839 49.1486 18.1469H49.0891V24.294H46.52V7.77765H49.0068V9.84186H49.0541C49.4563 9.15309 50.0393 8.58532 50.7411 8.19897C51.4429 7.81262 52.2371 7.62214 53.0394 7.6478C56.216 7.64781 58.31 10.1377 58.31 13.9837ZM55.6693 13.9837C55.6693 11.471 54.3597 9.8191 52.3617 9.8191C50.3987 9.8191 49.0784 11.5058 49.0784 13.9837C49.0784 16.4843 50.3987 18.1589 52.3617 18.1589C54.3597 18.1589 55.6693 16.5191 55.6693 13.9837Z"
          fill="white"
        />
        <path
          d="M72.0849 13.9837C72.0849 17.8403 70.0031 20.3182 66.8616 20.3182C66.0658 20.3595 65.2743 20.1777 64.5778 19.7937C63.8814 19.4097 63.308 18.8389 62.9235 18.1469H62.8641V24.294H60.2949V7.77764H62.7817V9.84185H62.8289C63.2312 9.15308 63.8142 8.58531 64.516 8.19896C65.2177 7.81262 66.012 7.62214 66.8143 7.6478C69.991 7.6478 72.0849 10.1377 72.0849 13.9837ZM69.4442 13.9837C69.4442 11.471 68.1346 9.81909 66.1366 9.81909C64.1736 9.81909 62.8533 11.5058 62.8533 13.9837C62.8533 16.4843 64.1736 18.1589 66.1366 18.1589C68.1347 18.1589 69.4442 16.5191 69.4442 13.9837H69.4442Z"
          fill="white"
        />
        <path
          d="M81.189 15.4457C81.3794 17.1337 83.0332 18.2421 85.2931 18.2421C87.4586 18.2421 89.0165 17.1337 89.0165 15.6116C89.0165 14.2904 88.0769 13.4992 85.852 12.9571L83.6272 12.4256C80.4748 11.6706 79.0114 10.2088 79.0114 7.83669C79.0114 4.89968 81.5927 2.88232 85.258 2.88232C88.8856 2.88232 91.3724 4.89968 91.4561 7.83669H88.8626C88.7074 6.13794 87.2912 5.11253 85.2215 5.11253C83.1519 5.11253 81.7357 6.15 81.7357 7.66C81.7357 8.86345 82.6403 9.5716 84.853 10.1137L86.7444 10.5742C90.2666 11.4002 91.7301 12.8031 91.7301 15.293C91.7301 18.4776 89.1718 20.4722 85.1027 20.4722C81.2956 20.4722 78.7251 18.5245 78.5591 15.4456L81.189 15.4457Z"
          fill="white"
        />
        <path
          d="M97.2751 4.84033V7.77735H99.6553V9.7947H97.2751V16.6366C97.2751 17.6995 97.7517 18.1948 98.798 18.1948C99.0805 18.1899 99.3626 18.1702 99.6431 18.1358V20.1411C99.1727 20.2283 98.6946 20.2678 98.2161 20.2589C95.6821 20.2589 94.6939 19.3152 94.6939 16.9083V9.7947H92.874V7.77735H94.6938V4.84033H97.2751Z"
          fill="white"
        />
        <path
          d="M101.034 13.9836C101.034 10.0788 103.354 7.625 106.97 7.625C110.599 7.625 112.908 10.0787 112.908 13.9836C112.908 17.8992 110.611 20.3422 106.97 20.3422C103.331 20.3422 101.034 17.8992 101.034 13.9836ZM110.29 13.9836C110.29 11.305 109.052 9.72401 106.97 9.72401C104.889 9.72401 103.652 11.317 103.652 13.9836C103.652 16.673 104.889 18.2419 106.97 18.2419C109.052 18.2419 110.29 16.673 110.29 13.9836H110.29Z"
          fill="white"
        />
        <path
          d="M115.026 7.77778H117.476V9.89015H117.536C117.701 9.23042 118.091 8.64738 118.639 8.23904C119.188 7.83071 119.861 7.62197 120.546 7.64791C120.842 7.64688 121.138 7.67877 121.426 7.74296V10.1258C121.053 10.0125 120.663 9.96052 120.272 9.97183C119.899 9.95681 119.527 10.0221 119.181 10.1631C118.836 10.3041 118.526 10.5176 118.271 10.7889C118.017 11.0601 117.825 11.3828 117.708 11.7346C117.591 12.0864 117.553 12.4592 117.595 12.8272V20.1884H115.026L115.026 7.77778Z"
          fill="white"
        />
        <path
          d="M133.27 16.5431C132.924 18.7961 130.712 20.3422 127.881 20.3422C124.239 20.3422 121.979 17.9233 121.979 14.0425C121.979 10.1497 124.252 7.625 127.773 7.625C131.235 7.625 133.413 9.98371 133.413 13.7467V14.6195H124.573V14.7734C124.532 15.2302 124.59 15.6903 124.742 16.1232C124.894 16.5562 125.138 16.952 125.456 17.2845C125.775 17.617 126.161 17.8785 126.589 18.0516C127.017 18.2248 127.478 18.3056 127.94 18.2887C128.547 18.3451 129.156 18.2057 129.677 17.8912C130.198 17.5767 130.602 17.1039 130.83 16.5431L133.27 16.5431ZM124.585 12.8391H130.843C130.866 12.4284 130.803 12.0174 130.658 11.6319C130.513 11.2465 130.29 10.8948 130.002 10.599C129.713 10.3033 129.367 10.0698 128.983 9.9132C128.599 9.75663 128.187 9.68037 127.773 9.68921C127.354 9.68673 126.939 9.76644 126.552 9.92372C126.165 10.081 125.813 10.3128 125.517 10.6056C125.221 10.8985 124.986 11.2466 124.826 11.6299C124.666 12.0133 124.584 12.4242 124.585 12.8391V12.8391Z"
          fill="white"
        />
      </svg>
    </>
  )
}

export default AppStore
