import * as R from 'ramda'
import { useState } from 'react'
import cx from 'classnames'

import Carousel from 'components/homepage/carousel'
import parenthesisLeft from 'images/parenthesis-left-orange.svg'
import parenthesisRight from 'images/parenthesis-right-orange.svg'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const DEFAULT_ACTIVE_IDX = 0

const Trainers = ({ fields }) => {
  const { header, primaryItems: trainers } = fields
  const [activeTrainerIdx, setActiveTrainerIdx] = useState(DEFAULT_ACTIVE_IDX)
  const activeTrainer = trainers[activeTrainerIdx]
  const { header: name, text } = activeTrainer.fields

  return (
    <section id="trainers">
      <div className={s.trainersSection}>
        <div className={s.main}>
          <h2 className={s.header}>{header}</h2>
          <h4 className={s.trainerName}>{name}</h4>
          <div className={s.trainerBio}>{text}</div>
        </div>
        <div className={s.gallery}>
          <div className={s.galleryItems}>
            {R.addIndex(R.map)(({ fields }, idx) => {
              const { primaryImage } = fields

              return (
                <div
                  key={`trainer-${idx}`}
                  className={cx(s.imageContainer, {
                    [s.imageContainerActive]: activeTrainerIdx === idx,
                  })}
                  onMouseEnter={() => setActiveTrainerIdx(idx)}>
                  <img
                    src={parenthesisLeft}
                    alt="Parenthesis left"
                    className={s.parenthesisLeft}
                  />
                  <CmsImage
                    fields={primaryImage.fields}
                    className={s.imageWrapper}
                    imageClassName={s.image}
                    w="400"
                    h="400"
                  />
                  <img
                    src={parenthesisRight}
                    alt="Parenthesis right"
                    className={s.parenthesisRight}
                  />
                </div>
              )
            }, trainers)}
          </div>
        </div>
      </div>
      <div className={s.trainersSectionMobile}>
        <h2 className={s.header}>{header}</h2>
        <Carousel items={trainers} />
      </div>
    </section>
  )
}

export default Trainers
