import cx from 'classnames'
import s from './styles.module.css'

const SectionColumn = ({ children, imageColumn }) => {
  return (
    <div className={cx(s.col, { [s.imageContainer]: imageColumn })}>
      {children}
    </div>
  )
}

export default SectionColumn
