import * as R from 'ramda'
import cx from 'classnames'

import Faq from './faq'
import s from './styles.module.css'
import Text from 'components/shared/text'
import { AnalyticsSection } from 'containers/analytics'
import { useContext } from 'react'
import CmsPageContext from 'contexts/cms-page-context'

export const CityFaqs = ({ fields }) => {
  const { locationDetails } = useContext(CmsPageContext)

  if (!fields) return null

  const faqs = R.uniqBy(
    R.prop('id'),
    R.map(
      ({ sys: { id }, fields: { question, answer } }) => ({
        id,
        question,
        answer,
      }),
      fields.primaryItems
    )
  )

  return (
    <AnalyticsSection name="FAQ">
      <section className={cx(s.container)}>
        <div className={s.content}>
          <Text is="h2" className={s.title}>
            {fields.header}
          </Text>
          <ul className={s.faqs}>
            {R.map(faq => {
              return (
                <li className={s.faq} key={faq.id}>
                  <Faq faq={faq} locationDetails={locationDetails} />
                </li>
              )
            }, faqs)}
          </ul>
        </div>
      </section>
    </AnalyticsSection>
  )
}
