import * as R from 'ramda'

import { fullName } from './user'

export const interpolateCoachContent = (coach, text) => {
  const { user } = coach
  const { firstName } = user

  return R.pipe(
    R.replace('{coachFirstName}', firstName),
    R.replace('{coachFullName}', fullName(user))
  )(text)
}
