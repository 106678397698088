import * as R from 'ramda'
import cx from 'classnames'
import qs from 'query-string'
import kickoffLogoStandard from 'images/kickoff-logo-standard.svg'
import Link from 'next/link'
import s from './styles.module.css'
import Menu from './menu'
import { useContext, useEffect, useState } from 'react'
import Close from './close'
import urls, { surround } from 'utilities/urls'
import CTAButton from '../cta-button'
import { NAVIGATION, NAV_LINKS } from './constants'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'
import ModalContext from 'contexts/modal-context'
import CmsPageContext from 'contexts/cms-page-context'
import ConfirmationModal from 'components/shared/confirmation-modal'
import Spinner from 'components/shared/spinner'
import User from './user'
import { LandingPageExperimentAnalytics } from '../analytics'
import { Button } from 'components/design-system/button'
import useScrollThreshold from 'hooks/use-scroll-threshold'

const NavExperiment = ({
  landingPageOptions = { overrideCTA: undefined },
  fields,
}) => {
  const targetAudience = useTargetAudience()
  // this is causing the component to rerender on scroll
  const scroll = useScrollThreshold(1)
  const confirmationModal = useContext(ModalContext)
  const { hasBanner, signUp, isCoachPage, coach, getRef, query } =
    useContext(CmsPageContext)

  const { id, ...otherParams } = query
  const pageRef = getRef()
  const defaultQueryString = qs.stringify({ ...otherParams, ref: pageRef })
  const getQueryString = ({ href, ref }) => {
    if (href.match(/^#\w+/)) {
      //just a hash, no need for any params
      return href
    }
    const queryString =
      !pageRef && ref
        ? qs.stringify({ ...otherParams, ref })
        : defaultQueryString
    return href + surround(queryString, '?')
  }

  const { firstName: coachFirstName } = coach ? coach.user : {}

  const showReviewsLink = false

  const [showMenu, setShowMenu] = useState(false)
  const [appLink, setAppLink] = useState('#')

  const isFreeTrial =
    targetAudience === Audience.FreeTrial ||
    targetAudience === Audience.CoachFreeTrial
  const isGuestPass = targetAudience === Audience.GuestPass
  const isTYPGuestPass = targetAudience === Audience.ThankYouPageGuestPass

  let linksToExclude =
    isFreeTrial || isGuestPass || isTYPGuestPass ? ['Gifts'] : []
  linksToExclude = isCoachPage
    ? R.concat(linksToExclude, ['Trainers', 'Gifts'])
    : linksToExclude

  linksToExclude = !showReviewsLink
    ? R.concat(linksToExclude, ['Reviews'])
    : linksToExclude

  const navToUse =
    fields.options && fields.options.length ? fields.options : NAVIGATION

  const navLinks = navToUse

  const initAppLink = async () => {
    const branch = (await import('clients/branch')).default
    branch.link(
      {
        data: {
          $deeplink_path: 'dashboard',
        },
      },
      (_, link) => {
        setAppLink(link)
      }
    )
  }

  useEffect(() => {
    if (appLink === '#') {
      initAppLink()
    }
  }, [appLink])

  useEffect(() => {
    if (window) {
      window.onpopstate = () => {
        window.location.reload()
      }
    }
  }, [])

  const handleNavClick = e => {
    setShowMenu(false)

    const target =
      e.target.getAttribute && e.target.getAttribute('href').split('?')[0]
    const targetHash = target.includes('#') ? target.split('#')[1] : null

    // Check if the current page and target page are the same
    const currentPath = window.location.pathname
    const targetPath = new URL(
      target,
      window.location.origin + window.location.pathname
    ).pathname

    if (targetHash && currentPath === targetPath) {
      e.preventDefault()
      scrollToTargetElement(`#${targetHash}`)

      window.history.pushState(null, null, target)

      LandingPageExperimentAnalytics.onClick({
        location: `Nav - ${e.target.innerText}`,
      })
    } else if (targetHash) {
      e.preventDefault()

      window.location.href = target

      const onWindowLoad = () => {
        scrollToTargetElement(`#${targetHash}`)
        window.removeEventListener('load', onWindowLoad)
      }

      window.addEventListener('load', onWindowLoad)
    }
  }

  const scrollToTargetElement = target => {
    const yOffset = -100
    const element = document.querySelector(target)
    const y =
      element.getBoundingClientRect().top +
      window.pageYOffset +
      (target === `#${NAV_LINKS.successStories}` ? yOffset : -50)

    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  const openSignUp = _ => {
    confirmationModal.open(() => {
      return <Spinner />
    })
    signUp()
  }

  const handleLoginClick = e => {
    e.preventDefault()

    setShowMenu(false)

    LandingPageExperimentAnalytics.onClick({
      location: `Nav - ${e.target.innerText}`,
    })

    if (appLink === '#') {
      return
    }

    confirmationModal.open(() => {
      return (
        <ConfirmationModal
          prompt={'Click to access the Kickoff app and login!'}
          cancelButtonText="Create Client Account"
          confirmButtonText="Login as Existing Client"
          onCancel={openSignUp}
          bottomLinkURL={'/sign-in'}
          bottomLinkText={'Login as Coach'}
          signUpVariant="Z"
          onConfirm={_ => {
            window.location.href = appLink
          }}
          confirmButtonClassname={s.loginButton}
        />
      )
    })
  }

  return (
    <div
      className={cx(s.container, {
        [s.shadow]: scroll,
        [s.banner]: hasBanner,
      })}>
      <Link
        {...urls.homePage({ ...R.omit(['slug'], otherParams) })}
        className={s.logoContainer}>
        <img src={kickoffLogoStandard} alt="Kickoff" className={s.logo} />
      </Link>
      <div className={s.mobileNavBtn} onClick={() => setShowMenu(!showMenu)}>
        {showMenu ? <Close /> : <Menu width={20} height={20} />}
      </div>
      <div className={cx(s.mobileMenu, { [s.show]: showMenu })}>
        {isCoachPage && coachFirstName && (
          <a href="#coach" className={s.navItem} onClick={handleNavClick}>
            {`Meet ${coachFirstName}`}
          </a>
        )}
        {R.map(
          item => (
            <a
              href={getQueryString(item)}
              key={item.label}
              className={s.navItem}
              onClick={handleNavClick}>
              {item.label}
            </a>
          ),
          R.reject(n => R.includes(n.label, linksToExclude), navLinks)
        )}
        <CTAButton
          className={s.cta}
          analyticProps={{ location: `Nav` }}
          href={targetAudience === Audience.Coach ? '/apply' : ''}>
          {targetAudience === Audience.Coach
            ? 'Get Started'
            : isCoachPage
            ? `Train With ${coachFirstName}`
            : isGuestPass || isTYPGuestPass
            ? 'Claim Guest Pass'
            : isFreeTrial
            ? 'Start Free Trial'
            : landingPageOptions.overrideCTA || 'Find Your Coach'}
        </CTAButton>
        {!isTYPGuestPass && !isFreeTrial && !isGuestPass && !isCoachPage && (
          <Button
            variant="text"
            className={cx(s.navItem, s.login)}
            onClick={handleLoginClick}>
            <span>Login</span> <User />
          </Button>
        )}
      </div>
      <div className={s.desktopMenu}>
        {isCoachPage && coachFirstName && (
          <a href="#coach" className={s.navItem} onClick={handleNavClick}>
            {`Meet ${coachFirstName}`}
          </a>
        )}
        {(targetAudience === Audience.Client ||
          isFreeTrial ||
          isGuestPass ||
          isTYPGuestPass) &&
          R.map(
            item => (
              <a
                href={getQueryString(item)}
                key={item.label}
                className={s.navItem}
                onClick={handleNavClick}>
                {item.label}
              </a>
            ),
            R.reject(n => R.includes(n.label, linksToExclude), navLinks)
          )}
        {!isTYPGuestPass && !isFreeTrial && !isGuestPass && !isCoachPage && (
          <Button
            variant="text"
            className={cx(s.navItem, s.login)}
            onClick={handleLoginClick}>
            <span>Login</span> <User />
          </Button>
        )}
        <CTAButton
          analyticProps={{ location: 'Nav' }}
          href={targetAudience === Audience.Coach ? '/apply' : ''}>
          {targetAudience === Audience.Coach
            ? 'Get Started'
            : isCoachPage
            ? `Train With ${coachFirstName}`
            : isGuestPass || isTYPGuestPass
            ? 'Claim Guest Pass'
            : isFreeTrial
            ? 'Start Free Trial'
            : landingPageOptions.overrideCTA || 'Find Your Coach'}
        </CTAButton>
      </div>
    </div>
  )
}

export default NavExperiment
