import { AnalyticsManager } from 'utilities'

/*
 ** Analytics class
 */
export class CoachProfileSignUpAnalytics {
  static onViewPage({ coachId, coachProfileVariant }) {
    try {
      window.dataLayer.push({
        event: `viewed_clp_${
          coachProfileVariant ? coachProfileVariant.toLowerCase() : 'b'
        }`,
      })
    } catch (error) {}
  }

  static onSignUp({ coachId, coachProfileVariant }) {
    AnalyticsManager.logEvent(`CLP - Sign Up`, {
      'Coach ID': coachId,
      'Coach Profile Variant': coachProfileVariant,
    })
  }

  static onContinueSignUp({ coachId, coachProfileVariant }) {
    AnalyticsManager.logEvent(`CLP - Form - Continue Sign Up - Click`, {
      'Coach ID': coachId,
      'Coach Profile Variant': coachProfileVariant,
    })
  }
}
