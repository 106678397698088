import * as R from 'ramda'
import SectionTitle from '../common/section-title'
import s from './styles.module.css'
import CoachCard from '../common/coach-card'
import CTAButton from '../cta-button'
import { useState } from 'react'
import { Button } from 'components/design-system/button'
import Plus from './plus'
import { NAV_LINKS } from '../nav/constants'
import { LandingPageExperimentAnalytics } from '../analytics'
import useTargetAudience, { Audience } from 'hooks/use-target-audience'

const TrainersExperiment = ({ fields }) => {
  const targetAudience = useTargetAudience()
  const isGuestPass = targetAudience === Audience.GuestPass
  const isTYPGuestPass = targetAudience === Audience.ThankYouPageGuestPass

  const trainers = R.propOr([], 'primaryItems', fields)

  const [numOfTrainers, setNumOfTrainers] = useState(4)

  const handleViewMoreClick = e => {
    setNumOfTrainers(trainers.length)

    LandingPageExperimentAnalytics.onClick({
      location: `Trainers - ${e.target.innerText}`,
    })
  }

  return (
    <section id={NAV_LINKS.trainers} className={s.container}>
      <div className={s.sectionTitle}>
        <SectionTitle
          title={fields.header}
          subtitle={fields.subHeader}
          className={s.title}
        />
      </div>
      <p>{fields.text}</p>
      <div className={s.trainers}>
        {R.addIndex(R.map)(
          (trainer, idx) => (
            <div key={`trainer-${idx}`} className={s.trainer}>
              <CoachCard fields={trainer.fields} />
            </div>
          ),
          R.take(numOfTrainers, trainers)
        )}
      </div>
      <div className={s.buttonContainer}>
        <CTAButton analyticProps={{ location: 'Trainers' }}>
          {isGuestPass || isTYPGuestPass ? 'Claim Guest Pass' : 'Get Matched'}
        </CTAButton>
      </div>
      {numOfTrainers !== trainers.length && (
        <Button
          variant="text"
          size="small"
          onClick={handleViewMoreClick}
          className={s.viewMoreButton}>
          <span className={s.viewMoreButtonText}>View More Coaches</span>
          <Plus size={16} className={s.viewMoreButtonIcon} />
        </Button>
      )}
    </section>
  )
}

export default TrainersExperiment
