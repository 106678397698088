import * as R from 'ramda'
import { useEffect, useState } from 'react'

import useCurrentUser from 'hooks/use-current-user'
import contentfulClient from 'clients/contentful'
import Faq from './faq'
import s from './styles.module.css'

const FAQ_SECTION_ID = '3swHNrNWJiw9VxjfkvONAB'

const Faqs = () => {
  const [section, setSection] = useState(null)

  const currentUserPayload = useCurrentUser()
  const me = currentUserPayload.data?.me
  const clientId = me?.user?.client?.id
  const coachId = me?.user?.client?.coach?.id

  useEffect(() => {
    const fetchSection = async () => {
      setSection(
        await contentfulClient.getEntry(FAQ_SECTION_ID, {
          include: 2,
        })
      )
    }

    fetchSection()
  }, [])

  if (!section) return null

  const { header, primaryItems } = section.fields

  const faqs = R.uniqBy(
    R.prop('id'),
    R.map(
      ({ sys: { id }, fields: { header: question, text: answer } }) => ({
        id,
        question,
        answer,
      }),
      primaryItems
    )
  )

  return (
    <div className={s.container}>
      <h4 className={s.header}>{header}</h4>
      <ul className={s.faqs}>
        {R.map(faq => {
          return (
            <li className={s.faq} key={faq.id}>
              <Faq faq={faq} clientId={clientId} coachId={coachId} />
            </li>
          )
        }, faqs)}
      </ul>
    </div>
  )
}

export default Faqs
