import Hero from 'components/homepage/hero'
import s from './styles.module.css'

const BlogHero = ({ fields, blogProps }) => {
  return (
    <Hero
      fields={fields}
      windowHeightPercentage={0.65}
      minHeight={0}
      ctaText="Find a trainer"
      className={s.section}
      overridePrimaryImageFields={blogProps.entry?.fields?.heroImage?.fields}
      overlayClassName={s.overlay}
      mainClassName={s.main}
    />
  )
}

export default BlogHero
