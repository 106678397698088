import * as R from 'ramda'
import CmsImage from 'components/shared/cms-image'
import s from './styles.module.css'

const AffiliatesExperiment = ({ fields: { header, primaryItems } }) => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <div className={s.header}>{header}</div>
        {R.map(({ sys: { id }, fields: { primaryImage } }) => {
          return (
            <div key={id}>
              <CmsImage
                fields={primaryImage.fields}
                mobileFields={primaryImage.fields}
                className={s.logo}
                imageClassName={s.image}
                isProgressive={false}
                w={500}
                layout="responsive"
                useNextImage
              />
            </div>
          )
        }, primaryItems)}
      </div>
    </section>
  )
}

export default AffiliatesExperiment
