import { useContext } from 'react'
import cn from 'classnames'

import CmsPageContext from 'contexts/cms-page-context'
import useScrollPos from 'hooks/use-scroll-pos'
import ChatButton from 'components/shared/chat-button-b'
import s from './styles.module.css'
import { isStickyCtaHidden } from '../../sticky-cta'

const ChatButtonContainer = () => {
  const { isCoachPage } = useContext(CmsPageContext)

  const { y } = useScrollPos()
  const isStickyCtaVisible = !isStickyCtaHidden(y, isCoachPage)

  return (
    <div
      className={cn(s.container, { [s.stickyCtaVisible]: isStickyCtaVisible })}>
      <ChatButton />
    </div>
  )
}

export default ChatButtonContainer
