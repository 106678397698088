import { COACH_QUOTE_QUESTION_ID } from 'constants/cms-survey'
import EditButton from '../../edit-button'
import quotationIcon from 'images/quotation.png'
import s from './styles.module.css'
import { CoachProps } from 'components/coach-profile/coach-profile-hero'

const CoachQuote = ({ coach }: CoachProps) => {
  return (
    <div className={s.container}>
      <div className={s.quote}>
        <img className={s.quoteIcon} src={quotationIcon} alt="Quote" />
        <div className={s.quoteText}>
          {coach.quote}
          <EditButton questionId={COACH_QUOTE_QUESTION_ID} />
        </div>
      </div>
    </div>
  )
}

export default CoachQuote
