import cmsPageContext from 'contexts/cms-page-context'
import { useContext, useEffect } from 'react'
import * as R from 'ramda'
import { LandingPageAnalytics } from '../analytics'
import s from './styles.module.css'
import { useRouter } from 'next/router'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import { getFirstStringFromOptionalArray } from 'utilities/strings'
import { getDiscountAmountText } from 'utilities/coupon'
import useProducts from 'hooks/use-products'

const Banner = ({ fields, force }) => {
  const router = useRouter()
  const query = router.query

  const { isCoachPage, hasBanner, coupon } = useContext(cmsPageContext)
  const productsPayload = useProducts({
    excludeLiveVideo: true,
    excludeMultiMonth: true,
  })
  const products = productsPayload.data?.products || []
  const standardOneMonthPrice = R.path([0, 'prices', 0, 'unitAmount'], products)
  const ref = getFirstStringFromOptionalArray(query?.ref) || ''
  const [platform, freetrial] = ref.split('_')
  const amountOffAsPercent = query?.slug === 'health'

  const { header, options } = fields

  useEffect(() => {
    if (hasBanner || isCoachPage || force) {
      LandingPageAnalytics.onPromoBannerView({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const trialDays = useClientTrialDays()

  let bannerText
  if (isCoachPage) {
    if ((platform === 'gp' || trialDays) && options) {
      bannerText = options.freeTrial
    }
    bannerText = header
  } else if (options) {
    bannerText = options[platform] || options[freetrial]
  }

  if (!bannerText && coupon?.percentOff === 100) {
    bannerText = 'Your free trial has been applied'
  }

  const couponDiscountAmountText = getDiscountAmountText({
    basePrice: standardOneMonthPrice,
    amountOff: coupon?.amountOff,
    percentOff: coupon?.percentOff,
    amountOffAsPercent: amountOffAsPercent,
  })
  if (couponDiscountAmountText && !bannerText) {
    bannerText = `Your ${couponDiscountAmountText} discount has been applied`
  }

  if ((!hasBanner && !isCoachPage && !force) || !bannerText) return null

  return (
    <section className={s.bannerSection}>
      <h3 className={s.header}>{bannerText}</h3>
    </section>
  )
}

export default Banner
