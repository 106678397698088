import { AnalyticsManager } from 'utilities'

/*
 ** Analytics class
 */
export class LandingPageExperimentAnalytics {
  static onClick({ location, signUpVariant }) {
    AnalyticsManager.logEvent(
      `Landing Page - Client Sign Up - ${location} Click`,
      {
        'Sign Up Variant': signUpVariant || 'Z',
      }
    )
  }

  static onCoachStickyCTAClick({ coachProfileVariant, coachId }) {
    AnalyticsManager.logEvent(`CLP - Bottom Sticky - CTA - Click`, {
      'Coach ID': coachId,
      'Coach Profile Variant': coachProfileVariant,
    })
  }
}
