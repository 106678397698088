import cn from 'classnames'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import CmsImage from '../../../../shared/cms-image'
import s from './styles.module.css'
import { TABLET_WIDTH } from 'constants/breakpoints'
import useIsFirstRender from 'hooks/use-is-first-render'

const FeatureExperiment = ({
  fields: { header, subHeader, primaryImage, mobileImage, text, options },
}) => {
  const imageLocation = options?.imageLocation || 'left'
  const backgroundColor = options?.backgroundColor || '#FFF'
  const isFirstRender = useIsFirstRender()
  const isMobile = useWindowSizeCondition(({ width }) => width <= TABLET_WIDTH)

  return (
    <section className={s.container} style={{ backgroundColor }}>
      <div
        className={cn(s.content, {
          [s.contentReverse]: imageLocation === 'left',
        })}>
        <div className={s.headers}>
          <span className={s.header}>{header}</span>
          {subHeader && <span className={s.subheader}>{subHeader}</span>}
          {text && <span className={s.text}>{text}</span>}
        </div>
        <CmsImage
          className={cn(s.image, {
            [s.imageCover]:
              !isFirstRender &&
              isMobile &&
              options.mobileImageObjectFit == 'cover',
          })}
          fields={
            !isFirstRender && isMobile && mobileImage?.fields
              ? mobileImage.fields
              : primaryImage.fields
          }
          objectFit={
            !isFirstRender && isMobile && options.mobileImageObjectFit
              ? options.mobileImageObjectFit
              : 'contain'
          }
          useNextImage
        />
      </div>
    </section>
  )
}

export default FeatureExperiment
