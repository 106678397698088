import { useContext } from 'react'
import cx from 'classnames'
import CmsPageContext from 'contexts/cms-page-context'
import s from './styles.module.css'
import * as R from 'ramda'
import { generateCMSDynamicLocalText } from '../helpers'

export const OtherCitiesGrid = ({ fields }) => {
  const { locationDetails } = useContext(CmsPageContext)
  const { stateCities } = locationDetails

  const { header, subHeader, primaryItems } = fields

  const title = generateCMSDynamicLocalText(header, locationDetails)
  const subTitle = generateCMSDynamicLocalText(subHeader, locationDetails)

  return (
    <section className={cx(s.container)}>
      <div className={s.content}>
        <div className={s.title}>{title}</div>
        <div className={s.subTitle}>{subTitle}</div>
        <div className={s.grid}>
          {R.map(({ fields }) => {
            return (
              <a
                key={fields.header}
                href={fields.subHeader}
                className={s.gridItemLink}>
                <div className={s.gridItem}>{fields.header}</div>
              </a>
            )
          }, primaryItems || [])}
          {R.map(({ slug, name }) => {
            return (
              <a
                key={slug}
                href={`/local/${slug}/personal-trainers`}
                className={s.gridItemLink}>
                <div className={s.gridItem}>{name}</div>
              </a>
            )
          }, stateCities || [])}
        </div>
      </div>
    </section>
  )
}
