const Twitter = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 24H21C22.656 24 24 22.656 24 21V3C24 1.344 22.656 0 21 0H3C1.344 0 0 1.344 0 3V21C0 22.656 1.344 24 3 24ZM17.97 9.34199C17.97 13.404 14.874 18.096 9.216 18.096C7.482 18.096 5.862 17.586 4.506 16.722C4.746 16.752 4.992 16.764 5.238 16.764C6.678 16.764 8.004 16.272 9.06 15.444C7.716 15.42 6.576 14.532 6.186 13.308C6.372 13.344 6.564 13.362 6.762 13.362C7.044 13.362 7.314 13.326 7.572 13.254C6.168 12.972 5.106 11.724 5.106 10.236V10.194C5.52 10.422 5.994 10.56 6.498 10.578C5.676 10.026 5.13 9.08399 5.13 8.01599C5.13 7.45199 5.28 6.92399 5.544 6.46799C7.062 8.32799 9.33 9.55799 11.886 9.68399C11.838 9.45599 11.808 9.22199 11.808 8.98199C11.808 7.28399 13.188 5.90399 14.886 5.90399C15.768 5.90399 16.566 6.27599 17.13 6.87599C17.832 6.73799 18.492 6.48599 19.086 6.13199C18.852 6.85199 18.366 7.45799 17.73 7.83599C18.354 7.75799 18.948 7.59599 19.5 7.34999C19.086 7.96799 18.564 8.51399 17.964 8.94599C17.97 9.07799 17.97 9.20999 17.97 9.34199Z"
        fill="url(#paint0_linear_859:46624)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_859:46624"
          x1="-2.4"
          y1="30.96"
          x2="31.1873"
          y2="25.1032"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E9429A" />
          <stop offset="1" stopColor="#FF9171" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Twitter
