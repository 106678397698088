import cx from 'classnames'

import CmsImage from 'components/shared/cms-image'
import detailArrow from 'images/how-it-works/detail-arrow-long.png'
import muscleEmoji from 'images/how-it-works/muscle-emoji.png'
import ss from '../styles.module.css'
import s from './styles.module.css'

const Coach = ({ idx, fields }) => {
  const { header, subHeader, text } = fields

  return (
    <div className={s.section}>
      <div className={cx(ss.sectionMain, s.sectionMain)}>
        <header className={ss.sectionHeaderWrapper}>
          <div className={ss.sectionHeaderIdx}>{idx + 1}</div>
          <h4 className={ss.sectionHeader}>{header}</h4>
        </header>
        <div className={s.sectionText}>{text}</div>
        <div className={ss.detailWrapper}>
          <div className={s.detail}>
            <img className={s.muscleEmoji} src={muscleEmoji} alt="Muscle" />
            <h6 className={s.detailText}>{subHeader}</h6>
            <img className={s.detailArrow} src={detailArrow} alt="Arrow" />
          </div>
        </div>
      </div>
      <div className={s.images}>
        <CmsImage
          className={s.coachMatchesImage}
          fields={fields.primaryImage.fields}
          w={600}
        />
      </div>
    </div>
  )
}

export default Coach
