// sync any updates with node/utilities/client-trials.ts
import { Audience } from './target-audience'

const TRIAL_DAYS_BY_AUDIENCE = {
  [Audience.CoachFreeTrial]: 30,
  [Audience.GuestPass]: 30,
  [Audience.ThankYouPageGuestPass]: 7,
  [Audience.FreeTrial]: 7,
}

export function getClientTrialDays(targetAudience: Audience) {
  // currently, the same trial applies for every member of each audience,
  // but additional logic could be configured here
  return TRIAL_DAYS_BY_AUDIENCE[targetAudience] || 0
}
