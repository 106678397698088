import * as R from 'ramda'
import { useState, useRef, useEffect } from 'react'
import memoize from 'moize'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Swiper, SwiperSlide } from 'components/shared/swiper'
import { useWindowSizeCondition } from 'hooks/use-window-size-condition'
import CmsImage from 'components/shared/cms-image'
import rightArrow from 'images/long-right-arrow-orange.svg'
import leftArrow from 'images/long-left-arrow-orange.svg'
import { AnalyticsSection } from 'containers/analytics'
import { TrackedButton } from 'components/shared/analytics'
import s from './styles.module.css'

const getTranslateY = memoize(() => 50 - Math.floor(Math.random() * 100))

const Stars = ({ count }) => {
  const stars = new Array(count).fill('')
  return (
    <div className={s.starsContainer}>
      {stars.map((_, i) => {
        return (
          <FontAwesomeIcon key={`star-${i}`} className={s.star} icon={faStar} />
        )
      })}
    </div>
  )
}

const Reviews = ({ fields }) => {
  const [slidesPerView, setSlidesPerView] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef(null)

  const numSlides = useWindowSizeCondition(({ width }) => {
    return Math.max(1, Math.floor(width / 350))
  })

  useEffect(() => {
    setSlidesPerView(numSlides)
  }, [numSlides])

  const { header, primaryItems: reviews } = fields
  const isNext = activeIndex < reviews.length - slidesPerView
  const isPrevious = activeIndex > 0

  useEffect(() => {
    const swiper = swiperRef.current?.swiper
    if (!swiper) return

    const onChange = () => setActiveIndex(swiper.activeIndex)

    swiper.on('slideChange', onChange)

    return () => swiper && swiper.off('slideChange', onChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperRef.current, slidesPerView])

  const swiperParams = {
    spaceBetween: 45,
    slidesPerView,
  }

  // reset if number of slides changes
  if (!slidesPerView || slidesPerView !== numSlides) return null

  return (
    <AnalyticsSection name="Reviews">
      <section className={s.reviewsSection}>
        <h2 className={s.header}>{header}</h2>
        <div className={s.swiperWrapper}>
          <div className={s.overlayPrevious} />
          {isPrevious && (
            <TrackedButton
              name="Previous"
              className={s.previousButton}
              onClick={() => swiperRef.current.swiper.slidePrev()}>
              <img src={leftArrow} alt="Left arrow" className={s.arrowIcon} />
            </TrackedButton>
          )}
          {!R.isNil(slidesPerView) && (
            <Swiper {...swiperParams} ref={swiperRef}>
              {R.addIndex(R.map)(({ fields }, idx) => {
                const {
                  header: name,
                  subHeader: label,
                  text,
                  primaryImage: avatarImage,
                  secondaryImage: appStoreImage,
                } = fields

                const translateY = getTranslateY(idx)

                return (
                  <SwiperSlide key={idx} className={s.review}>
                    <div
                      className={s.reviewContent}
                      style={{
                        transform: `translateY(${translateY}px)`,
                      }}>
                      <div className={s.reviewTextWrapper}>
                        <div className={s.avatarWrapper}>
                          {avatarImage && (
                            <CmsImage
                              fields={avatarImage.fields}
                              className={s.avatar}
                              imageClassName={s.avatarImage}
                              w={200}
                              h={200}
                            />
                          )}
                        </div>
                        <p className={s.reviewText}>{text}</p>
                      </div>
                      <Stars count={5} />
                      <div className={s.reviewInfo}>
                        <div className={s.appStoreIconWrapper}>
                          {appStoreImage && (
                            <CmsImage
                              fields={appStoreImage.fields}
                              className={s.appStoreIcon}
                              w={200}
                              h={200}
                            />
                          )}
                        </div>
                        <div>
                          <div className={s.reviewName}>{name}</div>
                          <div className={s.reviewLabel}>{label}</div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              }, reviews)}
            </Swiper>
          )}
          {isNext && (
            <TrackedButton
              name="Next"
              className={s.nextButton}
              onClick={() => swiperRef.current.swiper.slideNext()}>
              <img src={rightArrow} alt="Right arrow" className={s.arrowIcon} />
            </TrackedButton>
          )}
          <div className={s.overlayNext} />
        </div>
      </section>
    </AnalyticsSection>
  )
}

export default Reviews
