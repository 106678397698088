import Markdown from 'markdown-to-jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import kickoffLogo from 'images/kickoff-logo-standard.svg'
import withProps from 'recompose/withProps'

import s from './styles.module.css'

const ComparisonTable = ({ fields, fields: { header, text } }) => {
  const Image = props => (
    <img
      alt="table"
      src={props.src}
      style={props.style}
      className={props.className}
    />
  )

  const Checkmark = withProps({
    icon: faCheckCircle,
    style: { color: '#2CB572', width: 30, height: 30 },
  })(FontAwesomeIcon)

  const Times = withProps({
    icon: faTimesCircle,
    style: { color: '#e5e5e5', width: 30, height: 30 },
  })(FontAwesomeIcon)

  const Kickoff = withProps({
    src: kickoffLogo,
    className: s.logo,
  })(Image)

  let result = text.replace(/{check}/gi, '<Checkmark />')
  result = result.replace(/{nocheck}/gi, '<Times />')
  result = result.replace(/{kickoff}/gi, '<Kickoff />')

  return (
    <div className={s.container}>
      <div className={s.content}>
        <h2 className={s.header}>{header}</h2>
        <div>
          <Markdown
            children={result}
            options={{
              overrides: {
                Checkmark: {
                  component: Checkmark,
                },
                Times: {
                  component: Times,
                },
                Kickoff: {
                  component: Kickoff,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ComparisonTable
