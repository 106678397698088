import * as R from 'ramda'

/**
 * Handles all mustache text replacement when text
 * has a "{variable}" that depends on a global CMS
 * location parameters, such as city and state.
 * */
export const generateCMSDynamicLocalText = (
  text: string = '',
  { city = {}, state = {} }: any = {}
): string => {
  return text
    .replace(/{cityName}/g, city?.name || '')
    .replace(/{citySlug}/g, city?.slug || '')
    .replace(/{stateSlug}/g, state?.slug || '')
    .replace(
      /{countrySlug}/g,
      state?.country?.slug || city?.state?.country?.slug || ''
    )
    .replace(/{stateName}/g, state?.name || city?.state?.name || '')
    .replace(
      /{stateAbbreviation}/g,
      city?.state?.abbreviation || state?.abbreviation || ''
    )
}

export const dynamicMetaTagsConverter = (dynamicMetaTags, locationDetails) =>
  R.map(metaTag => {
    const {
      fields: { content, ...fields },
    } = metaTag

    return {
      ...metaTag,
      fields: {
        ...fields,
        content: generateCMSDynamicLocalText(content, locationDetails),
      },
    }
  }, dynamicMetaTags)
